import React, {useState} from 'react';
import '../../../../assets/css/autoInsurance.css'
import {useDispatch} from "react-redux";
import {setCurrentAutoChangeOption} from "../../../../slices/requestAChnage";
import {useHistory} from "react-router-dom";

export const CurrentInsuranceRequest = ({nextStep, previousStep}) => {
    const datas = ['Add Driver','Remove/Replace a Driver']
    const [currentRequest, setCurrentRequest] = useState()
    const dispatch = useDispatch()
    const history = useHistory()

    const setCurrentRequestSubmit = (index,elem) => {
        setCurrentRequest(index)
        dispatch(setCurrentAutoChangeOption(elem))
        setTimeout(() => {
            if (elem === 'Add Driver'){
                history.push('/driver-change-request')
            }else{
                history.push('/remove-replace-driver')
            }
        }, 150)
    }

    return (
        <div>
            <div className='requestChangeCurrent driverFormTop'/>
            <div className='content_container driver_container'>
                <div className='input_container'>
                    <div className='displaying_content'>
                        {datas.map((el,index) => {
                            return (
                                <article key={index} className="feature2">
                                    <input onChange={() => setCurrentRequestSubmit(index,el)} checked={index === currentRequest}  type="checkbox" id="feature2"/>
                                    <div>
                                        {el}
                                    </div>
                                </article>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
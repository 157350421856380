/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCars = /* GraphQL */ `
  query GetCars($id: Int!) {
    getCars(id: $id) {
      id
      year
      make
      model
      trim
    }
  }
`;
export const listCarss = /* GraphQL */ `
  query ListCarss {
    listCarss {
      id
      year
      make
      model
      trim
    }
  }
`;
export const listYears = /* GraphQL */ `
  query ListYears {
    listYears {
      id
      year
      make
      model
      trim
    }
  }
`;
export const listMakes = /* GraphQL */ `
  query ListMakes($year: String!) {
    listMakes(year: $year) {
      id
      year
      make
      model
      trim
    }
  }
`;
export const listModels = /* GraphQL */ `
  query ListModels($make: String!) {
    listModels(make: $make) {
      id
      year
      make
      model
      trim
    }
  }
`;
export const listTrims = /* GraphQL */ `
  query ListTrims($model: String!) {
    listTrims(model: $model) {
      id
      year
      make
      model
      trim
    }
  }
`;
export const getVehicle = /* GraphQL */ `
  query GetVehicle($id: ID!) {
    getVehicle(id: $id) {
      id
      make
      model
      trim
      createdAt
      updatedAt
    }
  }
`;
export const listVehicles = /* GraphQL */ `
  query ListVehicles(
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVehicles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        make
        model
        trim
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

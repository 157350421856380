import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    nonOwnerFormInfo:null,
}


const setHomeOwnerInfoIns = (state,action) => {
    return {
        ...state,
        [action.payload.info]: action.payload.value
    }
}


const setNonOwnerInfoINS = (state,action) => {
    return {
        ...state,
        nonOwnerFormInfo: action.payload
    }
}


export const nonownerSlice = createSlice({
    name: "nonOwner",
    initialState: initialState,
    reducers: {
        setNonOwnerInfo:setHomeOwnerInfoIns,
        setNonOwnerFinalInfo:setNonOwnerInfoINS,
    }
})

export const {
    setNonOwnerInfo,
    setNonOwnerFinalInfo
    } = nonownerSlice.actions

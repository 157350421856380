import React, {useEffect, useState} from 'react';
import '../../../../assets/css/autoInsurance.css'
import './checkbox.css'
import {useDispatch, useSelector} from "react-redux";
import {setAutoRequestType} from "../../../../slices/requestAChnage";
import {getAutoRequestType} from "../../../../selectors/requestAChangeSelector";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import {goTO} from "../../CurrentRequestChange/autoDetect";


export const AutoRequestType = React.memo(({previousStep}) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const datas = ['AddressChange','Driver Change','Vehicle Change','Lienholder/Bank Change','Document Request','Other Change Request']
    const [currentChecked, setCurrentChecked] = useState();
    const isChecked = useSelector(getAutoRequestType)

    const changeCurrentInput = (index,current) => {
        setCurrentChecked(index)
        dispatch(setAutoRequestType(current))
    }

    useEffect(() => {
        isChecked && setTimeout(() => {
            history.push(goTO(isChecked))
        }, 150)
    }, [isChecked])

    return (
        <div>

            <div className='content_container container_gap'>
            <div className='input_container'>
            <div className='displaying_content'>
                {datas.map((el,index) => {
                    return (
                        <article key={index} className="feature2">
                            <input onChange={() => changeCurrentInput(index,el)} checked={index === currentChecked} type="checkbox" id="feature2"/>
                            <div>
                                {el}
                            </div>
                        </article>
                    )
                })}
            </div>
                </div>
            </div>
            <div className='nextPrevRequestButtonMainDiv'>
                <Button type='submit' style={{marginTop: '15px'}} onClick={previousStep} variant="contained"
                        color="primary">
                    Previous
                </Button>
            </div>
        </div>
    )
})
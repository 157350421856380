import React, {useEffect, useState} from 'react';
import {Field, FieldArray, reduxForm} from 'redux-form';
import '../../../../src/assets/css/autoInsurance.css'
import {
     gender_options, marital_status,
    states,
} from "../../../static/select-options/driver-select-options";
import Button from "@material-ui/core/Button";
import {
    renderDate,
    renderField, renderFieldPhoneNumber,
    renderFieldSelect,
    renderFieldSelectRequire, renderFieldZipCode, renderPatternDateInput
} from "../../utils/driver-redux-form";
import passValidation from "../../utils/validate";
import {useSelector} from "react-redux";
import {getZipCode} from "../../../selectors/autoInsuranceSelector";


const renderMembers = React.memo(({fields, meta: {touched, error, submitFailed},violation,setViolation, location, zipCodeValue}) => {
    return (
        <div>
            {fields.map((validate, index) => (
                <ul key={index}>
                    <li key={index} className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.firstName`}
                            type="text"
                            component={renderField}
                            label="First Name"
                        />
                        <Field
                            name={`${validate}.lastName`}
                            type="text"
                            component={renderField}
                            label="Last Name"
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.renter_email`}
                            component={renderField}
                            type="email"
                            label="Email Address"
                        />
                        <Field
                            name={`${validate}.phone_number`}
                            type="number"
                            min={10}
                            max={11}
                            component={renderFieldPhoneNumber}
                            label="Phone Number"
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.property_address`}
                            type="text"
                            component={renderField}
                            label="Address"
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.city`}
                            type="text"
                            component={renderField}
                            label="City"
                        />
                        <Field
                            name={`${validate}.state`}
                            component={renderFieldSelectRequire}
                            label="State"
                            options={states}
                        />
                        <Field
                            name={`${validate}.zipCode`}
                            type="number"
                            component={zipCodeValue ? renderFieldZipCode : renderField}
                            label="Zip Code"
                            zipCodeValue={zipCodeValue}
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.date_of_birth`}
                            type="text"
                            placeholder={'MM/DD/YYYY'}
                            component={renderPatternDateInput}
                            label="Date of Birth"
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.gender`}
                            component={renderFieldSelect}
                            label="Gender"
                            options={gender_options}
                        />
                        <Field
                            name={`${validate}.marital_status`}
                            component={renderFieldSelect}
                            label="Marital Status"
                            options={marital_status}
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <div className='haveViolation'>
                            <span
                                className='violation'>Have you had any moving violations in the past 3 (three) years? </span>
                            <label style={{marginRight: '10px'}}>
                                <Field onChange={() => setViolation(true)} name={`${validate}.violation`}
                                       component="input" checked={violation === true ? true : false} type="radio" value="Yes"/>{' '}
                                Yes
                            </label>
                            <label style={{marginRight: '10px'}}>
                                <Field onChange={() => setViolation(false,index)} name={`${validate}.violation`}
                                       checked={violation === false ? true : false} component="input" type="radio" value="No"/>{' '}
                                No
                            </label>
                        </div>
                    </li>
                    {violation === true &&
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.driver_license_number`}
                            component={renderField}
                            label="Driver License Number"
                            type='text'
                        />
                        <Field
                            name={`${validate}.violation_state`}
                            component={renderFieldSelect}
                            label="State"
                            options={states}
                        />
                    </li>}
                </ul>
            ))}
            <div className={'addDriver'}>
                <button style={{display: 'none'}} className='addButton' id='defaultValues' type="button"
                        onClick={() => fields.push({})}/>
            </div>
        </div>
    )
})

const FieldArraysForm = props => {
    const zipCodeValue = useSelector(getZipCode)
    const [violation,setViolation] = useState(false)
    useEffect(() => {
        const defaultClick = document.getElementById('defaultValues')
        defaultClick.click()
    }, [])

    const {handleSubmit, submitting} = props;
    return (
        <form onSubmit={handleSubmit} style={{marginTop: '25px'}}>
            <FieldArray setViolation={setViolation} violation={violation} zipCodeValue={zipCodeValue} name="validate" location={props} component={renderMembers}/>
            <div className='request'>
                <Button type='submit' disabled={submitting} style={{marginTop: '15px'}} variant="contained"
                        color="primary">
                    REQUEST A QUOTE
                </Button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'fieldArrays',
    validate: passValidation,
})(FieldArraysForm);

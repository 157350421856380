import {Link} from "react-router-dom";
export default function HomeF5({text, buttonText}) {
    return (
        <div className="notify-sec">
            <h3><i>{text}</i></h3>
            <div id="notify-btn">
                <Link to="/auto-insurance-form">
                    <button 
                        type="button" 
                        className="btn btn-raised btn-success btn-gm-green btn-home-radius shadow save-m-btn">{buttonText}</button>
                </Link>
            </div>
        </div>
    );
}
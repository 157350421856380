import { createSelector } from 'reselect'

const autoInsuranceSelector = (state) => {return state.autoInsurance}

export const getScrollPositionsAddNewDriver = createSelector(autoInsuranceSelector, state => state.scrollPositionIntoView)

export const getZipCode = createSelector(autoInsuranceSelector, state => state.zipCode)

export const getStepVehicle = createSelector(autoInsuranceSelector, state => state.currentVehicle)

export const getGraphQl = createSelector(autoInsuranceSelector, state => state.vehicleGraphQl)

export const getFinalForm = createSelector(autoInsuranceSelector, state => state.finalForm)

export const getCV = createSelector(autoInsuranceSelector, state => state.CV)
import {createSlice} from "@reduxjs/toolkit";
import {applicantViewModel as applicant} from "./../formModels"

const initialState = {
    applicant: applicant
}

const saveApplicantResolver = (state, action) => {
    state.applicant = action.payload
}

export const applicantSlice = createSlice({
    name: "applicant",
    initialState: initialState,
    reducers:{
        saveApplicant: saveApplicantResolver
    }
})

export const {saveApplicant} = applicantSlice.actions

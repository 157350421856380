import React from 'react';
import '../../../assets/css/autoInsurance.css'

export const RequestChangeDisclaimor = React.memo(({setDisc,disc}) => {
    return (
        <div className='disclaimorMain'>
            <p>
                <p style={{fontSize:'16px',fontWeight:700}}>Online Policy Change Request Disclaimer</p>
                <input onChange={() => setDisc(!disc)} checked={disc} className='disclaimorSubmit' type="checkbox" name="cb" id="cb1"/>
                <label className='disclaimorLabel' htmlFor="cb1">
                    I understand that <b>No </b>
                    changes to my policy or coverage are binding by submitting this online policy change request.
                    This change request will only be considered bound upon confirmation from GM Insurance Services.
                </label>
            </p>
        </div>

    )
})
import React from 'react'
import {Icon, Popup} from "semantic-ui-react";

export const renderFieldSelect = ({options,input, label,violation, type, meta: {touched, error}}) => (
    <div  className='mainDiv'>
        <label className='reduxFormLabel'>
            {label}
        </label>
        <select {...input} className='selectOptions' >
            <option></option>
            {options && options.map((el,index) => {
                return (
                    <option key={index} value={el.text}>{el.text}</option>
                )
            })}
        </select>
        {touched && error && <span className='errorSpan'>{error}</span>}
    </div>
);
export const renderFieldSelectRequire = ({options,input, label,violation, type, meta: {touched, error}}) => (
    <div  className='mainDiv'>
        <label className='reduxFormLabel'>
            {label}
        </label>
        <select {...input} className='selectOptions' >
            <option></option>
            {options && options.map((el,index) => {
                return (
                    <option key={index} value={el.text}>{el.text}</option>
                )
            })}
        </select>
        {touched && error && <span className='errorSpan'>{error}</span>}
    </div>
);


export const renderDate = ({input, label, type, meta: {touched, error}}) => {
    let validDate = false
    if (input.value.length > 10){
        validDate = true
    }
    return (
        <div  className='mainDiv'>
            <label className='reduxFormLabel'>
                {label}
            </label>
            <input className='form-inputMoto' style={{border:`${validDate ? '1px solid red' : '1px solid rgba(34, 36, 38, .15)'}`}}  {...input} type={type} placeholder={label}/>
            <br/>
            {validDate &&
            <span style={{color: 'red'}}>Pleas enter Valid Date</span>
            }
            {touched && error && <span className='errorSpan'>{error}</span>}
        </div>
    )
}
export const renderDateToday = ({input, label, type, meta: {touched, error}}) => {
    let validDate = false
    if (input.value.length > 10){
        validDate = true
    }
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth()+1; //January is 0 so need to add 1 to make it 1!
    let yyyy = today.getFullYear();
    if(dd<10){
        dd='0'+dd
    }
    if(mm<10){
        mm='0'+mm
    }

    today = yyyy+'-'+mm+'-'+dd;
    return (
        <div  className='mainDiv'>
            <label className='reduxFormLabel'>
                {label}
            </label>
            <input className='form-inputMoto' min={today} style={{border:`${validDate ? '1px solid red' : '1px solid rgba(34, 36, 38, .15)'}`}}  {...input} type={type} placeholder={label}/>
            <br/>
            {validDate &&
            <span style={{color: 'red'}}>Pleas enter Valid Date</span>
            }
            {touched && error && <span className='errorSpan'>{error}</span>}
        </div>
    )
}

let currentDateValue = ''
export const renderPatternDateInput = ({input, label,placeholder, type, meta: {touched, error}}) => {
    currentDateValue = input.value
    if (input.value.length > 0) {
        if (input.value.length  === 2 || input.value.length  === 5) {
            currentDateValue += "/";
        }
    }
    return (
        <div  className='mainDiv'>
            <label className='reduxFormLabel'>
                {label}
            </label>
            <input className='form-inputMoto' style={{width: '40%'}} {...input} maxLength={10} value={currentDateValue}  type='text' placeholder={placeholder}/>
            {touched && error && <div className='errorSpan'>{error}</div>}
        </div>
    );
};

export const renderField = ({input, label, type, meta: {touched, error}}) => (
    <div  className='mainDiv'>
        <label className='reduxFormLabel'>
            {label}
        </label>
        <input className='form-inputMoto' {...input} type={type} placeholder={label}/>
        {touched && error && <span className='errorSpan'>{error}</span>}
    </div>
);
export const renderFieldPhoneNumber = ({input, label, type, meta: {touched, error},max,min}) => (
    <div  className='mainDiv'>
        <label className='reduxFormLabel'>
            {label}
        </label>
        <input className='form-inputMoto' minlength={min} maxlength={max} {...input} type={type} placeholder={label}/>
        {touched && error && <span className='errorSpan'>{error}</span>}
    </div>
);
export const renderFieldVIN = ({input, label,placeHolder, type, meta: {touched, error}}) => (
    <div  className='mainDiv'>
        <label className='reduxFormLabel'>
            {label}
            <Popup
                wide='very'
                trigger={<Icon name='info circle' color='grey'/>}
                size='small'
            >
                VIN can be found on the registration or title
            </Popup>
        </label>

        <input className='form-inputMoto' maxlength={17} minLength={17} {...input} type={type} placeholder={placeHolder}/>
        {touched && error && <span className='errorSpan'>{error}</span>}
    </div>
);
export const renderFieldVINLastFive = ({input, label,placeHolder, type, meta: {touched, error}}) => (
    <div  className='mainDiv'>
        <label className='reduxFormLabel'>
            {label}
            <Popup
                wide='very'
                trigger={<Icon name='info circle' color='grey'/>}
                size='small'
            >
                VIN can be found on the registration or title
            </Popup>
        </label>

        <input className='form-inputMoto' maxlength={5} minLength={5} {...input} type={type} placeholder={placeHolder}/>
        {touched && error && <span className='errorSpan'>{error}</span>}
    </div>
);
export const renderFieldTextArea = ({input, label,holder, type, meta: {touched, error}}) => (
    <div  className='mainDiv'>
        <label className='reduxFormLabel'>
            {label}
        </label>
        <textarea className='form-inputMoto textareaNotResize' {...input} type={type} placeholder={holder}/>
        {touched && error && <span className='errorSpan'>{error}</span>}
    </div>
);

export const renderFieldZipCode = ({input,zipCodeValue, label, type, meta: {touched, error}}) => {

    return (
        <div className='mainDiv'>
            <label className='reduxFormLabel'>
                {label}
            </label>
            <input className='form-inputMoto' {...input} value={zipCodeValue}  type={type} placeholder={label}/>
            {touched && error && <span className='errorSpan'>{error}</span>}
        </div>
    );
}

export const renderFieldNotRequire = ({input, label, type, meta: {touched, error}}) => (
    <div  className='mainDiv'>
        <label className='reduxFormLabel'>
            {label}
        </label>
        <input className='form-inputMoto' {...input} type={type} placeholder={label}/>
        {touched && error && <span className='errorSpan'>{error}</span>}
    </div>
);

export const renderFieldSelectVehicle = ({options,input,changeDetails,valueYear, label,violation, type, meta: {touched, error}}) => (
    <div className='mainDiv'>
        <label className='reduxFormLabel'>
            {label}
        </label>
        <select {...input} className='selectOptions' onClick={(e) => changeDetails(e.target.value)} >
            <option disabled={true} selected={true} style={{visibility:'hidden'}}>{valueYear}</option>
            {options && options.map((el,index) => {
                return (
                    <option key={index} value={el}>{el}</option>
                )
            })}
        </select>
        {touched && error && <span className='errorSpan'>{error}</span>}
    </div>
);
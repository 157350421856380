import {createSlice} from "@reduxjs/toolkit";
import {homeownerViewModel as homeowner, propertyViewModel as property} from "./../formModels"

const initialState = {
    homeowner: homeowner,
    property: property,
    ownerHomeConfirm:false,
    currentStep:0,
}


const setOwnerPassHomeStepI = (state,action) => {
    return {
        ...state,
        ownerHomeConfirm: action.payload
    }
}

const setHomeFinalDataI = (state,action) => {
    return {
        ...state,
        homeOwnerFormFinalData: action.payload
    }
}


const setCurrentStepHome = (state,action) => {
    return {
        ...state,
        currentStep: action.payload
    }
}

const saveHomeownerResolver = (state, action) => {
    state.homeowner = action.payload
}

const savePropertyResolver = (state , action) => {
    state.property = action.payload
}

export const homeownerSlice = createSlice({
    name: "homeowner",
    initialState: initialState,
    reducers: {
        setOwnerPassHomeStep:setOwnerPassHomeStepI,
        setHomeFinalData:setHomeFinalDataI,
        setCurrentStep:setCurrentStepHome,
        saveHomeowner: saveHomeownerResolver,
        saveProperty: savePropertyResolver
    }
})

export const {
    setHomeFinalData,
    setCurrentStep,
    } = homeownerSlice.actions

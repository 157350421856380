import React from 'react';
import { Loader } from 'semantic-ui-react';
import styles from './loader.module.css'


export const PageLoader = () => {
    return (
        <div className={styles.mainLoader}>
             <Loader size='massive' className={styles.loader} active inline/>
        </div>
    )
}
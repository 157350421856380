import React, { useMemo } from "react";

import Layout from "../../layout";
import Hero from "../../components/Hero";
import { useContentFull } from "../../hooks/useContentFull";
import { getImageFromContentFull } from "../../utils/contentful";
import { PageLoader } from "../../components/PageLoader/PageLoader";
import get from "lodash.get";
const PrivacyPolicy = React.lazy(() => import('../../components/StaticPages/PrivacyPolicy'));

export default function PrivacyPolicyPage() {
  const content = {
    'bg_img': 'staic_pages_landing privacy-bg-image',
    'text': 'Privacy',
    'green_text': ' Policy',
    'description1': 'Effective Date: November 1st 2020',
    'NoNeedForm': true,
   
}
const {fields, loading, pictureTitle} = useContentFull()
const currentHeroImage = useMemo(() => {
    return getImageFromContentFull(fields.background)
},[fields])

if(loading || !currentHeroImage){
    return (
        <PageLoader />
    )
}
return (
    <Layout full>
        <Hero pictureTitle={pictureTitle} description={get(fields,'subtitle','')} image={currentHeroImage} content={content}/>
      <PrivacyPolicy fields={fields} />
    </Layout>
  );
}

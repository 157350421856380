import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {getAutoChange, getCurrentRequestForm} from "../../../selectors/requestAChangeSelector";
import {setAutoChange} from "../../../slices/requestAChnage";
import Layout from "../../../layout";
import ContactForm from "./form";
import {sendEmailChangeRequest} from "../../../services/sendEmail";
import emailjs from "emailjs-com";


export const InsuranceChangeRequest = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const main = useSelector(getAutoChange)
    const [disc, setDisc] = useState(false)
    const [discError, setDiscError] = useState(false)
    const [isFormSubmited, setIsFormSubmited] = useState(false)
    const currentForm = useSelector(getCurrentRequestForm)

    const renderCurrentFormTitle = useMemo(() => {
        if (currentForm === 'Home'){
            return 'Home Change Request'
        }else if (currentForm === 'Other'){
            return 'Other Change Request'
        }else {
            return 'Commercial Auto Change'
        }
    },[])

    const handleSubmit = (values) => {
        if (disc){
            dispatch(setAutoChange({
                name:`${currentForm} Request`,
                value:values.validate[0]
            }))
            setIsFormSubmited(true)
        }else{
            setDiscError(true)
        }
    }

    useEffect(async () => {
        if (main && currentForm){
            await emailjs.send("service_1d1ggng", "template_vkcxeiz", {
                subject: currentForm,
                firstName: main.MainInfo.firstName,
                lastName: main.MainInfo.lastName,
                phone: main.MainInfo.phone_number,
                policy: main.MainInfo.policy_number,
                email: main.MainInfo.apply_email,
                message: main[`${currentForm} Request`].other_change_message
            }, 'user_iuz4PywP4uJvUMl4sLkyV');
        }
        isFormSubmited && history.push(`/request-change-thank-you`)
    },[isFormSubmited])


    return (
        <Layout full>
            <div className="form-bg">
                <div className="container w-100">
                    <h1 className="text-center pt-5 pb-2">{renderCurrentFormTitle}</h1>
                    <ContactForm discError={discError} disc={disc} setDisc={setDisc} onSubmit={handleSubmit}/>
                </div>
            </div>
        </Layout>

    )
}
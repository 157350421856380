import React, {useEffect, useState} from "react";
import { Switch, Route } from "react-router";
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom'
import ThankYouPage from "./../../ThankYouPage"
import ContactForm from "./NonOwner";
import {useDispatch, useSelector} from "react-redux";
import {setNonOwnerFinalInfo} from "../../../slices/nonownerInsuranceSlice";
import {getNonOwnerInfo} from "../../../selectors/non-ownerSelector";
import {sendEmailQuotes, sendNonOwnerInsurance} from "../../../services/sendEmail";
import {PrivacyInformation} from "../../PrivicyPolicyUnderForm";
import emailjs from "emailjs-com";

export  function Main(){
    const location = useLocation()
    const history = useHistory()
    const dispatch = useDispatch()
    const currentLocation = location.pathname
    const { path} = useRouteMatch()
    const [emailForm,sendFormEmail] = useState(false)
    const finalForm = useSelector(getNonOwnerInfo)

    const handleSubmit = (values) => {
         dispatch(setNonOwnerFinalInfo({Non_Owner_Form:values.validate[0]}))
         sendFormEmail(true)
    }
    useEffect(async () => {
        if (emailForm && finalForm.Non_Owner_Form){
            const params = {
                subject: 'None Owner',
                firstName: finalForm.Non_Owner_Form.firstName ? finalForm.Non_Owner_Form.firstName : '',
                lastName: finalForm.Non_Owner_Form.lastName ? finalForm.Non_Owner_Form.lastName : '',
                date_of_birth: finalForm.Non_Owner_Form.date_of_birth ? finalForm.Non_Owner_Form.date_of_birth : '',
                city: finalForm.Non_Owner_Form.city ? finalForm.Non_Owner_Form.city : '',
                email: finalForm.Non_Owner_Form.renter_email ? finalForm.Non_Owner_Form.renter_email : '',
                number: finalForm.Non_Owner_Form.phone_number ? finalForm.Non_Owner_Form.phone_number : '',
                gender: finalForm.Non_Owner_Form.gender ? finalForm.Non_Owner_Form.gender : '',
                marital_status: finalForm.Non_Owner_Form.marital_status ? finalForm.Non_Owner_Form.marital_status : '',
                property_address: finalForm.Non_Owner_Form.property_address ? finalForm.Non_Owner_Form.property_address : '',
                state: finalForm.Non_Owner_Form.state ? finalForm.Non_Owner_Form.state : '',
                zipCode: finalForm.Non_Owner_Form.zipCode ? finalForm.Non_Owner_Form.zipCode : '',
                violation_state: finalForm.Non_Owner_Form.violation_state ? finalForm.Non_Owner_Form.violation_state : '',
                driver_license_number: finalForm.Non_Owner_Form.driver_license_number ? finalForm.Non_Owner_Form.driver_license_number : '',
            }
            await sendNonOwnerInsurance(params)
            history.push(`${location.pathname}/thank-you`)
        }
    },[emailForm])

    return (
        <Switch>
            <Route exact path={path}>
                <div className="form-bg">
                    <div className="container w-100">
                        <h1 className="text-center pt-5 pb-2">Non Owner Insurance</h1>
                        <ContactForm currentLocation={currentLocation} onSubmit={handleSubmit}/>
                        <PrivacyInformation/>
                    </div>
                </div>
            </Route>
            <Route path={`${path}/thank-you`}>
                <ThankYouPage/>
            </Route>
        </Switch>
    )
}

export default Main

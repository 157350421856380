import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {getAutoChange} from "../../../../selectors/requestAChangeSelector";
import {setAutoChange} from "../../../../slices/requestAChnage";
import {sendEmailChangeRequest} from "../../../../services/sendEmail";
import Layout from "../../../../layout";
import ContactForm from "./lienholderForm";
import emailjs from "emailjs-com";

export const LienholderChangeRequest = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const main = useSelector(getAutoChange)
    const [disc, setDisc] = useState(false)
    const [discError, setDiscError] = useState(false)
    const [isFormSubmited, setIsFormSubmited] = useState(false)

    const handleSubmit = (values) => {
        if (disc){
            dispatch(setAutoChange({
                name:'Lienholder Change',
                value:values.validate[0]
            }))
            setIsFormSubmited(true)
        }else{
            setDiscError(true)
        }
    }
    useEffect(async () => {
        if (main){
            await emailjs.send("service_1d1ggng", "template_5hrxhyq", {
                subject: main.RequestType,
                current: main.RequestSubType,
                firstName: main.MainInfo.firstName,
                lastName: main.MainInfo.lastName,
                phone: main.MainInfo.phone_number,
                policy: main.MainInfo.policy_number,
                email: main.MainInfo.apply_email,
                lienholder_name: main['Lienholder Change'].lienholder_name,
                lienholder_address: main['Lienholder Change'].lienholder_address,
                lienholder_message: main['Lienholder Change'].lienholder_message,
                date_request: main['Lienholder Change'].request_effective_date,
            }, 'user_iuz4PywP4uJvUMl4sLkyV');
        }
        isFormSubmited && history.push(`/request-change-thank-you`)
    },[isFormSubmited])


    return (
        <Layout full>
            <div className="form-bg">
                <div className="container w-100">
                    <h1 className="text-center pt-5 pb-2">Lienholder/Bank Change</h1>
                    <ContactForm discError={discError} disc={disc} setDisc={setDisc} onSubmit={handleSubmit}/>
                </div>
            </div>
        </Layout>

    )
}
import React, {useEffect, useState} from 'react';
import {Field, FieldArray, reduxForm} from 'redux-form';
import './../../../../assets/css/autoInsurance.css'
import Button from "@material-ui/core/Button";
import passValidation from "../../../utils/validate";
import {
    renderDate, renderDateToday,
    renderField, renderFieldTextArea,
} from "../../../utils/driver-redux-form";
import {RequestChangeDisclaimor} from "../../RequestChnageDisclaimor";

const renderMembers = React.memo(({fields, meta: {touched, error, submitFailed},mailing,discError,setMailing,disc,setDisc}) => {
    return (
        <div>
            {fields.map((validate, index) => (
                <ul key={index}>
                    <li  className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.lienholder_name`}
                            type="text"
                            component={renderField}
                            label="Lienholder Name"
                        />
                        <Field
                            name={`${validate}.lienholder_address`}
                            type="text"
                            component={renderField}
                            label="Lienholder Address"
                        />
                    </li>
                    <li  className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.lienholder_message`}
                            type="text"
                            component={renderFieldTextArea}
                            holder='Message'
                            label="What Vehicle would you like to add the lienholder to?"
                        />
                    </li>
                    <li  className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.request_effective_date`}
                            type="date"
                            component={renderDateToday}
                            label="Proposed Effective Date"
                        />
                    </li>
                </ul>
            ))}
            <RequestChangeDisclaimor disc={disc} setDisc={setDisc}/>
            {discError && <p style={{color:'red'}}>Please read and check online policy change request Disclaimer</p>}
            <div className={'addDriver'}>
                <button style={{display: 'none'}} className='addButton' id='defaultValues' type="button"
                        onClick={() => fields.push({})}/>
            </div>
        </div>
    )
})

const FieldArraysForm = props => {
    useEffect(() => {
        const defaultClick = document.getElementById('defaultValues')
        defaultClick.click()
    }, [])
    const [mailing, setMailing] = useState(true)
    const {handleSubmit} = props;
    return (
        <form onSubmit={handleSubmit} style={{marginTop: '25px'}}>
            <FieldArray name="validate" discError={props.discError} disc={props.disc} setDisc={props.setDisc} setMailing={setMailing} mailing={mailing}  component={renderMembers}/>
            <div className='request'>
                <Button type='submit'  style={{marginTop: '15px'}} variant="contained"
                        color="primary">
                    Submit Request
                </Button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'fieldArrays',
    validate: passValidation,
})(FieldArraysForm);

import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {getAttachedFile, getAutoChange, getAutoCurrentChange} from "../../../../../selectors/requestAChangeSelector";
import {useHistory} from "react-router-dom";
import {setAutoChange} from "../../../../../slices/requestAChnage";
import {sendAttachedFile, sendEmailChangeRequest} from "../../../../../services/sendEmail";
import Layout from "../../../../../layout";
import ContactForm from "./form";
import emailjs from "emailjs-com";



export const VehicleRemoveReplace = () => {
    const currentChangeRequest = useSelector(getAutoCurrentChange)
    const [disc, setDisc] = useState(false)
    const [discError, setDiscError] = useState(false)
    const [isFormSubmited, setIsFormSubmited] = useState(false)
    const dispatch = useDispatch()
    const main = useSelector(getAutoChange)
    const history = useHistory()
    const attachedFile = useSelector(getAttachedFile)

    const handleSubmit = (values) => {
        if (disc){
            dispatch(setAutoChange({
                name:'Vehicle Remove/Replace',
                value:values.validate[0],
            }))
            setIsFormSubmited(true)
        }else{
            setDiscError(true)
        }
    }
    useEffect(async () => {
        if (main){
            await emailjs.send("service_1d1ggng", "template_cg4q11c", {
                subject: 'Vehicle Remove/Replace',
                firstName: main.MainInfo.firstName,
                lastName: main.MainInfo.lastName,
                phone: main.MainInfo.phone_number,
                policy: main.MainInfo.policy_number,
                email: main.MainInfo.apply_email,
                vehicle_year: main['Vehicle Remove/Replace'].vehicle_year ? main['Vehicle Remove/Replace'].vehicle_year : '',
                vehicle_make: main['Vehicle Remove/Replace'].vehicle_make ? main['Vehicle Remove/Replace'].vehicle_make : '',
                vehicle_model: main['Vehicle Remove/Replace'].vehicle_model ? main['Vehicle Remove/Replace'].vehicle_model : '',
                vin_code_removed_vehicle: main['Vehicle Remove/Replace'].vin_code_removed_vehicle ? main['Vehicle Remove/Replace'].vin_code_removed_vehicle : '',
                vin_code_new_vehicle: main['Vehicle Remove/Replace'].vin_code_new_vehicle ? main['Vehicle Remove/Replace'].vin_code_new_vehicle : '',
                vehicle_purchase_date: main['Vehicle Remove/Replace'].vehicle_purchase_date ? main['Vehicle Remove/Replace'].vehicle_purchase_date : '',
                registered_owner_first_name: main['Vehicle Remove/Replace'].registered_owner_first_name ? main['Vehicle Remove/Replace'].registered_owner_first_name : '',
                registered_owner_last_name: main['Vehicle Remove/Replace'].registered_owner_last_name ? main['Vehicle Remove/Replace'].registered_owner_last_name : '',
                vehicle_current_miles: main['Vehicle Remove/Replace'].vehicle_current_miles ? main['Vehicle Remove/Replace'].vehicle_current_miles : '',
                vehicle_usage: main['Vehicle Remove/Replace'].vehicle_usage ? main['Vehicle Remove/Replace'].vehicle_usage : '',
                garaging_address_for_vehicle: main['Vehicle Remove/Replace'].garaging_address_for_vehicle ? main['Vehicle Remove/Replace'].garaging_address_for_vehicle : '',
                lienholder_bank_name: main['Vehicle Remove/Replace'].lienholder_bank_name ? main['Vehicle Remove/Replace'].lienholder_bank_name : '',
                lienholder_bank_address: main['Vehicle Remove/Replace'].lienholder_bank_address ? main['Vehicle Remove/Replace'].lienholder_bank_address : '',
                comprehensive_collision_deductible: main['Vehicle Remove/Replace'].comprehensive_collision_deductible ? main['Vehicle Remove/Replace'].comprehensive_collision_deductible : '',
                message_to_GM: main['Vehicle Remove/Replace'].message_to_GM ? main['Vehicle Remove/Replace'].message_to_GM : '',
                any_damage_text: main['Vehicle Remove/Replace'].any_damage_text ? main['Vehicle Remove/Replace'].any_damage_text : '',
                request_effective_date: main['Vehicle Remove/Replace'].request_effective_date ? main['Vehicle Remove/Replace'].request_effective_date : '',
            }, 'user_iuz4PywP4uJvUMl4sLkyV');
        }
        isFormSubmited && attachedFile && sendAttachedFile(attachedFile,`${main.MainInfo.firstName} ${main.MainInfo.lastName}`)
        isFormSubmited && history.push(`/request-change-thank-you`)
    },[isFormSubmited])
    return (
        <Layout full>
            <div className="form-bg">
                <div className="container w-100">
                    <h1 className="text-center pt-5 pb-2">{currentChangeRequest}</h1>
                    <ContactForm  discError={discError} disc={disc} setDisc={setDisc} onSubmit={handleSubmit}/>
                </div>
            </div>
        </Layout>
    )
}
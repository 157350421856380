import React, { useMemo } from "react";

import Layout from "../../layout";
import Hero from "../../components/Hero";
import { PageLoader } from "../../components/PageLoader/PageLoader";
import { useContentFull } from "../../hooks/useContentFull";
import get from "lodash.get";
const HomeOwnerInsurance = React.lazy(() => import('../../components/StaticPages/HomeOnwerInsurance'));

export default function HomeOnwerInsurance_Page() {
    const content = {
        'bg_img': 'staic_pages_landing homeowner-isurance-bg-image',
        'text': 'Homeowner',
        'green_text': 'Insurances',
        'text2': 'Quotes',
        link: "/home-owner-insurance-form",
    }

    const {fields, loading, pictureTitle} = useContentFull()
    const currentHeroImage = useMemo(() => {
        return get(fields, 'background.fields.file.url', '')
    },[fields])

    if(loading || !currentHeroImage){
        return (
            <PageLoader />
        )
    }

    return (
        <Layout full>
            <title>{get(fields, 'pageTitle', '')}</title>
            <Hero pictureTitle={pictureTitle} image={currentHeroImage} content={content}/>
            <HomeOwnerInsurance fields={fields} />
        </Layout>
    );
}
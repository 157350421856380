import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { useRouteMatch} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
}));

function getSteps() {
    return ['VEHICLE', 'DRIVER'];
}


export default function Steps({content}) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const { path} = useRouteMatch()


    useEffect(()=>{
        if (path === "/auto-insurance-form") {
            setActiveStep(0)
        }
        else if (path === "/auto-insurance-form/driver") {
            setActiveStep(1)
        }
        else if (path === "/auto-insurance-form/thank-you") {
            setActiveStep(2)
        }else if (path === "/dui-insurance-form") {
            setActiveStep(0)
        }
        else if (path === "/dui-insurance-form/driver") {
            setActiveStep(1)
        }
        else if (path === "/dui-insurance-form/thank-you") {
            setActiveStep(2)
        }
    },[path])

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel style={{backgroundColor:"#f3f7fc"}}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
             {content}
        </div>
    );
}

import React, {useEffect, useState} from 'react';
import '../../../assets/css/autoInsurance.css'
import Button from "@material-ui/core/Button";
import {useDispatch} from "react-redux";
import {setCurrentRequestChange} from "../../../slices/requestAChnage";
import {useHistory} from "react-router-dom";

export const CurrentInsuranceRequest = React.memo(({nextStep, previousStep}) => {
    const datas = ['Auto','Home','Commercial Auto','Other']
    const [currentRequest, setCurrentRequest] = useState()
    const dispatch = useDispatch()
    const history = useHistory();

    const setCurrentRequestSubmit = (index,elem) => {
        setCurrentRequest(index)
        dispatch(setCurrentRequestChange(elem))
        if (elem !== 'Auto'){
            setTimeout(() => {
                history.push('/change-request')
            }, 150)
        }else{
            setTimeout(() => {
                nextStep()
            }, 150)
        }
    }


    return (
        <div>
            <div className='requestChangeCurrent'>
                <div className='requestChangeHeading'>
                    Please choose your policy
                </div>
            </div>
            <div className='content_container'>
                <div className='input_container'>
                    <div className='displaying_content'>
                        {datas.map((el,index) => {
                            return (
                                <article key={index} className="feature2">
                                    <input onChange={() => setCurrentRequestSubmit(index,el)} checked={index === currentRequest}  type="checkbox" id="feature2"/>
                                    <div>
                                        {el}
                                    </div>
                                </article>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className='nextPrevRequestButtonMainDiv'>
                <Button type='submit' style={{marginTop: '15px'}} onClick={previousStep} variant="contained"
                        color="primary">
                    Previous
                </Button>
            </div>
        </div>
    )
})
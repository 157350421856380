import {Field, FieldArray, reduxForm} from "redux-form";
import {
    renderDate,
    renderDateToday,
    renderField, renderFieldNotRequire, renderFieldSelect, renderPatternDateInput,
} from "../../../../utils/driver-redux-form";
import {RequestChangeDisclaimor} from "../../../RequestChnageDisclaimor";
import React, {useEffect} from "react";
import Button from "@material-ui/core/Button";
import passValidation from "../../../../utils/validate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserPlus} from "@fortawesome/free-solid-svg-icons";
import {marital_status, relation_to_insured, states} from "../../../../../static/select-options/driver-select-options";
import {gender_options} from "../../../../../static/select-lists/forms-options";

const renderMembers = React.memo(({fields, meta: {touched, error, submitFailed},addNewDriver,setAddNewDriver,discError,disc,setDisc}) => {
    return (
        <div>
            {fields.map((validate, index) => (
                <ul key={index}>
                    <li  className='reduxFromLiMoto'>
                        <div className='removeReplace'>
                            <span className='removeReplaceText'>Remove</span>
                        </div>
                    </li>
                    <li  className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.firstName`}
                            type="text"
                            component={renderField}
                            label="First Name"
                        />
                        <Field
                            name={`${validate}.lastName`}
                            type="text"
                            component={renderField}
                            label="Last Name"
                        />
                    </li>
                    <li  className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.date_of_birth`}
                            type="text"
                            placeholder={'MM/DD/YYYY'}
                            component={renderPatternDateInput}
                            label="Date of Birth"
                        />
                    </li>
                    {addNewDriver &&
                    <li  className='reduxFromLiMoto'>
                        <div className='removeReplace'>
                            <span className='removeReplaceText'>Add</span>
                        </div>
                    </li>}
                    {addNewDriver &&
                    <li  className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.new_driver_firstName`}
                            type="text"
                            component={renderField}
                            label="First Name"
                        />
                        <Field
                            name={`${validate}.new_driver_lastName`}
                            type="text"
                            component={renderField}
                            label="Last Name"
                        />
                    </li>}
                    {addNewDriver &&
                    <li  className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.new_driver_license_number`}
                            type="text"
                            component={renderField}
                            label="Drivers License Number"
                        />
                        <Field
                            name={`${validate}.new_driver_date_of_birth`}
                            type="date"
                            component={renderDate}
                            label="Date of Birth"
                        />
                    </li>}
                    {addNewDriver &&
                    <li  className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.driver_state`}
                            component={renderFieldSelect}
                            label="State Licensed"
                            options={states}
                        />
                        <Field
                            name={`${validate}.driver_relationship_to_insured`}
                            component={renderFieldSelect}
                            label="Relationship to Insured "
                            options={relation_to_insured}
                        />
                    </li>}
                    {addNewDriver &&
                    <li  className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.marital_status`}
                            component={renderFieldSelect}
                            label="Marital Status"
                            options={marital_status}
                        />
                        <Field
                            name={`${validate}.message_to_GM`}
                            type="text"
                            component={renderFieldNotRequire}
                            label="Message to GM Insurance Services"
                        />
                    </li>}
                    {addNewDriver &&
                    <li  className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.gender`}
                            component={renderFieldSelect}
                            label="Gender"
                            options={gender_options}
                        />
                    </li>}
                    <li  className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.request_effective_date`}
                            type="date"
                            component={renderDateToday}
                            label="Proposed Effective Date"
                        />
                    </li>
                </ul>
            ))}

            {!addNewDriver &&
            <div>
                <span className='addSpan'>Do you want to add another driver ?</span>
                 <FontAwesomeIcon onClick={() => setAddNewDriver(true)} icon={faUserPlus} size='lg' className='addIcon requestIcon' color='green'/>
            </div>}
            <RequestChangeDisclaimor disc={disc} setDisc={setDisc}/>
            {discError && <p style={{color:'red'}}>Please read and check online policy change request Disclaimer</p>}
            <div className={'addDriver'}>
                <button style={{display: 'none'}} className='addButton' id='defaultValues' type="button"
                        onClick={() => fields.push({})}/>
            </div>
        </div>
    )
})

const FieldArraysForm = props => {
    useEffect(() => {
        const defaultClick = document.getElementById('defaultValues')
        defaultClick.click()
    }, [])
    const {handleSubmit, submitting} = props;
    return (
        <form onSubmit={handleSubmit} style={{marginTop: '25px'}}>
            <FieldArray name="validate" addNewDriver={props.addNewDriver} setAddNewDriver={props.setAddNewDriver} discError={props.discError} disc={props.disc} setDisc={props.setDisc}   component={renderMembers}/>
            <div className='request'>
                <Button type='submit'  style={{marginTop: '15px'}} variant="contained"
                        color="primary">
                    Submit Request
                </Button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'fieldArrays',
    validate: passValidation,
})(FieldArraysForm);
import {createSelector} from "reselect";


const RequestAChange = (state) => state.requestAChange

export const getMainFirstStep = createSelector(RequestAChange,state => state.MainFirstStep)

export const getCurrentRequestForm = createSelector(RequestAChange,state => state.currentRequest)

export const getAutoRequestType = createSelector(RequestAChange,state => state.autoRequestType)

export const getAutoChange = createSelector(RequestAChange,state => state.autoChange)

export const getAutoCurrentChange = createSelector(RequestAChange,state => state.AddRemoveChangeAutoInfo)

export const getAttachedFile = createSelector(RequestAChange,state => state.attachment)

export const getReviews = createSelector(RequestAChange,state => state.reviews)

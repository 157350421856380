import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { driverViewModel, vehicleViewModel } from "./../formModels"
import { API } from "aws-amplify";
import { listMakes, listModels, listTrims, listYears } from "../graphql/queries";
import axios from "axios";


const initialState = {
    driverViewModels: [driverViewModel],
    vehicleViewModels: [vehicleViewModel],
    step: 0,
    scrollPositionIntoView: 100,
    currentVehicle: -1,
    autoInsuranceFinalCredentials: [],
    vehicleCredentials: [],
    vehicleYear: null,
    vehicleMake: null,
    model: null,
    subModel: null,
    comprehensiveDeductible: null,
    collisionDeductible: null,
    vehicleUsage: null,
    zipCode: null,
    finalAutoForm: null,
    finalDUIForm: null,
    finalSR22Form: null,
    vehicleForm: null,
    driverForm: null,
    makes: [],
    models: [],
    sub_models: [],
    vehicleGraphQl: null,
    finalForm: null,
    CV: '',
    vinCodes: {}
}


const setVehicleGraphQlI = (state, action) => {
    return {
        ...state,
        vehicleGraphQl: action.payload
    }
}

const setCVApply = (state, action) => {
    return {
        ...state,
        CV: action.payload
    }
}

const setDriverFromI = (state, action) => {
    return {
        ...state,
        driverForm: { drivers: action.payload }
    }
}

const setVehicleFormI = (state, action) => {
    return {
        ...state,
        vehicleForm: { vehicles: action.payload }
    }
}



export const setFinalFormI = (state) => {
    return {
        ...state,
        finalForm: [state.vehicleForm, state.driverForm]
    }
}


export const updateVehicleByVIN = (state, action) => {
    const finalResult = {
        ...state.vehicleViewModels[action.payload.index],
        fetchResult: {
            ...state.vehicleViewModels[action.payload.index].fetchResult,
            [action.payload.key]: {
                ...state.vehicleViewModels[action.payload.index].fetchResult[action.payload.key],
                result: [...state.vehicleViewModels[action.payload.index].fetchResult[action.payload.key].result, action.payload.value],
                status: "succeeded"
            }
        }
    }

    const newVehicleViewModels = [...state.vehicleViewModels]
    newVehicleViewModels.splice(action.payload.index, 1, finalResult)


    return {
        ...state,
        vehicleViewModels: newVehicleViewModels
    }
}

export const setNewVinCode = (state, action) => {
    const newVins = state.vinCodes

    return {
        ...state,
        vinCodes: {...newVins, [action.payload.id]: {
            value: action.payload.value,
            status: action.payload.status
        }}
    }
}


const updateVehicleDataI = (state, action) => {
    return {
        ...state,
        vehicleViewModels: action.payload
    }
}

const addDriverResolver = (state, action) => {
    state.driverViewModels.map((v) => {
        return v.isChecked = true
    })
    state.driverViewModels.push(driverViewModel)
}

const updateDriverResolve = (state, action) => {
    const { index, name, value } = action.payload
    const nameAfterDot = name.substring(name.indexOf(".") + 1)
    state.driverViewModels[index] = { ...state.driverViewModels[index], [nameAfterDot]: value }
}

const deleteDriverResolver = (state, action) => {
    state.driverViewModels.splice(action.payload.index + 1, 1)
    state.driverViewModels.map((d, i) => {
        return d.isChecked = !(i === state.driverViewModels.length - 1)
    })
}

const saveDriversResolver = (state, action) => {
    state.driverViewModels = action.payload
}

const addVehicleResolver = (state, action) => {
    state.vehicleViewModels.map((viewModel) => {
        return viewModel.vehicle.isChecked = true
    })
    state.vehicleViewModels.push(vehicleViewModel)
}

const updateVehicleResolve = (state, action) => {
    const { index, name, value } = action.payload
    state.vehicleViewModels[index].vehicle = { ...state.vehicleViewModels[index].vehicle, [name]: value }
}

const deleteVehicleResolver = (state, action) => {
    const { index } = action.payload
    state.vehicleViewModels.splice(index + 1, 1)
    state.vehicleViewModels.map((viewModel, i) => {
        return viewModel.vehicle.isChecked = !(i === state.vehicleViewModels.length - 1)
    })
}

const setNextStepResolver = (state, action) => {
    state.step = action.payload
}

const setZipcodeResolver = (state, action) => {
    state.zipCode = action.payload
}

const setScrollPositionIntoViewReducer = (state, action) => {
    return {
        ...state,
        scrollPositionIntoView: action.payload
    }
}
const DEFAULT_HEADERS = {
    headers: {
        'X-RapidAPI-Key': 'f2a4efaceemsh2fe573f8628acb1p101adfjsnd83acfd5b2f7',
        'X-RapidAPI-Host': 'car-api2.p.rapidapi.com'
    }
}

export const fetchYears = createAsyncThunk(
    'years/fetchYears',
    async ({ index }) => {
        const res = await axios.get('https://car-api2.p.rapidapi.com/api/years', DEFAULT_HEADERS)
        //Not deleting GraphQP realisation
        // let {data:{listYears:years}} = await API.graphql({query:listYears})
        // years = years.map(obj => obj.year)
        return { index: index, result: res.data.map(String) }
    }
)

export const fetchMakesByYear = createAsyncThunk(
    'years/fetchMakesByYear',
    async ({ index, year }) => {
        const res = await axios.get(`https://car-api2.p.rapidapi.com/api/makes?year=${year}`, DEFAULT_HEADERS)
        const mapped = res.data.data.map((curr) => curr.name)
        //Not deleting GraphQP realisation
        // let {data:{listMakes:makes}} = await API.graphql({query:listMakes, variables:{year:year}})
        // makes = makes.map(obj => obj.make)
        return { index: index, result: mapped }
    }
)

export const fetchModelsByMake = createAsyncThunk(
    'years/fetchModelsByMake',
    async ({ index, make }) => {
        const res = await axios.get(`https://car-api2.p.rapidapi.com/api/models?make=${make}&year=2020`, DEFAULT_HEADERS)
        const mapped = res.data.data.map((curr) => curr.name)
        //Not deleting GraphQP realisation
        // let {data:{listModels:models}} = await API.graphql({query:listModels, variables:{make:make}})
        // models = models.map(obj => obj.model)
        return { index: index, result: mapped }
    }
)

export const fetchTrimsByModel = createAsyncThunk(
    'years/fetchTrimsByModel',
    async ({ index, model }) => {
        const res = await axios.get(`https://car-api2.p.rapidapi.com/api/trims?model=${model}&year=2020`, DEFAULT_HEADERS)
        const mapped = res.data.data.map((curr) => curr.name)
        //Not deleting GraphQP realisation
        // let {data:{listTrims:trims}} = await API.graphql({query:listTrims, variables:{model:model}})
        // trims = trims.map(obj => obj.trim)
        return { index: index, result: mapped }
    }
)

export const autoInsuranceSlice = createSlice({
    name: "autoInsurance",
    initialState: initialState,
    reducers: {
        setScrollPositionIntoView: setScrollPositionIntoViewReducer,
        setZipcode: setZipcodeResolver,
        setNextStep: setNextStepResolver,
        addDriver: addDriverResolver,
        removeDriver: deleteDriverResolver,
        updateDriver: updateDriverResolve,
        saveDrivers: saveDriversResolver,
        addVehicle: addVehicleResolver,
        removeVehicle: deleteVehicleResolver,
        updateVehicle: updateVehicleResolve,
        setVehicleForm: setVehicleFormI,
        setDriverFrom: setDriverFromI,
        setVehicleGraphQl: setVehicleGraphQlI,
        updateVehicleData: updateVehicleDataI,
        setFinalForm: setFinalFormI,
        setCV: setCVApply,
        vinUpdate: updateVehicleByVIN,
        setVechilsesVin: setNewVinCode
    },
    extraReducers: {
        // Fetch years
        [fetchYears.fulfilled]: (state, action) => {
            const { index, result } = action.payload
            state.vehicleViewModels[index].fetchResult.fetchYearsResult.status = 'succeeded'
            state.vehicleViewModels[index].fetchResult.fetchYearsResult.result = result
        },
        [fetchYears.pending]: (state, action) => {
            const { index } = action.meta.arg
            state.vehicleViewModels[index].fetchResult.fetchYearsResult.status = 'loading'
            state.vehicleViewModels[index].fetchResult.fetchMakesResult.result = []
            state.vehicleViewModels[index].fetchResult.fetchModelsResult.result = []
            state.vehicleViewModels[index].fetchResult.fetchTrimsResult.result = []
        },
        [fetchYears.rejected]: (state, action) => {
            const { index } = action.meta.arg
            state.vehicleViewModels[index].fetchResult.fetchYearsResult.status = 'failed'
            state.vehicleViewModels[index].fetchResult.fetchYearsResult.error = action.error.message
            state.vehicleViewModels[index].fetchResult.fetchYearsResult.result = []
        },

        // Fetch makes
        [fetchMakesByYear.fulfilled]: (state, action) => {
            const { index, result } = action.payload
            state.vehicleViewModels[index].fetchResult.fetchMakesResult.status = 'succeeded'
            state.vehicleViewModels[index].fetchResult.fetchMakesResult.result = result
        },
        [fetchMakesByYear.pending]: (state, action) => {
            const { index } = action.meta.arg
            state.vehicleViewModels[index].fetchResult.fetchMakesResult.status = 'loading'
            state.vehicleViewModels[index].fetchResult.fetchModelsResult.result = []
            state.vehicleViewModels[index].fetchResult.fetchTrimsResult.result = []
        },
        [fetchMakesByYear.rejected]: (state, action) => {
            const { index } = action.meta.arg
            state.vehicleViewModels[index].fetchResult.fetchMakesResult.status = 'failed'
            state.vehicleViewModels[index].fetchResult.fetchMakesResult.error = action.error.message
            state.vehicleViewModels[index].fetchResult.fetchMakesResult.result = []
        },

        // Fetch models
        [fetchModelsByMake.fulfilled]: (state, action) => {
            const { index, result } = action.payload
            state.vehicleViewModels[index].fetchResult.fetchModelsResult.status = 'succeeded'
            state.vehicleViewModels[index].fetchResult.fetchModelsResult.result = result
        },
        [fetchModelsByMake.pending]: (state, action) => {
            const { index } = action.meta.arg
            state.vehicleViewModels[index].fetchResult.fetchModelsResult.status = 'loading'
            state.vehicleViewModels[index].fetchResult.fetchTrimsResult.result = []
        },
        [fetchModelsByMake.rejected]: (state, action) => {
            const { index } = action.meta.arg
            state.vehicleViewModels[index].fetchResult.fetchModelsResult.status = 'failed'
            state.vehicleViewModels[index].fetchResult.fetchModelsResult.error = action.error.message
            state.vehicleViewModels[index].fetchResult.fetchModelsResult.result = []
        },

        // Fetch trims
        [fetchTrimsByModel.fulfilled]: (state, action) => {
            const { index, result } = action.payload
            state.vehicleViewModels[index].fetchResult.fetchTrimsResult.status = 'succeeded'
            state.vehicleViewModels[index].fetchResult.fetchTrimsResult.result = result
        },
        [fetchTrimsByModel.pending]: (state, action) => {

            const { index } = action.meta.arg
            state.vehicleViewModels[index].fetchResult.fetchTrimsResult.status = 'loading'
        },
        [fetchTrimsByModel.rejected]: (state, action) => {
            const { index } = action.meta.arg
            state.vehicleViewModels[index].fetchResult.fetchTrimsResult.status = 'failed'
            state.vehicleViewModels[index].fetchResult.fetchTrimsResult.error = action.error.message
            state.vehicleViewModels[index].fetchResult.fetchTrimsResult.result = []
        }
    }
})

export const {
    setFinalForm,
    setVehicleGraphQl,
    setDriverFrom,
    setVehicleForm,
    setFinalAutoForm,
    setZipcode,
    setScrollPositionIntoView,
    setNextStep,
    setCV,
    saveDrivers,
    addDriver,
    removeDriver,
    updateDriver,
    updateVehicleData,
    addVehicle,
    removeVehicle,
    vinUpdate,
    setVechilsesVin,
    updateVehicle } = autoInsuranceSlice.actions

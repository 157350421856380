import React, { useEffect, useState } from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import validateDriver from '../../utils/validateDriver';
import '../../../../src/assets/css/autoInsurance.css'
import {
    gender_options, liability_limit,
    marital_status, relation_to_insured,
    states
} from "../../../static/select-options/driver-select-options";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserMinus, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import {
    renderDate,
    renderField,
    renderFieldPhoneNumber,
    renderFieldSelect, renderFieldZipCode, renderPatternDateInput,
} from "../../utils/driver-redux-form";
import { useSelector } from "react-redux";


const renderMembers = React.memo(({ fields,
    meta: { touched, error,
        submitFailed }, location,
    zipCode,
    handleViolation, violation, setViolation }) => {
    return (
        <div>
            {fields.map((drivers, index) => (
                <ul key={index}>
                    {location.currentLocation === '/motorcycle-insurance-form' && index < 1 &&
                        <li className='reduxFromLiMoto'>
                            <Field
                                name={`${drivers}.moto_vin_make_model`}
                                type="text"
                                component={renderField}
                                label="Motorcycle VIN or Year, Make and Model"
                            />
                        </li>}
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${drivers}.firstName`}
                            type="text"
                            component={renderField}
                            label="First Name"
                        />
                        {location.currentLocation !== '/motorcycle-insurance-form' && <Field
                            name={`${drivers}.middleName`}
                            type="text"
                            component={renderField}
                            label="Middle Name"
                        />}
                        <Field
                            name={`${drivers}.lastName`}
                            type="text"
                            component={renderField}
                            label="Last Name"
                        />
                    </li>
                    {index < 1 &&
                        <li className='reduxFromLiMoto'>
                            <Field
                                name={`${drivers}.homeAddress`}
                                type="text"
                                component={renderField}
                                label="Home Address"
                            />
                        </li>
                    }
                    {index < 1 &&
                        <li className='reduxFromLiMoto'>
                            <Field
                                name={`${drivers}.city`}
                                type="text"
                                component={renderField}
                                label="City"
                            />
                            <Field
                                name={`${drivers}.state`}
                                component={renderFieldSelect}
                                label="State"
                                options={states}
                            />
                            {zipCode ?
                                <Field
                                    name={`${drivers}.zipCode`}
                                    type="number"
                                    zipCodeValue={Number(zipCode)}
                                    component={renderFieldZipCode}
                                    label="Zip Code"
                                /> :
                                <Field
                                    name={`${drivers}.zipCode`}
                                    type="number"
                                    component={renderField}
                                    label="Zip Code"
                                />}
                        </li>
                    }
                    {index < 1 &&
                        <li className='reduxFromLiMoto'>
                            <Field
                                name={`${drivers}.contact`}
                                type="number"
                                component={renderFieldPhoneNumber}
                                label="Phone Number"
                            />
                            <Field
                                name={`${drivers}.email`}
                                type="email"
                                component={renderField}
                                label="Email Address"
                            />
                        </li>
                    }
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${drivers}.date_of_birth`}
                            type="text"
                            placeholder={'MM/DD/YYYY'}
                            component={renderPatternDateInput}
                            label="Date of Birth"
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${drivers}.gender`}
                            component={renderFieldSelect}
                            label="Gender"
                            options={gender_options}
                        />
                        <Field
                            name={`${drivers}.marital_status`}
                            component={renderFieldSelect}
                            label="Marital Status"
                            options={marital_status}
                        />
                    </li>
                    {/* <li className='reduxFromLiMoto'>
                        <div className='haveViolation'>
                            <span
                                className='violation'>Have you had any moving violations in the past 3 (three) years? </span>
                            <label style={{marginRight: '10px'}}>
                                <Field onChange={() => handleViolation(true,index)} name={`${drivers}.violation`}
                                       component="input" checked={violation[index] === true ? true : false} type="radio" value="Yes"/>{' '}
                                Yes
                            </label>
                            <label style={{marginRight: '10px'}}>
                                <Field onChange={() => handleViolation(false,index)} name={`${drivers}.violation`}
                                       checked={violation[index] === false ? true : false} component="input" type="radio" value="No"/>{' '}
                                No
                            </label>
                        </div>
                    </li> */}

                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${drivers}.driver_license_number`}
                            component={renderField}
                            label="Driver License Number"
                            type='text'
                        />
                        <Field
                            name={`${drivers}.violation_state`}
                            component={renderFieldSelect}
                            label="State"
                            options={states}
                        />
                    </li>
                    {location.currentLocation !== '/motorcycle-insurance-form' && index < 1 ?
                        <li className='reduxFromLiMoto'>
                            <Field
                                name={`${drivers}.liability_limit`}
                                component={renderFieldSelect}
                                label="DESIRED LIABILITY LIMIT"
                                options={liability_limit}
                            />
                        </li>
                        : null}
                    {index > 0 &&
                        <li className='reduxFromLiMoto'>
                            <Field
                                name={`${drivers}.relation_to_insured`}
                                component={renderFieldSelect}
                                label="Drivers relation to insured"
                                options={relation_to_insured}
                            />
                        </li>
                    }

                    {index > 0 &&
                        <li className='reduxFromLiMoto'>
                            <div>
                                <span style={{ fontSize: '18px' }}>Delete Driver</span>
                                <button
                                    className='addButton'
                                    id='defaultValues'
                                    type="button"
                                    onClick={() => fields.remove(index)}
                                >
                                    <FontAwesomeIcon icon={faUserMinus} size='lg' className='addIcon' color='red' />
                                </button>
                            </div>
                        </li>
                    }
                </ul>
            ))}
            <div className={'addDriver'}>
                <span className='addSpan'>Do you want to add another driver ?</span>
                <button className='addButton' id='defaultValues' type="button" onClick={() => {
                    fields.push({})
                    setViolation([...violation, false])
                }}>
                    <FontAwesomeIcon icon={faUserPlus} size='lg' className='addIcon' color='green' />
                </button>
                {(touched || submitFailed) && error && <span>{error}</span>}
            </div>
        </div>
    )
})

const FieldArraysForm = props => {
    const zipCode = useSelector(state => state.autoInsurance.zipCode)

    useEffect(() => {
        const defaultClick = document.getElementById('defaultValues')
        defaultClick.click()
    }, [])
    const [violation, setViolation] = useState([])
    const handleViolation = (data, index) => {
        const handledViol = violation.map((el, cur) => {
            if (violation[index] === true && cur === index) {
                return false
            } else if (violation[index] === false && cur === index) {
                return true
            } else {
                return el
            }
        })
        setViolation(handledViol)
    }
    const { handleSubmit, submitting } = props;

    return (
        <form onSubmit={handleSubmit} style={{ marginTop: '25px' }}>
            <FieldArray name="drivers" zipCode={zipCode} setViolation={setViolation} violation={violation} handleViolation={handleViolation} location={props} component={renderMembers} />
            <div className='request'>
                <Button type='submit' disabled={submitting} style={{ marginTop: '15px' }} variant="contained" color="primary" >
                    REQUEST A QUOTE
                </Button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'fieldArrays',
    validate: validateDriver,
})(FieldArraysForm);

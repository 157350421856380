import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {setCurrentAutoChangeOption} from "../../../../slices/requestAChnage";
import Layout from "../../../../layout";
import {detectCurrentVehicleRequestType} from "./util";

export const VehicleRequest = () => {
    const datas = ['Add Vehicle','Remove/Replace Vehicle']
    const [currentRequest, setCurrentRequest] = useState()
    const dispatch = useDispatch()
    const history = useHistory()

    const setCurrentRequestSubmit = (index,elem) => {
        setCurrentRequest(index)
        dispatch(setCurrentAutoChangeOption(elem))
        setTimeout(() => {
            history.push(detectCurrentVehicleRequestType(elem))
        }, 150)
    }
    return(
        <Layout full>
            <div className="App">
                <div>
                    <div className='requestChangeCurrent'>
                        <div className='requestChangeHeading'>
                            Please choose your request type
                        </div>
                    </div>
                    <div className='content_container driver_container'>
                        <div className='input_container'>
                            <div className='displaying_content'>
                                {datas.map((el,index) => {
                                    return (
                                        <article key={index} className="feature2">
                                            <input onChange={() => setCurrentRequestSubmit(index,el)} checked={index === currentRequest}  type="checkbox" id="feature2"/>
                                            <div>
                                                {el}
                                            </div>
                                        </article>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
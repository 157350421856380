import React, {useEffect} from 'react';
import {Field, FieldArray, reduxForm} from 'redux-form';
import '../../../../src/assets/css/autoInsurance.css'
import {
    company_type,
     vehicleNUmbers, yeras_in_buisness
} from "../../../static/select-options/driver-select-options";
import Button from "@material-ui/core/Button";
import {
    renderDate,
    renderField,
    renderFieldNotRequire,
    renderFieldSelect,
    renderFieldSelectRequire, renderPatternDateInput
} from "../../utils/driver-redux-form";
import passValidation from "../../utils/validate";


const renderMembers = React.memo(({fields, meta: {touched, error, submitFailed},location}) =>{
    return (
        <div>
            {fields.map((validate,index) => (
                <ul key={index}>
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.companyType`}
                            component={renderFieldSelectRequire}
                            label="Company Type"
                            options={company_type}
                        />
                        <Field
                            name={`${validate}.companyName`}
                            type="text"
                            component={renderField}
                            label="Company Name"
                        />
                    </li>
                    <li  className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.website`}
                            type="text"
                            component={renderFieldNotRequire}
                            label="Company Website"
                        />
                        <Field
                            name={`${validate}.yearsInBussiness`}
                            component={renderFieldSelect}
                            label="Years In Business"
                            options={yeras_in_buisness}
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <div className='haveViolation'>
                            <span className='violation'>Currently Insured</span>
                            <label style={{marginLeft:'15px'}}>
                                <Field name={`${validate}.currentlyInsured`} component="input" type="radio" value="Yes" />{' '}
                                Yes
                            </label>
                            <label style={{marginLeft:'10px'}}>
                                <Field  name={`${validate}.currentlyInsured`} component="input" type="radio" value="No" />{' '}
                                No
                            </label>
                        </div>
                    </li>
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.date_of_birth`}
                            type="text"
                            placeholder={'MM/DD/YYYY'}
                            component={renderPatternDateInput}
                            label="Proposed Effective Date"
                        />
                    </li>
                    <li className='reduxFromLiMoto' style={{marginTop:'30px'}}>
                        <h3>Business Operations </h3>
                    </li>
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.descriptionBusiness`}
                            type="text"
                            component={renderField}
                            label="BUSINESS DESCRIPTION"
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.vehicleNumber`}
                            component={renderFieldSelectRequire}
                            options={vehicleNUmbers}
                            label="NUMBER OF VEHICLES"
                        />
                        <Field
                            name={`${validate}.driversNUmber`}
                            component={renderFieldSelectRequire}
                            options={vehicleNUmbers}
                            label="NUMBER OF DRIVERS"
                        />
                    </li>
                </ul>
            ))}
            <div className={'addDriver'}>
                <button style={{display:'none'}} className='addButton' id='defaultValues' type="button" onClick={() => fields.push({})}/>
            </div>
        </div>
    )
})

const FieldArraysForm = props => {
    useEffect(() => {
        const defaultClick = document.getElementById('defaultValues')
        defaultClick.click()
    },[])

    const {handleSubmit, submitting} = props;
    return (
        <form onSubmit={handleSubmit} style={{marginTop:'25px'}}>
            <FieldArray name="validate" location={props} component={renderMembers}/>
            <div className='request'>
                <Button type='submit'  disabled={submitting} style={{marginTop:'15px'}} variant="contained" color="primary" >
                    Next
                </Button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'fieldArrays',
    validate: passValidation,
})(FieldArraysForm);

import React from 'react'
import MainStep from "../FirstStep-Authentication";
import {CurrentInsuranceRequest} from "../SecondStep-SelectCurrentInsurance";
import {AutoRequestType} from "../AutoRequest/RequestType";

export const CurrentChangeRequestComponent = ({step,nextStep,previousStep}) => {
    const stepDetectFunction = () => {
        if (step === 1) {
            return (
                <MainStep nextStep={nextStep} previousStep={previousStep}/>
            )
        }if (step === 2){
            return (
                <CurrentInsuranceRequest nextStep={nextStep} previousStep={previousStep} />
            )
        }if (step > 2){
            return (
                <AutoRequestType nextStep={nextStep} previousStep={previousStep}/>
            )
        }
    }
    return (
        <div className="form-bg">
            <div className="container w-100">
                {stepDetectFunction()}
            </div>
        </div>
    )
}
let years = [];
for (let i = 1981; i <= 2021; i++) {
    years.push(i);
}


const deductibleOptions = [
    { key: '1', text: 'Reject', value: 'reject' },
    { key: '2', text: '$500', value: '$500' },
    { key: '3', text: '$1000', value: '$1000' },
]

const vehicleUsageOptions = [
    { key: '1', text: 'Pleasure', value: 'pleasure' },
    { key: '2', text: 'Commute to Work/School', value: 'Commute to Work/School' },
    { key: '3', text: 'Business', value: 'business' },
]

const vehicleUsageOptionsRequestChange = [
    { key: '1', text: 'To Work or School', value: 'To Work or School ' },
    { key: '2', text: 'Commercial', value: 'Commercial' },
    { key: '3', text: 'Pleasure ', value: 'Pleasure ' },
]

export {
    years,
    deductibleOptions,
    vehicleUsageOptions,
    vehicleUsageOptionsRequestChange
};
import React from "react";
import {Link, Route, Switch, useHistory, useLocation} from "react-router-dom";
import {useRouteMatch} from 'react-router-dom'
import ThankYouPage from "./ThankYouApplicant"
import {Divider, Header} from "semantic-ui-react";
import ContactForm from "../HiringForm/index";
import {useSelector} from "react-redux";
import {sendCarreersAttachment} from "../../../services/sendEmail";
import emailjs from "emailjs-com";
import {getAttachedFile} from "../../../selectors/requestAChangeSelector";


export  const Main = React.memo(() =>{
    const { path} = useRouteMatch()
    const history = useHistory()
    const location = useLocation()
    const attachedFile = useSelector(getAttachedFile)

    const handleSubmit = async (data) => {
        if(data && data.validate[0]){
            await emailjs.send("service_1d1ggng", "template_vle0en1", {
                email: data.validate[0].apply_email ? data.validate[0].apply_email : '',
                firstName: data.validate[0].firstName ? data.validate[0].firstName : '',
                lastName: data.validate[0].lastName ? data.validate[0].lastName : '',
                licensed_to_sell: data.validate[0].licensed_to_sell ? data.validate[0].licensed_to_sell : '',
                licensed: data.validate[0].licensed ? data.validate[0].licensed : '',
                personal_contact: data.validate[0].personal_contact ? data.validate[0].personal_contact : '',
                position_of_interest: data.validate[0].position_of_interest ? data.validate[0].position_of_interest : ''
            }, 'user_iuz4PywP4uJvUMl4sLkyV');
        }
        attachedFile && sendCarreersAttachment(attachedFile,`${data.validate[0].firstName} ${data.validate[0].lastName}`)
        history.push(`${location.pathname}/thank-you`)
    }
    return (
        <Switch>
            <Route exact path={path}>
                <div className="form-bg">
                    <div className="container w-100">
                        <h1 className="text-center pt-5 pb-2">
                            We're Hiring!</h1>
                        <Header as='h3' textAlign='center'>
                            <Link to="">Insurance Agents </Link>
                            & <Link to="">Customer Service Respresentatives</Link>{" "}
                            in Los Angeles, California
                        </Header>
                        <Divider hidden/>
                        <Divider hidden/>
                        <Divider hidden/>
                        <ContactForm onSubmit={handleSubmit}/>
                        <div className="text-left mt-3">
                            <p>By clicking "Submit" I am providing my express written consent to
                                GM Insurance Services to contact me by phone, text message, and email at
                                the contact information provided in this form. Additionally, by clicking
                                "Send Message" I acknowledge that I have read, understood, and agree to
                                GM Insurance Service's <a href="/privacy-policy">Privacy
                                    Policy</a> and <a href="/privacy-policy">Terms of Use</a>.</p>
                        </div>
                    </div>
                </div>
            </Route>
            <Route path={`${path}/thank-you`}>
                <ThankYouPage/>
            </Route>
        </Switch>

    )
})

export default Main
import React from 'react'
import Layout from "../../layout";
import Hero from "../../components/Hero";
import {WebsiteAccessibility} from "./accessibility";



export const WebsiteAccessibilityPage = () => {
    const content = {
        'bg_img': 'staic_pages_landing ourmission-bg-image',
        'text': ' Website Accessibility',
        'green_text': '',
        'text2': '.',
        'description1': '',
        'link': '/website-accessibility',
        'NoNeedForm': true,
    }
    return (
        <Layout full>
            <Hero content={content}/>
            <WebsiteAccessibility />
        </Layout>
    );
}
import React from 'react'
import {makeStyles} from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {useLocation} from "react-router-dom";



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
}));

function getSteps() {
    return ['Company Information','Contact Information'];
}

export const CommercialSteps = React.memo(() => {
    const classes = useStyles();
    const steps = getSteps();
    const location = useLocation()

    const activeStep = () => {
        if (location.pathname === '/commercial-auto-insurance-form'){
            return 0
        }else {
            return 1
        }
    }

    return(
        <div className={classes.root}>
            <Stepper activeStep={activeStep()} alternativeLabel style={{backgroundColor:"#f3f7fc"}}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    )
})
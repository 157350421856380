import React from 'react'
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import {BLOCKS, INLINES} from '@contentful/rich-text-types'
import get from 'lodash.get';

const FIRST_PART = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return <p className="paragraph">{children}</p>
        },
        [BLOCKS.HEADING_3]: (node, children) => {
            return  <h3 className="p-heading">{children}</h3>
        },
        [BLOCKS.TABLE]: (node, children) => {
            return  <table className="table-contentful">{children}</table>
        },
        [BLOCKS.TABLE_ROW]: (node, children) => {
            return  <tr className="table-row-contentful">{children}</tr>
        },
        [INLINES.HYPERLINK]: (node, next) => {
            return <a href={node.data.uri} target='_blank' rel="noreferrer">{node.content[0].value}</a>;
          }
    }
}

export default function MotorcycleInsurance({fields}) {
    return (
        
        <div class="container-fluid generic_font_color">
              <div class="row justify-content-md-center">
                <div class="col col-lg-12">
                <h3 class="text-center my-5  page-heading">{get(fields,'title','')}</h3>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-12">
                 {fields.content && documentToReactComponents(fields.content, FIRST_PART)}
                </div>
            </div>         
        </div>
    );
}

import React, { useMemo } from "react";

import Layout from "../../layout";
import Hero from "../../components/Hero";
import MakePaymentOnline  from "../../components/StaticPages/MakePaymentOnline";
import {useContentFull} from '../../hooks/useContentFull'
import { PageLoader } from "../../components/PageLoader/PageLoader";
import { getImageFromContentFull } from "../../utils/contentful";
import get from "lodash.get";
export default function MakePaymentOnlinePage() {
    const content = {
        'bg_img': 'staic_pages_landing payment-online-bg-image',
        'text': 'Report an',
        'green_text': 'Accident',
        'text2': 'Online or by Phone',
        'description1': true,
        'link': '/auto-insurance-form',
        NoNeedForm: true,

    }
    const {fields, loading, pictureTitle} = useContentFull()
    const currentHeroImage = useMemo(() => {
        return getImageFromContentFull(fields.background)
    },[fields])

    if(loading || !currentHeroImage){
        return (
            <PageLoader />
        )
    }
    return (
        <Layout full>
            <title>{get(fields, 'pageTitle', '')}</title>
            <Hero pictureTitle={pictureTitle} description={get(fields, 'subtitle','')}  image={currentHeroImage} content={content}/>
            <MakePaymentOnline fields={fields} />
        </Layout>
    );
}
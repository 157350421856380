const validateDriver = values => {
    const errors = {};
    if (!values.clubName) {
        errors.clubName = 'Required';
    }
    if (!values.drivers || !values.drivers.length) {
        errors.drivers = { _error: 'At least one member must be entered' };
    } else {
        const driversArrayErrors = [];
        values.drivers.forEach((drivers, memberIndex) => {
            const driverErrors = {};
            if (!drivers || !drivers.firstName) {
                driverErrors.firstName = 'Required';
                driversArrayErrors[memberIndex] = driverErrors;
            }
            if (!drivers || !drivers.lastName) {
                driverErrors.lastName = 'Required';
                driversArrayErrors[memberIndex] = driverErrors;
            }
            if (!drivers || !drivers.homeAddress) {
                driverErrors.homeAddress = 'Required';
                driversArrayErrors[memberIndex] = driverErrors;
            }
            if (!drivers || !drivers.city) {
                driverErrors.city = 'Required';
                driversArrayErrors[memberIndex] = driverErrors;
            }
            if (!drivers || !drivers.contact) {
                driverErrors.contact = 'Required';
                driversArrayErrors[memberIndex] = driverErrors;
            }
            if (!drivers || !drivers.email) {
                driverErrors.email = 'Required';
                driversArrayErrors[memberIndex] = driverErrors;
            }
            if (!drivers || !drivers.date_of_birth) {
                driverErrors.date_of_birth = 'Required';
                driversArrayErrors[memberIndex] = driverErrors;
            }
            if (!drivers || !drivers.driver_license_number) {
                driverErrors.driver_license_number = 'Required';
                driversArrayErrors[memberIndex] = driverErrors;
            }
            if (!drivers || !drivers.moto_vin_make_model) {
                driverErrors.moto_vin_make_model = 'Required';
                driversArrayErrors[memberIndex] = driverErrors;
            }

        });
        if (driversArrayErrors.length) {
            errors.drivers = driversArrayErrors;
        }
    }
    return errors;
};

export default validateDriver;

import Layout from "../../layout";
import Hero from "../../components/Hero";
import DuiInsurance  from "../../components/StaticPages/DUI-Insurance";
import { useMemo } from "react";
import { getImageFromContentFull } from "../../utils/contentful";
import { useContentFull } from '../../hooks/useContentFull'
import { PageLoader } from "../../components/PageLoader/PageLoader";

export default function DUI_InsurancePage() {
    const content = {
        'bg_img': 'staic_pages_landing dui-isurance-bg-image',
        'text': 'DUI ',
        'green_text': 'Insurances',
        'text2': 'Quotes',
        link: "/dui-insurance-form",
    }
    const {fields, loading, pictureTitle} = useContentFull()
    const currentHeroImage = useMemo(() => {
        return getImageFromContentFull(fields.background)
    },[fields])

    if(loading || !currentHeroImage){
        return (
            <PageLoader />
        )
    }
    return (
        <Layout full>
            <Hero pictureTitle={pictureTitle} image={currentHeroImage} content={content}/>
            <DuiInsurance fields={fields} />
        </Layout>
    );
}
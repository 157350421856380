import React, { Suspense } from "react";
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'semantic-ui-css/semantic.min.css'
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import {Provider} from "react-redux";
import {store} from "./store";
import {initializeApp} from "firebase/app"
import { getAnalytics, logEvent } from "firebase/analytics";
Amplify.configure(awsExports);

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD_9aP1477MBR323sAvd5emy0WV2wl2XxE",
    authDomain: "gm-insurance.firebaseapp.com",
    projectId: "gm-insurance",
    storageBucket: "gm-insurance.appspot.com",
    messagingSenderId: "583306007128",
    appId: "1:583306007128:web:946dcd404d2f3649a8702d",
    measurementId: "G-P5C9TYJWLE"
};
// Initialize Firebase
initializeApp(firebaseConfig)

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <Suspense fallback={<div></div>}>
              <App />
          </Suspense>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

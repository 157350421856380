import React from "react";
import '../../assets/css/autoInsurance.css'
import HomeIcon from "../../static/images/icons/HomeOwnerInsuranceSmallIcon.png";
import CarIcon from "../../static/images/icons/AutoInsuranceQuoteSmallIcon.png";
import CommercialIcon from "../../static/images/icons/CommercialAutoInsuranceSmallIcon.png";
import MotorCycleIcon from "../../static/images/icons/MotorCycleInsurence.png";

import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import {BLOCKS, INLINES} from '@contentful/rich-text-types'
import get from 'lodash.get';

const FIRST_PART = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return <p className="paragraph">{children}</p>
        },
        [BLOCKS.HEADING_3]: (node, children) => {
            return  <h3 className="p-heading">{children}</h3>
        },
        [INLINES.HYPERLINK]: (node, next) => {
          return <a href={node.data.uri} target='_blank' rel="noreferrer">{node.content[0].value}</a>;
        }
    }
}

export default function OurMission({fields}) {
  const fixed_width = {
    maxWidth: "339px",
  };

  return (
    <div class="container-fluid generic_font_color">
        <title>{get(fields, 'pageTitle', '')}</title>
      <div class="row justify-content-md-center mt-5">
        <div class="col col-lg-12">
          <p className="paragraph text-center">
          {get(fields, 'title1', '')}
          </p>
          <p className="paragraph text-center">
          {get(fields, 'title2', '')}
          </p>
        </div>
      </div>

      <div className='iconsAboutUs'>
          <div className="col">
            <div className="flex-row">
              <div className="p-2">
                <img className='aboutUsImges' src={CarIcon} alt="" />
              </div>
              <div className="p-2">
                <h3 className="p-heading">Auto</h3>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="flex flex-row justify-content-end">
              <div className="p-2">
                <img className='aboutUsImges' src={HomeIcon} alt="" />
              </div>
              <div className="p-2">
                <div style={fixed_width}>
                  <h3 className="p-heading">Homeowners</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="col ">
            <div className="flex flex-row">
              <div className="p-2">
                <img className='aboutUsImges' src={MotorCycleIcon} alt="" />
              </div>
              <div className="p-2">
                <div style={fixed_width}>
                  <h3 className="p-heading">Motorcycle</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="flex flex-row justify-content-end">
              <div className="p-2">
                <img className='aboutUsImges' src={CommercialIcon} alt="" />
              </div>
              <div className="p-2">
                <div style={fixed_width}>
                  <h3 className="p-heading">Commercial Auto</h3>
                </div>
              </div>
            </div>
          </div>
      </div>


      <div class="row mb-5">
        <div class="col col-lg-12">
        {fields.content && documentToReactComponents(fields.content, FIRST_PART)}          
      </div>
      </div>
    </div>
  );
}

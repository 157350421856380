export const states = [
    {key: '1', text: 'Alabama', value: 'AL'},
    {key: '2', text: 'Alaska', value: 'AK'},
    {key: '3', text: 'Arizona', value: 'AZ'},
    {key: '4', text: 'Arkansas', value: 'AR'},
    {key: '5', text: 'California', value: 'CA'},
    {key: '6', text: 'Colorado', value: 'CO'},
    {key: '7', text: 'Connecticut', value: 'CT'},
    {key: '8', text: 'Delaware', value: 'DE'},
    {key: '9', text: 'District Of Columbia', value: 'DC'},
    {key: '10', text: 'Florida', value: 'FL'},
    {key: '11', text: 'Georgia', value: 'GA'},
    {key: '12', text: 'Hawaii', value: 'HI'},
    {key: '13', text: 'Idaho', value: 'ID'},
    {key: '14', text: 'Illinois', value: 'IL'},
    {key: '15', text: 'Indiana', value: 'IN'},
    {key: '16', text: 'Iowa', value: 'IA'},
    {key: '17', text: 'Kansas', value: 'KS'},
    {key: '18', text: 'Kentucky', value: 'KY'},
    {key: '19', text: 'Louisiana', value: 'LA'},
    {key: '20', text: 'Maine', value: 'ME'},
    {key: '21', text: 'Maryland', value: 'MD'},
    {key: '22', text: 'Massachusetts', value: 'MA'},
    {key: '23', text: 'Michigan', value: 'MI'},
    {key: '24', text: 'Minnesota', value: 'MN'},
    {key: '25', text: 'Mississippi', value: 'MS'},
    {key: '26', text: 'Missouri', value: 'MO'},
    {key: '27', text: 'Montana', value: 'MT'},
    {key: '28', text: 'Nebraska', value: 'NE'},
    {key: '29', text: 'Nevada', value: 'NV'},
    {key: '30', text: 'New Hampshire', value: 'NH'},
    {key: '31', text: 'New Jersey', value: 'NJ'},
    {key: '32', text: 'New Mexico', value: 'NM'},
    {key: '33', text: 'New York', value: 'NY'},
    {key: '34', text: 'North Carolina', value: 'NC'},
    {key: '35', text: 'North Dakota', value: 'ND'},
    {key: '36', text: 'Ohio', value: 'OH'},
    {key: '37', text: 'Oklahoma', value: 'OK'},
    {key: '38', text: 'Oregon', value: 'OR'},
    {key: '39', text: 'Pennsylvania', value: 'PA'},
    {key: '40', text: 'Rhode Island', value: 'RI'},
    {key: '41', text: 'South Carolina', value: 'SC'},
    {key: '42', text: 'South Dakota', value: 'SD'},
    {key: '43', text: 'Tennessee', value: 'TN'},
    {key: '44', text: 'Texas', value: 'TX'},
    {key: '45', text: 'Utah', value: 'UT'},
    {key: '46', text: 'Vermont', value: 'VT'},
    {key: '47', text: 'Virginia', value: 'VA'},
    {key: '48', text: 'Washington', value: 'WA'},
    {key: '49', text: 'West Virginia', value: 'WV'},
    {key: '50', text: 'Wisconsin', value: 'WI'},
    {key: '51', text: 'Wyoming', value: 'WY'},
    {key: '52', text: 'International', value: 'International'},
];

export const gender_options = [
    {key: '1', text: 'Male', value: 'male'},
    {key: '2', text: 'Female', value: 'female'},
    {key: '3', text: 'Other', value: 'other'},
];
export const relation_to_insured = [
    {key: '1', text: 'Spouse', value: 'Spouse'},
    {key: '2', text: 'Child', value: 'Child'},
    {key: '3', text: 'Sibling', value: 'Sibling'},
    {key: '3', text: 'Parent', value: 'Parent'},
    {key: '3', text: 'Other', value: 'Other'},
]
export const marital_status = [
    {key: '1', text: 'Single', value: 'single'},
    {key: '2', text: 'Married', value: 'married'},
    {key: '3', text: 'Separated', value: 'seperatted'},
    {key: '4', text: 'Divorced', value: 'divorced'},
    {key: '5', text: 'Widowed', value: 'widowed'},
];

export const liability_limit = [
    {key: '1', text: 'California Minimum 15,000/30,000/5,000', value: 'CAmin'},
    {key: '2', text: 'Basic 25,000/50,000/25,000', value: 'basic'},
    {key: '3', text: 'Standard 100,000/300,000/50,000', value: 'standard'},
    {key: '4', text: 'Superior 250,000/500,000/100,000', value: 'superior'},
];

export const personal_property = [
    {key: '1', text: '$25,000', value: '25000'},
    {key: '2', text: '$50,000', value: '50000'},
    {key: '3', text: '$75,000', value: '75000'},
    {key: '4', text: '$100,000', value: '100000'},
]

export const renter_liability_limit = [
    {key: '1', text: '$100,000', value: '100000'},
    {key: '2', text: '$300,000', value: '300000'},
    {key: '3', text: '$500,000', value: '500000'},
]


export const property_constraction = [
    {key: '1', text: 'Frame', value: 'frame'},
    {key: '2', text: 'Joisted Masonry', value: 'joistedMasonry'},
    {key: '3', text: 'Non-Compustible', value: 'nonCombustible'},
    {key: '4', text: 'Masonry Non-Compustible', value: 'masonryNonCombustible'},
    {key: '5', text: 'Modified Fire Resistive', value: 'modifiedFireResistive'},
    {key: '6', text: 'Fire Resistive', value: 'fireResistive'},
]
//TODO: complete garage types
export const garage_type = [
    {key: '1', text: 'Attached Garage', value: 'attachedGarage'},
    {key: '1', text: 'Detached Garage', value: 'attachedGarage'},
    {key: '1', text: 'No Garage', value: 'noGarage'}
]
export const comprehensive_deductible = [
    {key: '1', text: 'Reject', value: 'attachedGarage'},
    {key: '1', text: '$500', value: 'attachedGarage'},
    {key: '1', text: '$1000', value: 'noGarage'}
]
//TODO: check if this is correct
export const roof_type = [
    {key: '1', text: 'Asphalt Shingles', value: 'asphaltShingles'},
    {key: '2', text: 'Built-up Roofing', value: 'builtUpRoofing'},
    {key: '3', text: 'Clay and Concrete Tiles ', value: 'clayConcreteTiles'},
    {key: '4', text: 'Green Roofs', value: 'greenRoofs'},
    {key: '5', text: 'Metal Roofing', value: 'metalRoofing'},
    {key: '6', text: 'Rubber Slate', value: 'rubberSlate'},
    {key: '7', text: 'Solar Tiles', value: 'solarTiles'},
    {key: '8', text: 'Stone-Coated Steel', value: 'stoneCoatedSteel'},
    {key: '9', text: 'Slate', value: 'slate'},
]
export const property_type = [
    {key: '1', text: 'House', value: 'House'},
    {key: '1', text: 'Condo', value: 'Condo'},
    {key: '1', text: 'Apartment', value: 'Apartment'},
    {key: '1', text: 'Duplex', value: 'Duplex'},
    {key: '1', text: 'Townhome', value: 'Townhome'},
    {key: '1', text: 'Mobile Home', value: 'Mobile Home'},
]
export const living_in_house = [
    {key: '1', text: 'Owner occupied', value: 'Owner occupied'},
    {key: '2', text: 'A Renter', value: 'A Renter'},
    {key: '3', text: 'Vacant, or will be renovated', value: 'Vacant, or will be renovated'},
]
export const quality_grade = [
    {key: '1', text: 'Standard – Just the basics', value: 'Standard – Just the basics'},
    {key: '2', text: 'Above Average – A few extras', value: 'Above Average – A few extras'},
    {key: '3', text: 'Custom – Top of the line', value: 'Custom – Top of the line'},
]
//TODO: check if this is correct
export const story = [
    {key: '1', text: 'One Story', value: '1'},
    {key: '2', text: 'Two Stories', value: '2'},
    {key: '3', text: 'Tree Stories', value: '3'},
]
//TODO: check if this is correct
export const roof_age = [
    {key: '1', text: '1 Year', value: '1'},
    {key: '2', text: '5 Years', value: '5'},
    {key: '3', text: '10 Years', value: '10'},
    {key: '4', text: '15 Years', value: '15'},
    {key: '5', text: '20 Years', value: '20'},
    {key: '6', text: '25 Years', value: '25'},
    {key: '7', text: '30 Years', value: '30'},
    {key: '8', text: '50 Years', value: '50'},
    {key: '9', text: 'More Then 50 Years', value: '50andMore'},
]
//TODO: check if alarm types are correct
export const security_system = [
    {key: '1', text: 'None', value: 'none'},
    {key: '2', text: 'Motion Sensor Alarm', value: 'motionSensorAlarm'},
    {key: '3', text: 'Fire Alarm System', value: 'fireAlarm'},
    {key: '4', text: 'Water Alarm', value: 'waterAlarm'},
    {key: '5', text: 'Door Alarm', value: 'doorAlarm'},
    {key: '6', text: 'Heat Alarm', value: 'heatAlarm'},
    {key: '7', text: 'Contact Sensor', value: 'contactAlarm'},
    {key: '8', text: 'Intrusion Alarm', value: 'intrusionAlarm'},
    {key: '9', text: 'Glass Break Sensor', value: 'glassBreakAlarm'},
]

export const company_type = [
    {key: '1', text: 'Sole Proprieters', value: 'soleProperty'},
    {key: '2', text: 'Corporation', value: 'corporation'},
    {key: '3', text: 'LLC', value: 'llc'},
    {key: '4', text: 'Partnership', value: 'partnership'},
]

export const yeras_in_buisness = [
    {key: '1', text: 'New Buisness', value: '0'},
    {key: '2', text: '1 Year', value: '1'},
    {key: '3', text: '2 Year', value: '2'},
    {key: '4', text: '3 Year', value: '3'},
    {key: '5', text: '4 Year', value: '4'},
    {key: '6', text: '5 Year', value: '5'},
    {key: '7', text: '6 Year', value: '6'},
    {key: '8', text: '7 Year', value: '7'},
    {key: '9', text: '8 Year', value: '8'},
    {key: '10', text: '9 Year', value: '9'},
    {key: '11', text: '10 Year', value: '10'},
    {key: '12', text: 'More Then 10 Year', value: '11'},
]
export const vehicleNUmbers= [
    {key: '1', text: 1},
    {key: '2', text: 2},
    {key: '3', text: 3},
    {key: '4', text: 4},
    {key: '5', text: 5},
    {key: '6', text: 6},
    {key: '7', text: 7},
    {key: '8', text: 8},
    {key: '9', text: 9},
    {key: '10', text: 10},
    {key: '11', text: 11},
    {key: '12', text: 12},
    {key: '13', text: 13},
    {key: '14', text: 14},
    {key: '15', text: 15},
    {key: '16', text: 16},
    {key: '17', text: 17},
    {key: '18', text: 18},
    {key: '19', text: 19},
    {key: '20', text: 20},
]

export const position_of_interest = [
    {key: '1', text: 'Costumer Service', value: 'costumerService'},
    {key: '2', text: 'Sels Insurance Agent/Broker', value: 'broker'},
    {key: '3', text: 'Management', value: 'managment'},
    {key: '4', text: 'Marketing', value: 'marketing'},
    {key: '4', text: 'Underwriting', value: 'underwriting'},
]

export const number_of_vehicles = [
    {key: '1', text: '1', value: '1'},
    {key: '2', text: '2', value: '2'},
    {key: '3', text: '3', value: '3'},
    {key: '4', text: '4', value: '4'},
    {key: '5', text: '5', value: '5'},
    {key: '6', text: '6', value: '6'},
    {key: '7', text: '7', value: '7'},
    {key: '8', text: '8', value: '8'},
    {key: '9', text: '9', value: '9'},
    {key: '10', text: '10', value: '10'},
    {key: '11', text: '11', value: '11'},
    {key: '12', text: '12', value: '12'},
    {key: '13', text: '13', value: '13'},
    {key: '14', text: '14', value: '14'},
    {key: '15', text: '15', value: '15'},
    {key: '16', text: '16', value: '16'},
    {key: '17', text: '17', value: '17'},
    {key: '18', text: '18', value: '18'},
    {key: '19', text: '19', value: '19'},
    {key: '20', text: '20', value: '20'},
    {key: '21', text: '21', value: '21'},
    {key: '22', text: '22', value: '22'},
    {key: '23', text: '23', value: '23'},
    {key: '24', text: '24', value: '24'},
    {key: '25', text: '25', value: '25'},
    {key: '26', text: '26', value: '26'},
    {key: '27', text: '27', value: '27'},
    {key: '28', text: '28', value: '28'},
    {key: '29', text: '29', value: '29'},
    {key: '30', text: '30', value: '30'},
    {key: '31', text: 'More then 30', value: 'more_then_30'},
]
export const vehicle_usage = [
    {key: '1', text: 'Pleasure', value: 'attachedGarage'},
    {key: '1', text: 'Commute to Work/School', value: 'attachedGarage'},
    {key: '1', text: 'Business', value: 'noGarage'}
]

export default {
    relation_to_insured,
    states,
    gender_options,
    marital_status,
    liability_limit,
    personal_property,
    renter_liability_limit,
    property_type,
    property_constraction,
    garage_type,
    roof_type,
    story,
    roof_age,
    vehicleNUmbers,
    security_system,
    company_type,
    yeras_in_buisness,
    position_of_interest,
    number_of_vehicles
}

import React, {useEffect, useState} from 'react';
import {Field, FieldArray, reduxForm} from 'redux-form';
import './../../../../assets/css/autoInsurance.css'
import Button from "@material-ui/core/Button";
import passValidation from "../../../utils/validate";
import {
    renderDateToday,
    renderField,
    renderFieldNotRequire, renderFieldPhoneNumber,
    renderFieldSelect
} from "../../../utils/driver-redux-form";
import {states} from "../../../../static/select-lists/forms-options";
import {RequestChangeDisclaimor} from "../../RequestChnageDisclaimor";

const renderMembers = React.memo(({fields, meta: {touched, error, submitFailed},mailing,discError,setMailing,disc,setDisc}) => {
    return (
        <div>
            {fields.map((validate, index) => (
                <ul key={index}>
                    <li  className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.garaging_address`}
                            type="text"
                            component={renderField}
                            label="Garaging Address"
                        />
                        <Field
                            name={`${validate}.apt_suit`}
                            type="text"
                            component={renderField}
                            label="Apt/Suite"
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.city`}
                            type="text"
                            component={renderField}
                            label="City"
                        />
                        <Field
                            name={`${validate}.state`}
                            component={renderFieldSelect}
                            label="State"
                            options={states}
                        />

                    </li>
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.zipCode`}
                            type="tel"
                            min={5}
                            max={5}
                            component={renderFieldPhoneNumber}
                            label="Zip Code"
                        />
                        <Field
                            name={`${validate}.request_effective_date`}
                            type="date"
                            component={renderDateToday}
                            label="Requested Effective Date of Change"
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <div className='haveViolation'>
                            <span className='violation'>I have a different Mailing Address</span>
                            <label style={{marginRight:'10px',marginLeft:'10px'}}>
                                <Field name={`${validate}.is_mailing_address`} checked={!mailing} onChange={() => setMailing(false) } component="input" type="radio" value="Yes" />{' '}
                                Yes
                            </label>
                            <label style={{marginRight:'10px'}}>
                                <Field onChange={() => setMailing(true)}  name={`${validate}.is_mailing_address`} checked={mailing} component="input" type="radio" value="No" />{' '}
                                No
                            </label>
                        </div>
                    </li>
                    {!mailing &&
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.mailing_address`}
                            type="text"
                            component={renderFieldNotRequire}
                            label="Mailing Address"
                        />
                        <Field
                            name={`${validate}.mailing_apt_suit`}
                            type="text"
                            component={renderFieldNotRequire}
                            label="Apt/Suite"
                        />
                    </li>}
                    {!mailing &&
                        <li className='reduxFromLiMoto'>
                        <Field
                        name={`${validate}.mailing_city`}
                        type="text"
                        component={renderFieldNotRequire}
                        label="City"
                        />
                        <Field
                        name={`${validate}.mailing_state`}
                        component={renderFieldSelect}
                        label="State"
                        options={states}
                        />
                            <Field
                                name={`${validate}.mailing_zipCode`}
                                type="tel"
                                min={5}
                                max={5}
                                component={renderFieldPhoneNumber}
                                label="Zip Code"
                            />
                        </li>
                    }
                </ul>
            ))}
            <RequestChangeDisclaimor disc={disc} setDisc={setDisc}/>
            {discError && <p style={{color:'red'}}>Please read and check online policy change request Disclaimer</p>}
            <div className={'addDriver'}>
                <button style={{display: 'none'}} className='addButton' id='defaultValues' type="button"
                        onClick={() => fields.push({})}/>
            </div>
        </div>
    )
})

const FieldArraysForm = props => {
    useEffect(() => {
        const defaultClick = document.getElementById('defaultValues')
        defaultClick.click()
    }, [])
    const [mailing, setMailing] = useState(true)
    const {handleSubmit} = props;
    return (
        <form onSubmit={handleSubmit} style={{marginTop: '25px'}}>
            <FieldArray name="validate" discError={props.discError} disc={props.disc} setDisc={props.setDisc} setMailing={setMailing} mailing={mailing}  component={renderMembers}/>
            <div className='request'>
                <Button type='submit'  style={{marginTop: '15px'}} variant="contained"
                        color="primary">
                    Submit Request
                </Button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'fieldArrays',
    validate: passValidation,
})(FieldArraysForm);

import React, {useEffect} from 'react';
import {Field, FieldArray, reduxForm} from 'redux-form';
import '../../../../src/assets/css/autoInsurance.css'
import Button from "@material-ui/core/Button";
import {
    renderField, renderFieldPhoneNumber,
} from "../../utils/driver-redux-form";
import passValidation from "../../utils/validate";


const renderMembers = ({fields, meta: {touched, error, submitFailed}}) => {
    return (
        <div>
            {fields.map((validate, index) => (
                <ul key={index}>
                    <li  className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.firstName`}
                            type="text"
                            component={renderField}
                            label="First Name"
                        />
                        <Field
                            name={`${validate}.lastName`}
                            type="text"
                            component={renderField}
                            label="Last Name"
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.phone_number`}
                            type="tel"
                            min={10}
                            max={11}
                            component={renderFieldPhoneNumber}
                            label="Phone Number"
                        />
                        <Field
                            name={`${validate}.apply_email`}
                            type="email"
                            component={renderField}
                            label="Email Address"
                        />
                    </li>
                    <li  className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.policy_number`}
                            type="text"
                            component={renderField}
                            label="Policy Number"
                        />
                    </li>
                </ul>
            ))}
            <div className={'addDriver'}>
                <button style={{display: 'none'}} className='addButton' id='defaultValues' type="button"
                        onClick={() => fields.push({})}/>
            </div>
        </div>
    )
}

const FieldArraysForm = props => {
    useEffect(() => {
        const defaultClick = document.getElementById('defaultValues')
        defaultClick.click()
    }, [])

    const {handleSubmit, submitting} = props;
    return (
        <form onSubmit={handleSubmit} style={{marginTop: '25px'}}>
            <FieldArray name="validate" location={props} component={renderMembers}/>
            <div className='request'>
                <Button type='submit' disabled={submitting} style={{marginTop: '15px'}} variant="contained"
                        color="primary">
                    Next
                </Button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'fieldArrays',
    validate: passValidation,
})(FieldArraysForm);

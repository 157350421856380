import {Field, FieldArray, reduxForm} from "redux-form";
import {
    renderDate, renderDateToday,
    renderField, renderFieldNotRequire,
    renderFieldSelect, renderFieldSelectRequire, renderPatternDateInput
} from "../../../utils/driver-redux-form";
import {gender_options, states} from "../../../../static/select-lists/forms-options";
import {RequestChangeDisclaimor} from "../../RequestChnageDisclaimor";
import React, {useEffect} from "react";
import Button from "@material-ui/core/Button";
import passValidation from "../../../utils/validate";
import {marital_status, relation_to_insured} from "../../../../static/select-options/driver-select-options";

const renderMembers = React.memo(({fields, meta: {touched, error, submitFailed},discError,disc,setDisc}) => {
    return (
        <div>
            {fields.map((validate, index) => (
                <ul key={index}>
                    <li  className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.firstName`}
                            type="text"
                            component={renderField}
                            label="First Name"
                        />
                        <Field
                            name={`${validate}.lastName`}
                            type="text"
                            component={renderField}
                            label="Last Name"
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.gender`}
                            component={renderFieldSelect}
                            label="Gender"
                            options={gender_options}
                        />
                        <Field
                            name={`${validate}.date_of_birth`}
                            type="text"
                            placeholder={'MM/DD/YYYY'}
                            component={renderPatternDateInput}
                            label="Date of Birth"
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.driver_license_number`}
                            type="text"
                            component={renderField}
                            label="Drivers’ License Number"
                        />
                        <Field
                            name={`${validate}.state`}
                            component={renderFieldSelectRequire}
                            label="State Licensed"
                            options={states}
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.relationship_to_insured`}
                            component={renderFieldSelect}
                            label="Relationship to Insured "
                            options={relation_to_insured}
                        />
                        <Field
                            name={`${validate}.marital_status`}
                            component={renderFieldSelect}
                            label="Marital Status"
                            options={marital_status}
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.message_to_GM`}
                            type="text"
                            component={renderFieldNotRequire}
                            label="Message to GM Insurance Services"
                        />
                        <Field
                            name={`${validate}.request_effective_date`}
                            type="date"
                            component={renderDateToday}
                            label="Requested Effective Date of Change"
                        />
                    </li>
                </ul>
            ))}
            <RequestChangeDisclaimor disc={disc} setDisc={setDisc}/>
            {discError && <p style={{color:'red'}}>Please read and check online policy change request Disclaimer</p>}
            <div className={'addDriver'}>
                <button style={{display: 'none'}} className='addButton' id='defaultValues' type="button"
                        onClick={() => fields.push({})}/>
            </div>
        </div>
    )
})

const FieldArraysForm = props => {
    useEffect(() => {
        const defaultClick = document.getElementById('defaultValues')
        defaultClick.click()
    }, [])
    const {handleSubmit, submitting} = props;
    return (
        <form onSubmit={handleSubmit} style={{marginTop: '25px'}}>
            <FieldArray name="validate" discError={props.discError} disc={props.disc} setDisc={props.setDisc}   component={renderMembers}/>
            <div className='request'>
                <Button type='submit'  style={{marginTop: '15px'}} variant="contained"
                        color="primary">
                    Submit Request
                </Button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'fieldArrays',
    validate: passValidation,
})(FieldArraysForm);
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {getAutoChange, getAutoCurrentChange} from "../../../../selectors/requestAChangeSelector";
import {setAutoChange} from "../../../../slices/requestAChnage";
import { sendEmailChangeRequest} from "../../../../services/sendEmail";
import Layout from "../../../../layout";
import ContactForm from "./driverChnageForm";
import emailjs from "emailjs-com";


export const DriverRequestCurrentForm = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const main = useSelector(getAutoChange)
    const [disc, setDisc] = useState(false)
    const [discError, setDiscError] = useState(false)
    const [isFormSubmited, setIsFormSubmited] = useState(false)
    const currentChangeRequest = useSelector(getAutoCurrentChange)

    const handleSubmit = (values) => {
        if (disc){
            dispatch(setAutoChange({
                name: currentChangeRequest,
                value:values.validate[0]
            }))
            setIsFormSubmited(true)
        }else{
            setDiscError(true)
        }
    }

    useEffect(async() => {
        if (main){
            await emailjs.send("service_1d1ggng", "template_56ijs8r", {
                subject: 'Add Driver',
                firstName: main.MainInfo.firstName,
                lastName: main.MainInfo.lastName,
                phone: main.MainInfo.phone_number,
                policy: main.MainInfo.policy_number,
                email: main.MainInfo.apply_email,
                firstDriverName: main['Add Driver'].firstName ? main['Add Driver'].firstName : '',
                lastDriverName: main['Add Driver'].lastName ? main['Add Driver'].lastName : '',
                gender: main['Add Driver'].gender ? main['Add Driver'].gender : '',
                date_of_birth: main['Add Driver'].date_of_birth ? main['Add Driver'].date_of_birth : '',
                driver_license_number: main['Add Driver'].driver_license_number ? main['Add Driver'].driver_license_number : '',
                state: main['Add Driver'].state ? main['Add Driver'].state : '',
                relationship_to_insured: main['Add Driver'].relationship_to_insured ? main['Add Driver'].relationship_to_insured : '',
                marital_status: main['Add Driver'].marital_status ? main['Add Driver'].marital_status : '',
                message_to_GM: main['Add Driver'].message_to_GM ? main['Add Driver'].message_to_GM : '',
                request_effective_date: main['Add Driver'].request_effective_date ? main['Add Driver'].request_effective_date : '',
            }, 'user_iuz4PywP4uJvUMl4sLkyV');
        }
        isFormSubmited && history.push(`/request-change-thank-you`)
    },[isFormSubmited])


    return (
        <Layout full>
            <div className="form-bg">
                <div className="container w-100">
                    <h1 className="text-center pt-5 pb-2">{currentChangeRequest}</h1>
                    <ContactForm discError={discError} disc={disc} setDisc={setDisc} onSubmit={handleSubmit}/>
                </div>
            </div>
        </Layout>

    )
}
import React, {useEffect, useState} from "react";
import { Switch, Route } from "react-router";
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom'
import ThankYouPage from "./../../ThankYouPage"
import ContactForm from "./RenterForm";
import {useDispatch, useSelector} from "react-redux";
import {setRenterFinalData} from "../../../slices/renterInsuranceSlice";
import {getFinalRenterData} from "../../../selectors/renterInsuranceSelector";
import {PrivacyInformation} from "../../PrivicyPolicyUnderForm";
import emailjs from "emailjs-com";
import { getAnalytics, logEvent } from "firebase/analytics";
import {sendRentersInsurance} from "../../../services/sendEmail";

export  function Main(){
    const location = useLocation()
    const history = useHistory()
    const dispatch = useDispatch()
    const currentLocation = location.pathname
    const { path} = useRouteMatch()
    const finalData = useSelector(getFinalRenterData)
    const [renterMail,setRenterEmail] = useState(false)

    const handleSubmit = (values) => {
        dispatch(setRenterFinalData({Renter_Insurance_Form:values.validate[0]}))
        setRenterEmail(true)
    }
    useEffect(async () => {
        if (renterMail && finalData){
            const params = {
                subject: 'Renter Insurance',
                firstName: finalData.Renter_Insurance_Form.firstName ? finalData.Renter_Insurance_Form.firstName : '',
                lastName: finalData.Renter_Insurance_Form.lastName ? finalData.Renter_Insurance_Form.lastName : '',
                earthquake_coverage: finalData.Renter_Insurance_Form.earthquake_coverage ? finalData.Renter_Insurance_Form.earthquake_coverage : '',
                liability_limit: finalData.Renter_Insurance_Form.liability_limit ? finalData.Renter_Insurance_Form.liability_limit : '',
                personal_contact: finalData.Renter_Insurance_Form.personal_contact ? finalData.Renter_Insurance_Form.personal_contact : '',
                personal_property: finalData.Renter_Insurance_Form.personal_property ? finalData.Renter_Insurance_Form.personal_property : '',
                property_address: finalData.Renter_Insurance_Form.property_address ? finalData.Renter_Insurance_Form.property_address : '',
                email_address: finalData.Renter_Insurance_Form.renter_email ? finalData.Renter_Insurance_Form.renter_email : '',
                state: finalData.Renter_Insurance_Form.state ? finalData.Renter_Insurance_Form.state : '',
                zipCode: finalData.Renter_Insurance_Form.zipCode ? finalData.Renter_Insurance_Form.zipCode : '',
            }
            await sendRentersInsurance(params)
            history.push(`${location.pathname}/thank-you`)

        }
    },[renterMail])
    return (
        <Switch>
            <Route exact path={path}>
                <div className="form-bg">
                    <div className="container w-100">
                        <h1 className="text-center pt-5 pb-2">Renter Insurance</h1>
                        <ContactForm currentLocation={currentLocation} onSubmit={handleSubmit}/>
                        <PrivacyInformation/>
                    </div>
                </div>
            </Route>
            <Route path={`${path}/thank-you`}>
                <ThankYouPage/>
            </Route>
        </Switch>
    )
}

export default Main

import Layout from "../../layout";
import Hero from "../../components/Hero";
import OurMission from "../../components/StaticPages/OurMission";
import { useContentFull } from "../../hooks/useContentFull";
import { useMemo } from "react";
import { getImageFromContentFull } from "../../utils/contentful";
import get from "lodash.get";
import { PageLoader } from "../../components/PageLoader/PageLoader";
export default function OurMissionPage() {
  const content = {
    'bg_img': 'staic_pages_landing ourmission-bg-image',
    'text': 'Welcome to ',
    'green_text': 'GM Insurance Services',
    'description1': true,
      isSelect: true
}
  const {fields, loading, pictureTitle} = useContentFull()
  const currentHeroImage = useMemo(() => {
      return getImageFromContentFull(fields.background)
  },[fields])
  const description = useMemo(() => {
    return get(fields, 'subtitle', '')
  },[fields])

if(loading || !currentHeroImage){
    return (
        <PageLoader />
    )
}
return (
    <Layout full>
        <Hero pictureTitle={pictureTitle} image={currentHeroImage} description={description} content={content}/>
      <OurMission fields={fields} />
    </Layout>
  );
}

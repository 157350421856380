import LocIcon from "../../static/images/fonts/loc-icon.png";
import {Link, useHistory, useLocation} from "react-router-dom";
import { useState, useMemo} from "react";
import '../../assets/css/autoInsurance.css'
function Hero(props) {
    const [zipCode, setZipCode] = useState()
    const history = useHistory()
    const [border,setBorder] = useState(false)
    const location = useLocation()
    const [currentInsurance, setCurrentInsurance] = useState('')
    const bkg = useMemo(() => {
        if(!!props.image){
            return {backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
            url(${props.image})`}
        }
        return null
    },[props.image])
    const handleZipCodeInput = (event) => {
        setZipCode(event.target.value)
    }
    const currentLocation = () => {
        if (location.pathname === '/auto-insurance-quote'){
            return '/auto-insurance-form'
        }else if(location.pathname === '/commercial-auto-insurance'){
            return '/commercial-auto-insurance-form'
        }else if(location.pathname === '/dui-Insurance'){
            return '/dui-Insurance-form'
        }else if(location.pathname === '/home-owner-insurance'){
            return '/home-owner-insurance-form'
        }else if(location.pathname === '/motorcycle-insurance'){
            return '/motorcycle-insurance-form'
        }else if(location.pathname === '/non-owner-insurance'){
            return '/non-owner-insurance-form'
        }else if(location.pathname === '/apartment-renter-insurance'){
            return '/renters-insurance-form'
        }else if(location.pathname === '/sr-22-Insurance'){
            return '/sr22-insurance-form'
        }else if(location.pathname === '/customer-reviews'){
            return '/auto-insurance-form'
        }else if(location.pathname === '/career'){
            return '/auto-insurance-form'
        }else if(location.pathname === '/about-us'){
            return !!currentInsurance && currentInsurance
        }else if(location.pathname === '/make-payment-online'){
            return '/auto-insurance-form'
        }else if(location.pathname === '/claims'){
            return '/auto-insurance-form'
        }else if(location.pathname === '/autoinsurancenorthhollywood'){
            return '/auto-insurance-form'
        }
    }
    const setInsurance = (e) => {
        setCurrentInsurance(e.target.value)
    }
    const goToInsurance = () => {
        if (zipCode){
            history.push(currentLocation())
        }else{
            setBorder(true)
        }
    }
    const handleEnterClick = (event) => {
        if (event.key === 'Enter') {
            goToInsurance();
        }
    }
    return (
        <div className={props.content.bg_img} style={bkg}>
            <div className="container" style={{ paddingLeft: '24px', paddingRight: '24px' }}>
                <div className="row mx-0">
                    <div className="col-lg-12">
                        <div className="main-headings-sec p-0">
                            <h1 style={{fontSize: props.content.font && props.content.font}} className="landing-heading mt-5">
                                {props.pictureTitle && Object.keys(props.pictureTitle).length > 0 ? props.pictureTitle.beferoGreenText : props.content.text}{' '}
                                 <strong className="color-head">
                                     {props.pictureTitle && Object.keys(props.pictureTitle).length > 0 ? props.pictureTitle.greenContent : props.content.green_text}{' '}
                                     </strong>
                                     {props.pictureTitle && Object.keys(props.pictureTitle).length > 0 ? props.pictureTitle.afterGreenText : props.content.text2}
                                    </h1>
                            {/* <br /> */}
                            {
                                props.content.description1 &&
                                <p className="landing-para pt-2 description1 px-3" style={{color: props.content.color && props.content.color}}>    {props.description ? props.description : props.content.description1}   </p>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                !props.content.NoNeedForm &&
                <div className="container landing-form mt-2" style={{ paddingLeft: '24px', paddingRight: '24px' }}>
                    <div className="row mx-0" style={{ marginLeft: '0px' }}>
                        <div className="col-lg-12 px-0">
                            <div className="d-flex flex-column flex-md-row flex-xl-row flex-sm-row flex-lg-row justify-content-center">
                                {
                                    props.content.isSelect &&
                                    <div className="mb-3 mr-lg-2 mr-md-2 mr-sm-2">
                                        <div className="input-icon ml-0">
                                            <svg width="19" id="select-icon" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.0085 0.0166016L9.13303 6.83014L2.25757 0.0166016L0.145508 2.11422L9.13303 11.0403L18.1205 2.11422L16.0085 0.0166016Z" fill="#90CF36" />
                                            </svg>
                                            <select className="main-select" onChange={setInsurance}>
                                                <option value="" disabled hidden>Select Insurance</option>
                                                <option value="/auto-insurance-form">Auto Insurance</option>
                                                <option value="/commercial-auto-insurance-form">Commercial Insurance</option>
                                                <option value="/dui-Insurance-form">DUI Insurance</option>
                                                <option value="/home-owner-insurance-form">Homeowner Insurance</option>
                                                <option value="/motorcycle-insurance-form">Motorcycle Insurance</option>
                                                <option value="/non-owner-insurance-form">Non Owner Insurance</option>
                                                <option value="/renters-insurance-form">Renter Insurance</option>
                                                <option value="/sr22-insurance-form">SR 22 Insurance</option>
                                            </select>
                                        </div>
                                    </div>
                                }
                                <div className="mb-3 mr-lg-2 mr-md-2 mr-sm-2 main-input-div">
                                    <div className="input-icon ml-0">
                                        <img id="loc-icon" src={LocIcon} alt="Location Icon" />
                                        <input className="main-input text-center"
                                               label="Enter Zip Code"
                                               style={{border:`${border ? '1px solid red' : '' }`}}
                                               placeholder="Enter Zip Code"
                                               type="number"
                                               onKeyPress={handleEnterClick}
                                               onChange={handleZipCodeInput}/>
                                    </div>
                                </div>
                                <div className="mb-5">

                                        <button
                                            onClick={goToInsurance}
                                            type="button"
                                            className="btn btn-raised btn-info btn-gm-green static_pages_btn btn-block" >
                                            Get a Free Quote
                                        </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                props.content.applyOnlineBtn &&
                <div className="container text-center">
                    <Link to="/apply" className="text-decoration-none">
                        <button  className="btn btn-outline-success btn-lg text-white apllyBTN">APPLY ONLINE</button>
                    </Link>
                </div>
            }
            {
                props.content.description2 &&
                <div className="container">
                    <p className="landing-para mb-5" style={{color: props.content.paraColor && props.content.paraColor}}>    {!!props.subtitle ? props.subtitle : props.content.description2}   </p>
                </div>
            }

        </div>
    );
}

export default Hero;

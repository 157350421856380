import React, {useEffect, useState} from "react";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import {useRouteMatch} from 'react-router-dom'
import ThankYouPage from "./../../ThankYouPage"
import {HomeOwnerSteps} from "./homeownerSteps";
import {useDispatch, useSelector} from "react-redux";
import {getHomeFinalInfo, getHomeOwnerStep1Passing} from "../../../selectors/homeOwnerSlice";
import {setHomeFinalData} from "../../../slices/homeownerSlice";
import {sendEmailQuotes, sendHomeOwnerInsurance} from "../../../services/sendEmail";
import {PrivacyInformation} from "../../PrivicyPolicyUnderForm";
import emailjs from "emailjs-com";
const ContactForm = React.lazy(() => import('./informationForm'));
const ContactOwnerInfoForm = React.lazy(() => import('./ownerInfoForm'));

function Main() {
    const firstStepPass = useSelector(getHomeOwnerStep1Passing)
    const { path} = useRouteMatch()
    const location = useLocation()
    const history = useHistory()
    const dispatch = useDispatch()
    const currentLocation = location.pathname
    const FinalInfo = useSelector(getHomeFinalInfo)
    const [homeEmail,setHomeEmail] = useState(false)

    const handleSubmit =  (values) => {
        if (location.pathname === '/home-owner-insurance-form'){
            dispatch(setHomeFinalData(values.validate[0]))
            history.push(`${location.pathname}/owner-info`)
        }else{
            dispatch(setHomeFinalData({home_owner_insurance:[FinalInfo,values.validate[0]]}))
            setHomeEmail(true)
        }
    }
    useEffect(async () => {
        if (homeEmail && FinalInfo){
            const params = {
                subject: 'Home Owner',
                home_address: FinalInfo.home_owner_insurance[0].property_address ? FinalInfo.home_owner_insurance[0].property_address : '',
                city: FinalInfo.home_owner_insurance[0].city ? FinalInfo.home_owner_insurance[0].city : '',
                state: FinalInfo.home_owner_insurance[0].state ? FinalInfo.home_owner_insurance[0].state : '',
                zip: FinalInfo.home_owner_insurance[0].zipCode ? FinalInfo.home_owner_insurance[0].zipCode : '',
                living_in_house: FinalInfo.home_owner_insurance[0].living_in_house ? FinalInfo.home_owner_insurance[0].living_in_house : '',
                property_type: FinalInfo.home_owner_insurance[0].property_type ? FinalInfo.home_owner_insurance[0].property_type : '',
                quality_grade: FinalInfo.home_owner_insurance[0].quality_grade ? FinalInfo.home_owner_insurance[0].quality_grade : '',
                residence: FinalInfo.home_owner_insurance[0].residence ? FinalInfo.home_owner_insurance[0].residence : '',
                firstName: FinalInfo.home_owner_insurance[1].firstName ? FinalInfo.home_owner_insurance[1].firstName : '',
                lastName: FinalInfo.home_owner_insurance[1].lastName ? FinalInfo.home_owner_insurance[1].lastName : '',
                owner_email: FinalInfo.home_owner_insurance[1].owner_email ? FinalInfo.home_owner_insurance[1].owner_email : '',
                personal_contact: FinalInfo.home_owner_insurance[1].personal_contact ? FinalInfo.home_owner_insurance[1].personal_contact : '',
                date_of_birth: FinalInfo.home_owner_insurance[1].date_of_birth ? FinalInfo.home_owner_insurance[1].date_of_birth : '',
                secondary_date: FinalInfo.home_owner_insurance[1].secondary_date ? FinalInfo.home_owner_insurance[1].secondary_date : '',
                secondary_firstName: FinalInfo.home_owner_insurance[1].secondary_firstName ? FinalInfo.home_owner_insurance[1].secondary_firstName : '',
                secondary_last_name: FinalInfo.home_owner_insurance[1].secondary_last_name ? FinalInfo.home_owner_insurance[1].secondary_last_name : '',
            }
            await sendHomeOwnerInsurance(params)
            history.push(`/home-owner-insurance-form/thank-you`)

        }

    },[homeEmail])

        return (
            <Switch>
                <Route exact path={path}>
                    <div className="form-bg">
                        <div className={`container w-100 ${firstStepPass && 'homeOwnerForm1Step'}`} >
                            <h1 className="text-center pt-5 pb-2">Homeowner's Insurance</h1>
                            <HomeOwnerSteps/>
                            <ContactForm currentLocation={currentLocation} onSubmit={handleSubmit}/>
                        </div>
                    </div>
                </Route>
                <Route path={`${path}/owner-info`}>
                    <div className="form-bg">
                        <div className="container w-100">
                            <h1 className="text-center pt-5 pb-2">Homeowner's Insurance</h1>
                            <HomeOwnerSteps/>
                            <ContactOwnerInfoForm currentLocation={currentLocation} onSubmit={handleSubmit}/>
                            <PrivacyInformation/>
                        </div>
                    </div>
                </Route>
                <Route path={`${path}/thank-you`}>
                    <ThankYouPage/>
                </Route>
            </Switch>
        )

}

export default Main;




import React, {useEffect, useState} from 'react';
import Layout from "../../../../../layout";
import {useDispatch, useSelector} from "react-redux";
import {getAttachedFile, getAutoChange, getAutoCurrentChange} from "../../../../../selectors/requestAChangeSelector";
import ContactForm from "./form";
import {setAutoChange} from "../../../../../slices/requestAChnage";
import {sendAttachedFile} from "../../../../../services/sendEmail";
import {useHistory} from "react-router-dom";
import emailjs from "emailjs-com";

export const AddVehicleRequestChange = () => {
    const currentChangeRequest = useSelector(getAutoCurrentChange)
    const [disc, setDisc] = useState(false)
    const [discError, setDiscError] = useState(false)
    const [isFormSubmited, setIsFormSubmited] = useState(false)
    const dispatch = useDispatch()
    const main = useSelector(getAutoChange)
    const history = useHistory()
    const attachedFile = useSelector(getAttachedFile)

    const handleSubmit = (values) => {
        if (disc){
            dispatch(setAutoChange({
                name:'Add Vehicle',
                value:values.validate[0],
            }))
            setIsFormSubmited(true)
        }else{
            setDiscError(true)
        }
    }

    useEffect( async () => {
        if (main){
            await emailjs.send("service_1d1ggng", "template_w3c71o7", {
                subject: 'Add Vehicle',
                firstName: main.MainInfo.firstName,
                lastName: main.MainInfo.lastName,
                phone: main.MainInfo.phone_number,
                policy: main.MainInfo.policy_number,
                email: main.MainInfo.apply_email,
                vin_code: main['Add Vehicle'].vin_code ? main['Add Vehicle'].vin_code : '',
                vehicle_purchase_date: main['Add Vehicle'].vehicle_purchase_date ? main['Add Vehicle'].vehicle_purchase_date : '',
                registered_owner_first_name: main['Add Vehicle'].registered_owner_first_name ? main['Add Vehicle'].registered_owner_first_name : '',
                registered_owner_last_name: main['Add Vehicle'].registered_owner_last_name ? main['Add Vehicle'].registered_owner_last_name : '',
                vehicle_current_miles: main['Add Vehicle'].vehicle_current_miles ? main['Add Vehicle'].vehicle_current_miles : '',
                vehicle_usage: main['Add Vehicle'].vehicle_usage ? main['Add Vehicle'].vehicle_usage : '',
                garaging_address_for_vehicle: main['Add Vehicle'].garaging_address_for_vehicle ? main['Add Vehicle'].garaging_address_for_vehicle : '',
                lienholder_bank_name: main['Add Vehicle'].lienholder_bank_name ? main['Add Vehicle'].lienholder_bank_name : '',
                lienholder_bank_address: main['Add Vehicle'].lienholder_bank_address ? main['Add Vehicle'].lienholder_bank_address : '',
                comprehensive_collision_deductible: main['Add Vehicle'].comprehensive_collision_deductible ? main['Add Vehicle'].comprehensive_collision_deductible : '',
                message_to_GM: main['Add Vehicle'].message_to_GM ? main['Add Vehicle'].message_to_GM : '',
                any_damage_text: main['Add Vehicle'].any_damage_text ? main['Add Vehicle'].any_damage_text : '',
                request_effective_date: main['Add Vehicle'].request_effective_date ? main['Add Vehicle'].request_effective_date : '',
            }, 'user_iuz4PywP4uJvUMl4sLkyV');
        }
        isFormSubmited && attachedFile && sendAttachedFile(attachedFile,`${main.MainInfo.firstName} ${main.MainInfo.lastName}`)
        isFormSubmited && history.push(`/request-change-thank-you`)
    },[isFormSubmited])
    return (
        <Layout full>
            <div className="form-bg">
                <div className="container w-100">
                    <h1 className="text-center pt-5 pb-2">{currentChangeRequest}</h1>
                    <ContactForm  discError={discError} disc={disc} setDisc={setDisc} onSubmit={handleSubmit}/>
                </div>
            </div>
        </Layout>
    )
}
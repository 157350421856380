import {createSlice} from "@reduxjs/toolkit";



const initialState = {
    finalForm:null
}


export const motorcyclistInsuranceSlice = createSlice({
    name: "motorcyclistInsurance",
    initialState: initialState,
    reducers: {
        setFinalMotoForm(state,action){
            return {
                ...state,
                finalForm : action.payload
            }
        },
    }
})

export const {
    setFinalMotoForm
    } = motorcyclistInsuranceSlice.actions

import Nav from "../components/Nav";
import Footer from "../components/Footer";
// import FooterHalf from "../components/Footer/footer-thank-you";

export default function Layout(props) {
    return (
        <>
            <Nav {...props}  />
            {props.children}
            <Footer />
        </>
    )
}
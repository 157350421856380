import React from 'react'
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {makeStyles} from "@material-ui/core/styles";
import {useLocation} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
}));

const getSteps = () => {
    return ['Home Information', 'Owner Information'];
}

export const HomeOwnerSteps = () => {
    const classes = useStyles();
    const steps = getSteps();
    const location = useLocation()
    const activeStep = () => {
        return location.pathname === '/home-owner-insurance-form' ? 0 : 1
    }

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep()} alternativeLabel style={{backgroundColor:"#f3f7fc"}}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    )
}
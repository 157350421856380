import AutoInsuranceQuoteIcon from "../../../static/images/icons/AutoInsuranceQuoteIcon.png";
import {Link} from "react-router-dom";
import get from 'lodash.get'
function HomeF3First({fields, buttons}) {
    return (
        <div className="info-sec-one">
            <div className="img-sec">
                <img id="right-img" src={AutoInsuranceQuoteIcon} alt="" />
            </div>
            <div className="content">
                <h2 id="content-heading"> {get(fields, 'title', '')}</h2>
                <p className="content-para">
                    {get(fields, 'text1', '')}
                    <br/>
                    {get(fields, 'text2', '')}
                    <br/>
                    {get(fields, 'text3', '')}
                </p>
                <div className="row">
                    <div className="col-sm-12 col-lg-6">
                    <Link to="/auto-insurance-form">
                        <button 
                                type="button" 
                                className="btn btn-raised btn-success btn-gm-green btn-home-radius">{get(buttons, 'getAFreeQuote', '')}</button>
                    </Link>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                    <Link to="/auto-insurance-quote">
                        <button 
                            type="button" 
                            className="btn btn-outline-success btn-gm-outline-green btn-home-radius">{get(buttons, 'learnMore', '')}</button>
                    </Link>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default HomeF3First;
import {Field, FieldArray, reduxForm} from "redux-form";
import {
    renderDate,
    renderDateToday,
    renderField, renderFieldNotRequire,
    renderFieldSelect,
    renderFieldVIN, renderPatternDateInput
} from "../../../../utils/driver-redux-form";
import {RequestChangeDisclaimor} from "../../../RequestChnageDisclaimor";
import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import passValidation from "../../../../utils/validate";
import {deductibleOptions, vehicleUsageOptionsRequestChange} from "../../../../../config/vehicles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCar, faCloudUploadAlt, faUpload} from "@fortawesome/free-solid-svg-icons";
import FileUploader from "../../../../Forms/Career/FileUpload";

const renderMembers = React.memo(({fields, meta: {touched, error, submitFailed},
                           damag,handleDamag,
                           garaged,handleGaraged,
                           violation,discError,
                           handleViolation,disc,
                           handleLienholder,lienholder
                           ,setDisc}) => {
    return (
        <div>
            {fields.map((validate, index) => (
                <ul key={index}>
                    <li  className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.vin_code`}
                            type="text"
                            component={renderFieldVIN}
                            label="Vehicle Identification Number (VIN)"
                            placeHolder="17 characters required"
                        />
                        <Field
                            name={`${validate}.vehicle_purchase_date`}
                            type="text"
                            placeholder={'MM/DD/YYYY'}
                            component={renderPatternDateInput}
                            label="Vehicle Purchase Date"
                        />
                    </li>

                    <li className='reduxFromLiMoto'>
                        <div className='haveViolation'>
                            <span
                                className='violation'>Are you the registered owner of this vehicle ? </span>
                            <label style={{marginRight: '10px'}}>
                                <Field onChange={() => handleViolation(true)} name={`${validate}.registered_owner`}
                                       checked={violation} component="input" type="radio" value="Yes"/>{' '}
                                Yes
                            </label>
                            <label style={{marginRight: '10px'}}>
                                <Field onChange={() => handleViolation(false)} name={`${validate}.registered_owner`}
                                       component="input" type="radio" value="No"/>{' '}
                                No
                            </label>
                        </div>
                    </li>
                    {!violation &&
                    <li  className='reduxFromLiMoto'>
                        <span className='registeredOwner'>First and Last Name of the registered owner</span>
                    </li>}
                    {!violation &&
                    <li  className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.registered_owner_first_name`}
                            type="text"
                            component={renderField}
                            label="First Name"
                        />
                        <Field
                            name={`${validate}.registered_owner_last_name`}
                            type="text"
                            component={renderField}
                            label="Last Name"
                        />
                    </li>}
                    <li  className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.vehicle_current_miles`}
                            type="text"
                            component={renderField}
                            label="Current Miles on Vehicle"
                        />
                        <Field
                            name={`${validate}.vehicle_usage`}
                            component={renderFieldSelect}
                            label="Vehicle Usage "
                            options={vehicleUsageOptionsRequestChange}
                        />
                    </li>

                    <li className='reduxFromLiMoto'>
                        <div className='haveViolation'>
                            <span
                                className='violation'>Are all vehicles garaged at your mailing address ? </span>
                            <label style={{marginRight: '10px'}}>
                                <Field onChange={() => handleGaraged(true)} name={`${validate}.garaged_at_mailing_address`}
                                       checked={garaged} component="input" type="radio" value="Yes"/>{' '}
                                Yes
                            </label>
                            <label style={{marginRight: '10px'}}>
                                <Field onChange={() => handleGaraged(false)} name={`${validate}.garaged_at_mailing_address`}
                                        component="input" type="radio" value="No"/>{' '}
                                No
                            </label>
                        </div>
                    </li>
                    {!garaged &&
                    <li  className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.garaging_address_for_vehicle`}
                            type="text"
                            component={renderField}
                            label="Garaging address for Vehicle "
                        />
                    </li>}

                    <li className='reduxFromLiMoto'>
                        <div className='haveViolation'>
                            <span
                                className='violation'>Is this vehicle financed or leased?  </span>
                            <label style={{marginRight: '10px'}}>
                                <Field onChange={() => handleLienholder(true)} name={`${validate}.financed_or_leased`}
                                       component="input" type="radio" value="Yes"/>{' '}
                                Yes
                            </label>
                            <label style={{marginRight: '10px'}}>
                                <Field onChange={() => handleLienholder(false)} name={`${validate}.financed_or_leased`}
                                       checked={!lienholder} component="input" type="radio" value="No"/>{' '}
                                No
                            </label>
                        </div>
                    </li>
                    {lienholder &&
                    <li  className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.lienholder_bank_name`}
                            type="text"
                            component={renderField}
                            label="Lienholder/Bank Name"
                        />
                        <Field
                            name={`${validate}.lienholder_bank_address`}
                            type="text"
                            component={renderField}
                            label="Lienholder/Bank Address"
                        />
                    </li>}
                    <li  className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.comprehensive_collision_deductible`}
                            component={renderFieldSelect}
                            label="Comprehensive & Collision Deductible"
                            options={deductibleOptions}
                        />
                        <Field
                            name={`${validate}.message_to_GM`}
                            type="text"
                            component={renderFieldNotRequire}
                            label="Message to GM Insurance Services"
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <div className='haveViolation'>
                            <span
                                className='violation'>Any Damage to Vehicle ?  </span>
                            <label style={{marginRight: '10px'}}>
                                <Field onChange={() => handleDamag(true)} name={`${validate}.any_damage_to_vehicle`}
                                       component="input" type="radio" value="Yes"/>{' '}
                                Yes
                            </label>
                            <label style={{marginRight: '10px'}}>
                                <Field onChange={() => handleDamag(false)} name={`${validate}.any_damage_to_vehicle`}
                                       checked={!damag} component="input" type="radio" value="No"/>{' '}
                                No
                            </label>
                        </div>
                    </li>
                    {damag &&
                    <li  className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.any_damage_text`}
                            type="text"
                            component={renderField}
                            label="Please describe damage "
                        />
                    </li>}
                    <li  className='reduxFromLiMoto'>
                        <FileUploader isRequest desc='Please attach Registration Card, Sales Contract or Title of the vehicle ' upload='Upload'/>
                    </li>
                    <li  className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.request_effective_date`}
                            type="date"
                            component={renderDateToday}
                            label="Proposed Effective Date"
                        />
                    </li>
                </ul>
            ))}
            <RequestChangeDisclaimor disc={disc} setDisc={setDisc}/>
            {discError && <p style={{color:'red'}}>Please read and check online policy change request Disclaimer</p>}
            <div className={'addDriver'}>
                <button style={{display: 'none'}} className='addButton' id='defaultValues' type="button"
                        onClick={() => fields.push({})}/>
            </div>
        </div>
    )
})

const FieldArraysForm = props => {
    const [violation, setViolation] = useState(true)
    const handleViolation = (data) => {
        setViolation(data)
    }
    const [lienholder,setLienholder] = useState(false)
    const handleLienholder = (data) => {
        setLienholder(data)
    }
    const [garaged, setGaraged] = useState(true)
    const handleGaraged = (data) => {
        setGaraged(data)
    }
    const [damag, setDamag] = useState(false)
    const handleDamag = (data) => {
        setDamag(data)
    }
    useEffect(() => {
        const defaultClick = document.getElementById('defaultValues')
        defaultClick.click()
    }, [])
    const [mailing, setMailing] = useState(true)
    const {handleSubmit} = props;
    return (
        <form onSubmit={handleSubmit} style={{marginTop: '25px'}}>
            <FieldArray name="validate"
                        handleLienholder={handleLienholder}
                        lienholder={lienholder}
                        damag={damag}
                        handleDamag={handleDamag}
                        handleGaraged={handleGaraged}
                        garaged={garaged}
                        violation={violation}
                        handleViolation={handleViolation}
                        discError={props.discError}
                        disc={props.disc}
                        setDisc={props.setDisc}
                        setMailing={setMailing}
                        mailing={mailing}
                        component={renderMembers}/>
            <div className='request'>
                <Button type='submit'  style={{marginTop: '15px'}} variant="contained"
                        color="primary">
                    Submit Request
                </Button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'fieldArrays',
    validate: passValidation,
})(FieldArraysForm);

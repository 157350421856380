import Layout from "../../layout";
import FormComponent from "../../components/Forms/AutoInsurance";
import { useState } from "react";

export default function AutoInsuranceForm() {
    const [width, setWidth] = useState("50");
    return (
        <Layout width={width} active full>
            <FormComponent insuranceType="Auto Insurance" getWidth={setWidth}/>
        </Layout>
    );
}

import Layout from "../../layout";
import HomeF1 from "../../components/Home/HomeF1";
import HomeF2 from "../../components/Home/HomeF2";
import HomeF3 from "../../components/Home/HomeF3";
import HomeF4 from "../../components/Home/HomeF4";
import HomeF5 from "../../components/Home/HomeF5";
import { useContentFull } from '../../hooks/useContentFull'
import { PageLoader } from "../../components/PageLoader/PageLoader";
import get from "lodash.get";

export default function Home() {
    const {fields, loading} = useContentFull()

    if(loading){
        return (
            <PageLoader />
        )
    }
    return (
        <Layout full>
            <title>{get(fields, 'pageTitle', '')}</title>
            <HomeF1 fields={fields} />
            <HomeF2 fields={fields.compareCarInsuranceRate || {}} />
            <HomeF3 buttons={fields.otherFields || {}} fields={{
                autoInsurance: fields.autoInsuranceQuote || {},
                commercialAuto: fields.commericalAutoInsurances || {},
                homeOwner: fields.homeownerInsurances || {}
            }} />
            <HomeF4 fields={fields.otherProtection || {}} />
            <HomeF5 buttonText={fields.otherFields.getAFreeQuote || ''} text={fields.saveMoney || ''} />
        </Layout>
    );
}

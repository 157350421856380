export const goTO = (isChecked) => {
    if (isChecked === 'AddressChange'){
        return '/address-request'
    }else if (isChecked === 'Driver Change') {
        return '/driver-change'
    }else if(isChecked === 'Lienholder/Bank Change'){
        return 'lienholder-change'
    }else if(isChecked === 'Other Change Request'){
        return 'other-change'
    }else if(isChecked === 'Document Request'){
        return 'policy-document-request'
    }else if(isChecked === 'Vehicle Change'){
        return 'vehicle-request'
    }
}
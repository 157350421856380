import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {API} from "aws-amplify";
import {listMakes, listModels, listTrims, listYears} from "../graphql/queries";
import axios from 'axios';


const DEFAULT_HEADERS = {
    headers: {
        'X-RapidAPI-Key': 'f2a4efaceemsh2fe573f8628acb1p101adfjsnd83acfd5b2f7',
        'X-RapidAPI-Host': 'car-api2.p.rapidapi.com'
      }
}

const initialState = {
    fetchYearsResult: {
        years:[],
        status:'idle',
        error:null
    },
    fetchMakesResult:{
        makes:[],
        status:'idle',
        error:null
    },
    fetchModelsResult:{
        models:[],
        status:'idle',
        error:null
    },
    fetchTrimsResult:{
        trims:[],
        status:'idle',
        error:null
    },
}

export const fetchYears = createAsyncThunk(
    'years/fetchYears',
    async () => {
        let {data:{listYears:years}} = await API.graphql({query:listYears})
        years = years.map(obj => obj.year)
        return years
    }
)

export const fetchMakesByYear = createAsyncThunk(
    'years/fetchMakesByYear',
    async (year) => {
        let {data:{listMakes:makes}} = await API.graphql({query:listMakes, variables:{year:year}})
        makes = makes.map(obj => obj.make)
        return makes
    }
)

export const fetchModelsByMake = createAsyncThunk(
    'years/fetchModelsByMake',
    async (make) => {
        let {data:{listModels:models}} = await API.graphql({query:listModels, variables:{make:make}})
        models = models.map(obj => obj.model)
        return models
    }
)

export const fetchTrimsByModel = createAsyncThunk(
    'years/fetchTrimsByModel',
    async (model) => {
        let {data:{listTrims:trims}} = await API.graphql({query:listTrims, variables:{model:model}})
        trims = trims.map(obj => obj.trim)
        return trims
    }
)

export const yearsSlices = createSlice({
    name:'yearsMakeModel',
    initialState:initialState,
    reducers:{

    },
    extraReducers:{
        // Fetch years
        [fetchYears.fulfilled]: (state, action) => {
            state.fetchYearsResult.status = 'succeeded'
            state.fetchYearsResult.years = action.payload
        },
        [fetchYears.pending]: (state, action) => {
            state.fetchYearsResult.status = 'loading'
            state.fetchMakesResult.makes = []
            state.fetchModelsResult.models = []
            state.fetchTrimsResult.trims = []
        },
        [fetchYears.rejected]: (state, action) => {
            state.fetchYearsResult.status = 'failed'
            state.fetchYearsResult.error = action.error.message
        },

        // Fetch makes
        [fetchMakesByYear.fulfilled]: (state, action) => {
            state.fetchMakesResult.status = 'succeeded'
            state.fetchMakesResult.makes = action.payload
        },
        [fetchMakesByYear.pending]: (state, action) => {
            state.fetchMakesResult.status = 'loading'
            state.fetchModelsResult.models = []
            state.fetchTrimsResult.trims = []
        },
        [fetchMakesByYear.rejected]: (state, action) => {
            state.fetchMakesResult.status = 'failed'
            state.fetchMakesResult.error = action.error.message
        },

        // Fetch models
        [fetchModelsByMake.fulfilled]: (state, action) => {
            state.fetchModelsResult.status = 'succeeded'
            state.fetchModelsResult.models = action.payload
        },
        [fetchModelsByMake.pending]: (state, action) => {
            state.fetchModelsResult.status = 'loading'
            state.fetchTrimsResult.trims = []
        },
        [fetchModelsByMake.rejected]: (state, action) => {
            state.fetchModelsResult.status = 'failed'
            state.fetchModelsResult.error = action.error.message
        },

        // Fetch trims
        [fetchTrimsByModel.fulfilled]: (state, action) => {
            state.fetchTrimsResult.status = 'succeeded'
            state.fetchTrimsResult.trims = action.payload
        },
        [fetchTrimsByModel.pending]: (state, action) => {
            state.fetchTrimsResult.status = 'loading'
        },
        [fetchYears.rejected]: (state, action) => {
            state.fetchTrimsResult.status = 'failed'
            state.fetchTrimsResult.error = action.error.message
        }
    }
})



export const states = [
        { key: '1', text: 'Alabama', value: 'AL' },
        { key: '2', text: 'Alaska', value: 'AK' },
        { key: '3', text: 'Arizona', value: 'AZ' },
        { key: '4', text: 'Arkansas', value: 'AR' },
        { key: '5', text: 'California', value: 'CA' },
        { key: '6', text: 'Colorado', value: 'CO' },
        { key: '7', text: 'Connecticut', value: 'CT' },
        { key: '8', text: 'Delaware', value: 'DE' },
        { key: '9', text: 'District Of Columbia', value: 'DC' },
        { key: '10', text: 'Florida', value: 'FL' },
        { key: '11', text: 'Georgia', value: 'GA' },
        { key: '12', text: 'Hawaii', value: 'HI' },
        { key: '13', text: 'Idaho', value: 'ID' },
        { key: '14', text: 'Illinois', value: 'IL' },
        { key: '15', text: 'Indiana', value: 'IN' },
        { key: '16', text: 'Iowa', value: 'IA' },
        { key: '17', text: 'Kansas', value: 'KS' },
        { key: '18', text: 'Kentucky', value: 'KY' },
        { key: '19', text: 'Louisiana', value: 'LA' },
        { key: '20', text: 'Maine', value: 'ME' },
        { key: '21', text: 'Maryland', value: 'MD' },
        { key: '22', text: 'Massachusetts', value: 'MA' },
        { key: '23', text: 'Michigan', value: 'MI' },
        { key: '24', text: 'Minnesota', value: 'MN' },
        { key: '25', text: 'Mississippi', value: 'MS' },
        { key: '26', text: 'Missouri', value: 'MO' },
        { key: '27', text: 'Montana', value: 'MT' },
        { key: '28', text: 'Nebraska', value: 'NE' },
        { key: '29', text: 'Nevada', value: 'NV' },
        { key: '30', text: 'New Hampshire', value: 'NH' },
        { key: '31', text: 'New Jersey', value: 'NJ' },
        { key: '32', text: 'New Mexico', value: 'NM' },
        { key: '33', text: 'New York', value: 'NY' },
        { key: '34', text: 'North Carolina', value: 'NC' },
        { key: '35', text: 'North Dakota', value: 'ND' },
        { key: '36', text: 'Ohio', value: 'OH' },
        { key: '37', text: 'Oklahoma', value: 'OK' },
        { key: '38', text: 'Oregon', value: 'OR' },
        { key: '39', text: 'Pennsylvania', value: 'PA' },
        { key: '40', text: 'Rhode Island', value: 'RI' },
        { key: '41', text: 'South Carolina', value: 'SC' },
        { key: '42', text: 'South Dakota', value: 'SD' },
        { key: '43', text: 'Tennessee', value: 'TN' },
        { key: '44', text: 'Texas', value: 'TX' },
        { key: '45', text: 'Utah', value: 'UT' },
        { key: '46', text: 'Vermont', value: 'VT' },
        { key: '47', text: 'Virginia', value: 'VA' },
        { key: '48', text: 'Washington', value: 'WA' },
        { key: '49', text: 'West Virginia', value: 'WV' },
        { key: '50', text: 'Wisconsin', value: 'WI' },
        { key: '51', text: 'Wyoming', value: 'WY' },
    {key: '52', text: 'International', value: 'International'},
];

export const gender_options = [
    { key: '1', text: 'Female', value: 'female' },
    { key: '2', text: 'Male', value: 'male' },
    { key: '3', text: 'Other', value: 'other' },
];
export const marital_status = [
    { key: '1', text: 'Single', value: 'single' },
    { key: '2', text: 'Married', value: 'married' },
    { key: '3', text: 'Seperatted', value: 'seperatted' },
    { key: '3', text: 'Divorced', value: 'divorced' },
    { key: '3', text: 'Widowed', value: 'widowed' },
];

export const liability_limit = [
    { key: '1', text: 'California Minimum 15,000/30,000/5,000', value: 'CAmin' },
    { key: '2', text: 'Basic 25,000/50,000/25,000', value: 'basic' },
    { key: '3', text: 'Standard 100,000/300,000/50,000', value: 'standard' },
    { key: '3', text: 'Superior 250,000/500,000/100,000', value: 'superior' },
];


export default {
    states, gender_options, marital_status, liability_limit
}

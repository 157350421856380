const passValidation = values => {
    const errors = {};
    if (!values.clubName) {
        errors.clubName = 'Required';
    }
    if (!values.validate || !values.validate.length) {
        errors.validate = { _error: 'At least one member must be entered' };
    } else {
        const validateErrors = [];
        values.validate.forEach((passValidation, memberIndex) => {
            const validErrors = {};
            if (!passValidation || !passValidation.companyType) {
                validErrors.companyType = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.companyName) {
                validErrors.companyName = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.apply_email) {
                validErrors.apply_email = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.date_of_birth) {
                validErrors.date_of_birth = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.vehicleNumber) {
                validErrors.vehicleNumber = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.driversNUmber) {
                validErrors.driversNUmber = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.firstName) {
                validErrors.firstName = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.lastName) {
                validErrors.lastName = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.property_address) {
                validErrors.property_address = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.city) {
                validErrors.city = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.state) {
                validErrors.state = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.position_of_interest) {
                validErrors.position_of_interest = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.zipCode) {
                validErrors.zipCode = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.personal_contact) {
                validErrors.personal_contact = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.footage) {
                validErrors.footage = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.property_type) {
                validErrors.property_type = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.renter_email) {
                validErrors.renter_email = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.owner_email) {
                validErrors.owner_email = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.home_address) {
                validErrors.home_address = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.phone_number) {
                validErrors.phone_number = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.garaging_address) {
                validErrors.garaging_address = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.request_effective_date) {
                validErrors.request_effective_date = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.driver_license_number) {
                validErrors.driver_license_number = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.lienholder_name) {
                validErrors.lienholder_name = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.lienholder_address) {
                validErrors.lienholder_address = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.lienholder_message) {
                validErrors.lienholder_message = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.other_change_message) {
                validErrors.other_change_message = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.vin_code) {
                validErrors.vin_code = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.vehicle_purchase_date) {
                validErrors.vehicle_purchase_date = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.registered_owner_first_name) {
                validErrors.registered_owner_first_name = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.registered_owner_last_name) {
                validErrors.registered_owner_last_name = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.vehicle_current_miles) {
                validErrors.vehicle_current_miles = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.vehicle_usage) {
                validErrors.vehicle_usage = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.garaging_address_for_vehicle) {
                validErrors.garaging_address_for_vehicle = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.lienholder_bank_name) {
                validErrors.lienholder_bank_name = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.lienholder_bank_address) {
                validErrors.lienholder_bank_address = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.any_damage_text) {
                validErrors.any_damage_text = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.comprehensive_collision_deductible) {
                validErrors.comprehensive_collision_deductible = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.vin_code_new_vehicle) {
                validErrors.vin_code_new_vehicle = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.vin_code_removed_vehicle) {
                validErrors.vin_code_removed_vehicle = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.vehicle_year) {
                validErrors.vehicle_year = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.vehicle_make) {
                validErrors.vehicle_make = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
            if (!passValidation || !passValidation.vehicle_model) {
                validErrors.vehicle_model = 'Required';
                validateErrors[memberIndex] = validErrors;
            }
        });
        if (validateErrors.length) {
            errors.validate = validateErrors;
        }
    }
    return errors;
};

export default passValidation;





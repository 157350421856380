import Layout from "../../layout";
import FormComponent from "../../components/Forms/RentersInsurance";

export default function RentersInsuranceForm() {
    return (
        <Layout full>
            <FormComponent />
        </Layout>
    );
}

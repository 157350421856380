import React, { useMemo } from 'react'
import get from "lodash.get";

export default function MakePaymentOnline({fields}) {
    const currentValues = useMemo(() => {
        return get(fields, 'values.values', [])
    },[fields])
    const t_head_style = {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        lineSeight: '23px',
        letterSpacing: '0.15px',
        textTransform: 'uppercase',
        color: '#373F41'
    }


    const btn = {
        backgroundColor: '#006AB0',
        borderRadius: '5px',
        color:'white',
        paddingTop: '10px',
        paddingBottom: '10px',
        textTransform: 'none'
    }
    return (
        
        <div class="container-fluid generic_font_color">
            <div class="row mb-3 mt-5">
            </div>
            <div class="row mb-3">
                <div class="col col-lg-12">
                    <div className="table-responsive">
                    <table className="table desktop">
                        <thead>
                            <tr>
                            <th style={t_head_style}>{get(fields, 'values.insuranceCompany', '')}</th>
                            <th></th>
                            <th className="text-right pr-5" style={t_head_style}>{get(fields, 'values.siteLink', '')}</th>
                            </tr>
                        </thead>
                        <tbody>
                        {currentValues.map((curr) => {
                                    return (
                                     <tr>
                                        <td>{curr.title}</td>
                                             <td><a  href={`tel:${curr.phone}`}>{curr.phone}</a></td>
                                            <td className="text-right"><a className="btn" href={curr.url} rel="noreferrer"  target="_blank" style={btn}>
                                            {get(fields, 'values.reportAClaimButtonText', '')}
                                            </a></td>
                                    </tr>
                                    )
                                })}  
                        </tbody>
                    </table>
                    </div>                  
                </div>
            </div>

        </div>
    );
}

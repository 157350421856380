import React from 'react';
import '../../assets/css/autoInsurance.css'

export const WebsiteAccessibility = () => {
    return (
        <div>
            <div className='accessibility_div'>
            </div>
            <div className='accessibility_div'>
                <p className='accessibility_p'>
                    GM Insurance Services, Inc. is continually looking for ways to improve the usability of our website for all customers
                </p>
            </div>
            <div className='accessibility_div_2'>
                <p className='accessibility_p'>
                    If you have trouble accessing any of the information on gminsuranceservices.com, please contact us online or call <span style={{color:'green'}}>877-333-8303.</span> Please provide the location of the inaccessible information.
                </p>
            </div>
        </div>
    )
}
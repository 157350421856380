import React, { useMemo } from 'react';
import ReactSelect from "react-select";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setZipcode} from "../../../slices/autoInsuranceSlice";
import get from 'lodash.get';
import { getImageFromContentFull } from '../../../utils/contentful';

const insuranceOptions = [
    {
        label: "Auto Insurance",
        value: "/auto-insurance-form",
    },
    {
        label: "Commercial Auto Insurance",
        value: "/commercial-auto-insurance-form",
    },    {
        label: "DUI Insurance",
        value: "/dui-insurance-form",
    },
    {
        label: "Homeowners Insurance",
        value: "/home-owner-insurance-form",
    },
    {
        label: "Motorcycle Insurance",
        value: "/motorcycle-insurance-form",
    },
    {
        label: "Non Owner Insurance",
        value: "/non-owner-insurance-form",
    },
    {
        label: "Renters Insurance",
        value: "/renters-insurance-form",
    },
    {
        label: "SR 22 Insurance",
        value: "/sr22-insurance-form",
    },
];


const styles = {
    indicatorSeparator: (provided, state) => ({
        ...provided,
        backgroundColor: "#90CF36",
        marginTop: "5px",
        marginBottom: "20px"
    }),
    control: (provided, state) => ({
        ...provided,
        border: "2px solid #90CF36",
        height: "70px",
        backgroundColor: "#EBEBEC"
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        color: "#90CF36"
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: "#000",
        marginLeft: "20px"
    }),
    input: (provided, state) => ({
        ...provided,
        color: "#000",
        marginLeft: "20px"
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        marginTop: "-5px"
    }),
    container: (provided, state) => ({
        ...provided,
    })
};

const HomeF1 = React.memo(({fields}) =>  {
    const [insurance, setInsurance] = React.useState('');
    const [redirectLink, setRedirectLink] = React.useState('');
    const dispatch = useDispatch()
    const history = useHistory();

    const handleChange = (event, value) => {
        setInsurance(event);
        setRedirectLink(event.value);
    };

    const handleZipCodeInput = (event) => {
        dispatch(setZipcode(event.target.value))
    }

    const handleEnterClick = (event) => {
        if (event.key === 'Enter') {
            history.push(redirectLink);
        }
    }

    const mainImage = useMemo(() => {
        return getImageFromContentFull(fields.mainImage)
    },[fields])
    
    return (
        <div className="landing landingImg" style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${mainImage})`}}>
            <div className="main-headings-sec">
                <h1 className="landing-heading">
                    {get(fields, 'mainTitle.beforeStrong', '')}
                    <strong className="color-head"> {get(fields, 'mainTitle.strong', '')} </strong>
                    {get(fields, 'mainTitle.afterStrong', '')}
                </h1>
                <h2 className="landing2-heading">{get(fields, 'mainTitle.mainSubTitle', '')}</h2>
            </div>
            <div className="container landing-form">
                <div className="row g-0">
                    <div className="col-sm-12 col-lg-4">
                        <div className="form-group">
                               {/* <img id="drp-icon" src={DPArrow} alt="DP Arrow Icon" /> */}
                            <ReactSelect
                                onChange={handleChange}
                                styles={styles}
                                value={insurance}
                                placeholder={get(fields, 'otherFields.selectInsurance', '')}
                                options={insuranceOptions}
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-4 mt-sm-5 mt-lg-0">
                        <div className="form-group">
                            <input onChange={handleZipCodeInput} onKeyPress={handleEnterClick} className="form-control" id="zip-gm" label="Enter Zip Code"  placeholder={get(fields, 'otherFields.enterZip', '')} type="text"/>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-4">
                            <button
                                onClick={() => history.push(redirectLink)}
                                type="button"
                                className="btn btn-raised btn-success btn-gm-green btn-home-radius">
                                    {get(fields, 'otherFields.getAFreeQuote', '')}
                            </button>
                    </div>
                </div>
            </div>
            <div className="container">
                <p className="landing-para">
                    {get(fields, 'mainTitleDescription', '')}
                </p>
            </div>
        </div>
    );
})

export default HomeF1;

import FirstComponent from "./FirstComponent";
import SecondComponent from "./SecondComponent";
function HomeF4({fields}) {
    return (
        <>
            <FirstComponent fields={fields} />
            <SecondComponent />
        </>
    );
}

export default HomeF4;
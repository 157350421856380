
export const autoSrDuiEmailInfoVehicle = (currentForm) => {
    return {
            make:currentForm[0].vehicles.map((el,index) => `${index + 1} - ${el.make}`).toString(),
            model:currentForm[0].vehicles.map((el,index) => `${index + 1} - ${el.model}`).toString(),
            trim:currentForm[0].vehicles.map((el,index) => `${index + 1} - ${el.trim}`).toString(),
            usage:currentForm[0].vehicles.map((el,index) => `${index + 1} - ${el.usage}`).toString(),
            year:currentForm[0].vehicles.map((el,index) => `${index + 1} - ${el.year}`).toString(),
            comprehensiveDeductible:currentForm[0].vehicles.map((el,index) => `${index + 1} - ${el.comprehensiveDeductible}`).toString(),
            collisionDeductible:currentForm[0].vehicles.map((el,index) => `${index + 1} - ${el.collisionDeductible}`).toString(),
    }
}

export const autoSrDuiEmailInfoDriver = (currentForm) => {
    return {
        firstName:currentForm[1].drivers.map((el,index) =>el.firstName ?  `${index + 1} - ${el.firstName}`: '').toString(),
        lastName:currentForm[1].drivers.map((el,index) =>el.lastName ?  `${index + 1} - ${el.lastName}`: '').toString(),
        middleName:currentForm[1].drivers.map((el,index) =>el.middleName ?  `${index + 1} - ${el.middleName}`: '').toString(),
        homeAddress:currentForm[1].drivers.map((el,index) =>el.homeAddress ?  `${index + 1} - ${el.homeAddress}`: '').toString(),
        city:currentForm[1].drivers.map((el,index) =>el.city ?  `${index + 1} - ${el.city}`: '').toString(),
        state:currentForm[1].drivers.map((el,index) =>el.state ?  `${index + 1} - ${el.state}`: '').toString(),
        zipCode:currentForm[1].drivers.map((el,index) =>el.zipCode ?  `${index + 1} - ${el.zipCode}`: '').toString(),
        contact:currentForm[1].drivers.map((el,index) =>el.contact ?  `${index + 1} - ${el.contact}`: '').toString(),
        email:currentForm[1].drivers.map((el,index) =>el.email ?  `${index + 1} - ${el.email}`: '').toString(),
        date_of_birth:currentForm[1].drivers.map((el,index) =>el.date_of_birth ?  `${index + 1} - ${el.date_of_birth}`: '').toString(),
        gender:currentForm[1].drivers.map((el,index) =>el.gender ?  `${index + 1} - ${el.gender}`: '').toString(),
        marital_status:currentForm[1].drivers.map((el,index) =>el.marital_status ?  `${index + 1} - ${el.marital_status}`: '').toString(),
        driver_license_number:currentForm[1].drivers.map((el,index) =>el.driver_license_number ?  `${index + 1} - ${el.driver_license_number}`: '').toString(),
        violation_state:currentForm[1].drivers.map((el,index) =>el.violation_state ?  `${index + 1} - ${el.violation_state}`: '').toString(),
        liability_limit:currentForm[1].drivers.map((el,index) =>el.liability_limit ?  `${index + 1} - ${el.liability_limit}`: '').toString(),
    }
}

export const MotoFormCurrent = (currentForm) => {
    return {
        moto_vin_make_model:currentForm.drivers.map((el,index) =>el.moto_vin_make_model ?  `${index + 1} - ${el.moto_vin_make_model}`: '').toString(),
        firstName:currentForm.drivers.map((el,index) =>el.firstName ?  `${index + 1} - ${el.firstName}`: '').toString(),
        lastName:currentForm.drivers.map((el,index) =>el.lastName ?  `${index + 1} - ${el.lastName}`: '').toString(),
        email:currentForm.drivers.map((el,index) =>el.email ?  `${index + 1} - ${el.email}`: '').toString(),
        city:currentForm.drivers.map((el,index) =>el.city ?  `${index + 1} - ${el.city}`: '').toString(),
        contact:currentForm.drivers.map((el,index) =>el.contact ?  `${index + 1} - ${el.contact}`: '').toString(),
        date_of_birth:currentForm.drivers.map((el,index) =>el.date_of_birth ?  `${index + 1} - ${el.date_of_birth}`: '').toString(),
        driver_license_number:currentForm.drivers.map((el,index) =>el.driver_license_number ?  `${index + 1} - ${el.driver_license_number}`: '').toString(),
        gender:currentForm.drivers.map((el,index) =>el.gender ?  `${index + 1} - ${el.gender}`: '').toString(),
        homeAddress:currentForm.drivers.map((el,index) =>el.homeAddress ?  `${index + 1} - ${el.homeAddress}`: '').toString(),
        marital_status:currentForm.drivers.map((el,index) =>el.marital_status ?  `${index + 1} - ${el.marital_status}`: '').toString(),
        violation_state:currentForm.drivers.map((el,index) =>el.violation_state ?  `${index + 1} - ${el.violation_state}`: '').toString(),
        relation_to_insured:currentForm.drivers.map((el,index) =>el.relation_to_insured ?  `${index + 1} - ${el.relation_to_insured}`: '').toString(),
        state:currentForm.drivers.map((el,index) =>el.state ?  `${index + 1} - ${el.state}`: '').toString(),
        zipCode:currentForm.drivers.map((el,index) =>el.zipCode ?  `${index + 1} - ${el.zipCode}`: '').toString(),
    }
}
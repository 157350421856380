import React, {useEffect, useState} from "react";
import ContactForm from "./addressChangeform";
import {useDispatch, useSelector} from "react-redux";
import Layout from "../../../../layout";
import {setAutoChange} from "../../../../slices/requestAChnage";
import {
    getAutoChange,
} from "../../../../selectors/requestAChangeSelector";
import '../../../../assets/css/autoInsurance.css'
import {useHistory} from "react-router-dom";
import {sendEmailChangeRequest} from "../../../../services/sendEmail";
import '../../../../assets/css/autoInsurance.css'
import emailjs from "emailjs-com";

const AddressChangeRequest = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const main = useSelector(getAutoChange)
    const [disc, setDisc] = useState(false)
    const [discError, setDiscError] = useState(false)
    const [isFormSubmited, setIsFormSubmited] = useState(false)

    const handleSubmit = (values) => {
        if (disc){
            dispatch(setAutoChange({
                name:'Address Change',
                value:values.validate[0]
            }))
            setIsFormSubmited(true)
        }else{
            setDiscError(true)
        }
    }

    useEffect(async () => {
        if (main){
            await emailjs.send("service_1d1ggng", "template_mqnbbah", {
                subject: 'Auto Address',
                firstName: main.MainInfo.firstName,
                lastName: main.MainInfo.lastName,
                phone: main.MainInfo.phone_number,
                policy: main.MainInfo.policy_number,
                email: main.MainInfo.apply_email,
                garaging:main['Address Change'].garaging_address ? main['Address Change'].garaging_address : '',
                app_suite: main['Address Change'].apt_suit ? main['Address Change'].apt_suit : '',
                city: main['Address Change'].city ? main['Address Change'].city : '',
                state: main['Address Change'].state ? main['Address Change'].state : '',
                zip: main['Address Change'].zipCode ? main['Address Change'].zipCode : '',
                date_request: main['Address Change'].request_effective_date ? main['Address Change'].request_effective_date : '',
                mailing_address: main['Address Change'].mailing_address ? main['Address Change'].mailing_address : '',
                mailing_apt_suite: main['Address Change'].mailing_apt_suit ? main['Address Change'].mailing_apt_suit : '',
                mailing_city: main['Address Change'].mailing_city ? main['Address Change'].mailing_city : '',
                mailing_state: main['Address Change'].mailing_state ? main['Address Change'].mailing_state : '',
                mailing_zip: main['Address Change'].mailing_zipCode ? main['Address Change'].mailing_zipCode : '',
            }, 'user_iuz4PywP4uJvUMl4sLkyV');
        }
        isFormSubmited && history.push(`/request-change-thank-you`)
    },[isFormSubmited])


    return (
        <Layout full>
        <div className="form-bg">
            <div className="container w-100">
                <h1 className="text-center pt-5 pb-2">Address Change</h1>
                <span className="addressChangeHeading">Please tell us your NEW mailing and garaging address</span>
                <ContactForm discError={discError} disc={disc} setDisc={setDisc} onSubmit={handleSubmit}/>
            </div>
        </div>
        </Layout>

    )
}

export default AddressChangeRequest
import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    finalData:null
}



export const renterInsuranceSlice = createSlice({
    name: "renterInsurance",
    initialState: initialState,
    reducers: {
        setRenterFinalData(state,action){
            return{
                ...state,
                finalData: action.payload
            }
        }
    }
})

export const {
    setRenterFinalData,
} = renterInsuranceSlice.actions

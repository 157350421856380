import Layout from "../../layout";
import Hero from "../../components/Hero";
import OurHonesty  from "../../components/StaticPages/OurHonesty";
import { PageLoader } from "../../components/PageLoader/PageLoader";
import { getReviews } from "../../selectors/requestAChangeSelector";
import { useSelector } from "react-redux";
import { fetchMenus } from "../../slices/requestAChnage";
import { useDispatch } from 'react-redux'
import { useEffect } from "react";
import { useMemo } from "react";
import { getImageFromContentFull } from "../../utils/contentful";
import { useContentFull } from '../../hooks/useContentFull'
import get from "lodash.get";

export default function OurHonestyPage() {
    const content = {
        'bg_img': 'staic_pages_landing ourmission-bg-image',
        'text': ' Customer reviews for',
        'green_text': 'GM Insurance Services',
        'text2': '',
        'description1': 'Our customer say we\'re Fast and Easy to work with.',
        'link': '/auto-insurance-form',
        font: '51px',
    }
    const dispatch = useDispatch()
    useEffect(()=> {
        dispatch(fetchMenus())
    },[])
    const {fields, loading, pictureTitle} = useContentFull()
    const currentHeroImage = useMemo(() => {
        return getImageFromContentFull(fields.background)
    },[fields])

    const description = useMemo(() => {
        return get(fields, 'subtitle', '')
      },[fields])
    
    const reviews = useSelector(getReviews)
    if(loading || !currentHeroImage || reviews.length < 1){
        return(
            <PageLoader />
        )
    }
    return (
        <Layout full>
            <Hero pictureTitle={pictureTitle} description={description}  image={currentHeroImage} content={content}/>
            <OurHonesty reviews={reviews}/>
        </Layout>
    );
}

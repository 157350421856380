import Completed from "../../static/images/icons/Completed.png";
import { useHistory } from 'react-router-dom';
import {useEffect} from "react";

function ThankYouPage() {
    const history = useHistory();

    useEffect(() => {
        return history.listen(location => {
          if (history.action === 'POP') {
              history.replace({ pathname: '/'});
          }
        })
      }, [ history ])

    return (
        <div>
            <div className="completed-items text-center">
                    <img id="completedIcon" src={Completed} alt="Completed" />
            </div>

            <div className="submitted-request">
                <div className="submitted-request-content">
                    <h2 className="submitted-request-heading">
                        Your quote request has been submitted successfully.
                    </h2>
                    <p>
                        One of our representatives will compare your quotes and contact you shortly with all the available options.
                    </p>
                </div>
                <br/>
                <br/>

            </div>

        </div>

    );
}

export default ThankYouPage;

import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {useHistory } from "react-router-dom";
import { client } from "../contentful/client";
import { getCurrentContentFullId } from "../utils/contentful";

export const useContentFull = () => {
    const history = useHistory()
    const location = useLocation()
    const [fields, setFields] = useState({})
    const [loading, setLoading] = useState(true)
    const [pictureTitle, setPictureTitle] = useState({})

    const currentId = useMemo(() => {
        return getCurrentContentFullId(location.pathname)
    },[location.pathname])

    const setContent = (resp) => {
        if(resp.fields.pictureContent){
            setPictureTitle(resp.fields.pictureContent)
        }
        setFields(resp.fields)
    }

    useEffect(() => {
        if(currentId){
            client.getEntry(currentId)
            .then((resp) => setContent(resp))
            .catch(() => history.push('/'))
            .finally(() => setLoading(false))
        }
    },[currentId])


    return {
        fields,
        loading,
        pictureTitle
    }
}
import Layout from "../../layout";
import Hero from "../../components/Hero";
import Career from "../../components/StaticPages/Career";
import { useContentFull } from "../../hooks/useContentFull";
import { useMemo } from "react";
import { getImageFromContentFull } from "../../utils/contentful";
import { PageLoader } from "../../components/PageLoader/PageLoader";
import get from "lodash.get";
export default function CareerPage() {
  const content = {
    'bg_img': 'staic_pages_landing career-bg-image',
    'text': 'Come Work for Us!',
    'green_text': '',
      'link': '/career',
      'applyOnlineBtn': true,
      NoNeedForm:true,
      description1: true
  }
    const {fields, loading, pictureTitle} = useContentFull()
  const currentHeroImage = useMemo(() => {
      return getImageFromContentFull(fields.background)
  },[fields])

  const description = useMemo(() => {
    return get(fields, 'subtitle', '')
  },[fields])

  if(loading || !currentHeroImage){
      return (
          <PageLoader />
      )
  }

return (
    <Layout full>
        <Hero pictureTitle={pictureTitle} description={description} image={currentHeroImage} content={content}/>
      <Career fields={fields} />
    </Layout>
  );
}

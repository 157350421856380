import Layout from "../../layout";
import {useState} from "react";
import emailjs from "emailjs-com";
import { useContentFull } from "../../hooks/useContentFull";
import { PageLoader } from "../../components/PageLoader/PageLoader";
import get from "lodash.get";

function ContacUsForm() {
    const [message,sendMessage] = useState({Contact_US:{}})

    const handleInputChange = (e) => {
        sendMessage({Contact_US:{
            ...message.Contact_US,
                [e.target.name] : e.target.value
            }})
    }
    const {fields, loading} = useContentFull()

    
    if(loading){
        return (
            <PageLoader />
        )
    }
    const sendEmailContact = () => {
         emailjs.send("service_1d1ggng", "template_2iywbg6", {
             First_Name:message['Contact_US'].First_Name ? message['Contact_US'].First_Name : '',
             Last_Name:message['Contact_US'].Last_Name ? message['Contact_US'].Last_Name : '',
             Email:message['Contact_US'].Email ? message['Contact_US'].Email : '',
             Phone:message['Contact_US'].Phone ? message['Contact_US'].Phone : '',
             Message:message['Contact_US'].Message ? message['Contact_US'].Message : '',
         }, 'user_iuz4PywP4uJvUMl4sLkyV');
        sendMessage({Contact_US: {
                First_Name:'',
                Last_Name:'',
                Email:'',
                Phone:'',
                Message:''
            }})
    }

    return (
        <Layout full>
            <div className="form-bg contact-us-form">
                <title>{get(fields, 'pageTitle', '')}</title>
                <div className="container w-75">
                    <h1 className="text-center pt-5 pb-2">
                    {get(fields, 'title', '')}
                    </h1>
                    <div className="row">
                        <div className="col-sm-12 col-lg-6">
                            <p className="addresses">
                                {get(fields, 'values.info', '[]').map((curr) => {
                                    return <span>{curr}<br /></span>
                                })}
                            </p>
                            <p className="timings">                            
                                <strong>{get(fields, 'values.businessHoursTitle', '')}</strong><br />
                                <p className="addresses">
                                {get(fields, 'values.businessHours', '[]').map((curr) => {
                                    return <span>{curr}<br /></span>
                                })}
                            </p>
                            </p>
                        </div>
                        <div className="col-sm-12 col-lg-6">
                            <div className="row if-form">
                                <div className="col-sm-12 col-lg-6">
                                    <div className="form-group">
                                        <input
                                            onChange={handleInputChange}
                                            type="text"
                                            value={message.Contact_US.First_Name}
                                            name='First_Name'
                                            className="form-control"
                                            placeholder="First Name"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-lg-6">
                                    <div className="form-group">
                                        <input
                                            name='Last_Name'
                                            value={message.Contact_US.Last_Name}
                                            onChange={handleInputChange}
                                            type="text"
                                            className="form-control"
                                            placeholder="Last Name"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <input
                                            name='Email'
                                            value={message.Contact_US.Email}
                                            onChange={handleInputChange}
                                            type="email"
                                            className="form-control"
                                            placeholder="Email Address"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <input
                                            name='Phone'
                                            value={message.Contact_US.Phone}
                                            onChange={handleInputChange}
                                            type="text"
                                            className="form-control"
                                            placeholder="Phone Number"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <textarea
                                            value={message.Contact_US.Message}
                                            name='Message'
                                            onChange={handleInputChange}
                                            style={{resize:'none'}}
                                            type="text"
                                            className="form-control"
                                            placeholder="Message"
                                            rows={6}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-qsm-12">
                                    <div className="text-right pt-5">
                                        <button
                                            onClick={sendEmailContact}
                                            type="button"
                                            className="btn btn-raised btn-info btn-gm-blue">SEND MESSAGE
                                        </button>
                                        <div className="text-right mt-3">
                                            <p>By clicking "Send Message" I am providing my express written consent to
                                                GM Insurance Services to contact me by phone, text message, and email at
                                                the contact information provided in this form. Additionally, by clicking
                                                "Send Message" I acknowledge that I have read, understood, and agree to
                                                GM Insurance Service's <a href="/privacy-policy">Privacy
                                                    Policy</a> and <a href="/privacy-policy">Terms of Use</a>.</p>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="col-sm-12 mt-5">
                            <iframe title='iframe'
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3299.864423732805!2d-118.37188288514353!3d34.200940280565824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2944aa7324b05%3A0x6e6d97c2626fdd1d!2sGM%20Insurance%20Services!5e0!3m2!1sen!2sus!4v1607898274907!5m2!1sen!2sus"
                                    height="450" frameborder="0" style={{width: '100%'}} allowfullscreen=""
                                    aria-hidden="false" tabindex="0"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ContacUsForm;
import React from 'react'
import Completed from "../../static/images/icons/Completed.png";
import Layout from "../../layout";


export const RequestChangeThankYouPage = () => {
    return (
        <Layout full>

        <div>
            <div className="completed-items text-center">
                <img id="completedIcon" src={Completed} alt="Completed" />
            </div>

            <div className="submitted-request">
                <div className="submitted-request-content">
                    <h2 className="submitted-request-heading">
                        Thank you for requesting a change on your policy.
                    </h2>
                    <p>
                        We will contact you shortly with any price change and policy documents before binding coverage.
                    </p>
                    <p>
                        Should you need to contact us by phone please do so by calling <a href="tel:818-940-3000">(818) 940-3000</a>.
                    </p>

                </div>
                <br/>
                <br/>

            </div>

        </div>
        </Layout>

    );
}
import React from 'react';
import '../../assets/css/autoInsurance.css'
import {Divider} from "semantic-ui-react";

export const PrivacyInformation = React.memo(() => {
    return (
        <div style={{marginTop:'30px'}}>
            <Divider/>
            <div className='privacy_info_main'>
                <div className='privacy_info'>
                    By clicking "Get Quote" I am providing my express written consent to GM Insurance
                    Services to Contact me for my insurance quote by phone, text message, mail and
                    email at the contact information provided in this quote form. Additionally, by clicking
                    "Get Quote" I acknowledge that I have read, understood, and agree to GM Insurance
                    Service's Privacy Policy and Terms of Use.
                </div>
            </div>
        </div>

    )
})
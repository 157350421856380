import React, {useEffect, useState} from 'react'
import Layout from "../../../../../layout";
import {useDispatch, useSelector} from "react-redux";
import {getAutoChange, getAutoCurrentChange} from "../../../../../selectors/requestAChangeSelector";
import ContactForm from "./form";
import '../../../../../assets/css/autoInsurance.css'
import {setAutoChange} from "../../../../../slices/requestAChnage";
import {sendEmailChangeRequest} from "../../../../../services/sendEmail";
import {useHistory} from "react-router-dom";
import emailjs from "emailjs-com";

export const Remove_Replace_Driver = () => {
    const currentChangeRequest = useSelector(getAutoCurrentChange)
    const [disc, setDisc] = useState(false)
    const [discError, setDiscError] = useState(false)
    const [addNewDriver, setAddNewDriver] = useState(false)
    const dispatch = useDispatch()
    const [isFormSubmited, setIsFormSubmited] = useState(false)
    const main = useSelector(getAutoChange)
    const history = useHistory()
    const handleSubmit = (values) => {
        if (!discError){
            setDiscError(true)
        }else {
            dispatch(setAutoChange({
                name: currentChangeRequest,
                value:values.validate[0]
            }))
            setIsFormSubmited(true)
        }
    }
    useEffect(async () => {
        if (main){
            await emailjs.send("service_1d1ggng", "template_s4rfq4p", {
                subject: 'Remove/Replace Driver',
                firstName: main.MainInfo.firstName,
                lastName: main.MainInfo.lastName,
                phone: main.MainInfo.phone_number,
                policy: main.MainInfo.policy_number,
                email: main.MainInfo.apply_email,
                firstRemovedDriverName: main['Remove/Replace a Driver'].firstName ? main['Remove/Replace a Driver'].firstName : '',
                lastRemovedDriverName: main['Remove/Replace a Driver'].lastName ? main['Remove/Replace a Driver'].lastName : '',
                removed_driver_last_name: main['Remove/Replace a Driver'].date_of_birth ? main['Remove/Replace a Driver'].date_of_birth : '',
                firstDriverName: main['Remove/Replace a Driver'].new_driver_firstName ? main['Remove/Replace a Driver'].new_driver_firstName : '',
                lastDriverName: main['Remove/Replace a Driver'].new_driver_lastName ? main['Remove/Replace a Driver'].new_driver_lastName : '',
                gender: main['Remove/Replace a Driver'].gender ? main['Remove/Replace a Driver'].gender : '',
                date_of_birth: main['Remove/Replace a Driver'].new_driver_date_of_birth ? main['Remove/Replace a Driver'].new_driver_date_of_birth : '',
                driver_license_number: main['Remove/Replace a Driver'].new_driver_license_number ? main['Remove/Replace a Driver'].new_driver_license_number : '',
                state: main['Remove/Replace a Driver'].driver_state ? main['Remove/Replace a Driver'].driver_state : '',
                relationship_to_insured: main['Remove/Replace a Driver'].driver_relationship_to_insured ? main['Remove/Replace a Driver'].driver_relationship_to_insured : '',
                marital_status: main['Remove/Replace a Driver'].marital_status ? main['Remove/Replace a Driver'].marital_status : '',
                message_to_GM: main['Remove/Replace a Driver'].message_to_GM ? main['Remove/Replace a Driver'].message_to_GM : '',
                request_effective_date: main['Remove/Replace a Driver'].request_effective_date ? main['Remove/Replace a Driver'].request_effective_date : '',
            }, 'user_iuz4PywP4uJvUMl4sLkyV');
        }
        isFormSubmited && history.push(`/request-change-thank-you`)
    },[isFormSubmited])
    return (
        <Layout full>
            <div className="form-bg">
                <div className="container w-100">
                    <h1 className="text-center pt-5 pb-2">{currentChangeRequest}</h1>
                    <ContactForm setAddNewDriver={setAddNewDriver} addNewDriver={addNewDriver} discError={discError} disc={disc} setDisc={setDisc} onSubmit={handleSubmit}/>
                </div>
            </div>
        </Layout>
    )
}
import React, {useMemo} from "react";

import Layout from "../../layout";
import Hero from "../../components/Hero";
import {useContentFull} from '../../hooks/useContentFull'
import { PageLoader } from "../../components/PageLoader/PageLoader";
import { getImageFromContentFull } from "../../utils/contentful";
import get from "lodash.get";
const RoadsideAssistance = React.lazy(() => import('../../components/StaticPages/RoadsideAssistance'));

export default function RoadsideAssistancePage() {
    const content = {
        'bg_img': 'staic_pages_landing roadside-bg-image',
        'text': 'Roadside ',
        'green_text': 'Assistance',
        'text2': '.',
        'description1': true,
        'NoNeedForm': true
    }

    const {fields, loading, pictureTitle} = useContentFull()
    const currentHeroImage = useMemo(() => {
        return getImageFromContentFull(fields.background)
    },[fields])

    if(loading || !currentHeroImage){
        return (
            <PageLoader />
        )
    }
    return (
        <Layout full>
             <title>{get(fields, 'pageTitle', '')}</title>
            <Hero pictureTitle={pictureTitle} description={get(fields, 'subtitle','')}  image={currentHeroImage} content={content}/>
            <RoadsideAssistance fields={fields} />
        </Layout>
    );
}
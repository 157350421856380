import LogoWhite from "../../static/images/logo-white.png";
import {Link} from "react-router-dom";

export default function Footer() {
    return (
        <>
            <div className="footer">
                <div id="one" className="column">
                    <Link to="/">
                        <img id="logo-footer" src={LogoWhite} alt="" />
                    </Link>    
                        <p>877.333.8303</p>
                </div>
                <div id="two" className="column">
                    <strong className="strong footer-links-headin">QUOTE</strong>
                    <ul id="links-footer" name="quote-list">
                        <Link to="/auto-insurance-quote"><li>Auto Insurances</li></Link>
                        <Link to="/commercial-auto-insurance"><li>Commercial Auto Insurances</li></Link>
                        <Link to="/dui-Insurance"><li>DUI Insurances</li></Link>
                        <Link to="/home-owner-insurance"><li>Home Owner Insurances</li></Link>
                        <Link to="/motorcycle-insurance"><li>Motorcycle Insurances</li></Link>
                        <Link to="/non-owner-insurance"><li>Non-Owner Insurances</li></Link>
                        <Link to="/sr-22-Insurance"><li>SR-22 Insurances</li></Link>
                    </ul>
                </div>
                <div id="three" className="column">
                    <strong className="strong footer-links-headin">CLIENT SERVICES</strong>
                    <ul id="links-footer" name="client-services">
                        <Link to="/make-payment-online"><li>Make a Payment</li></Link>
                        <Link to="/roadside-assistance"><li>Road Side Assistance</li></Link>
                        <Link to="/request-change"><li>Request a Change</li></Link>
                    </ul>
                </div>

                <div id="four" className="column">
                    <strong className="strong footer-links-headin">About us</strong>
                    <ul id="links-footer" name="about-us">
                        <Link to="/about-us">
                            <li>About Us</li>
                        </Link>
                        <Link to="/career">
                            <li>Careers</li>
                        </Link>
                        <Link to="/contact-us">
                            <li>Contact Us</li>
                        </Link>
                        <Link to="/privacy-policy">
                            <li>Privacy Policy</li>
                        </Link>
                        <Link to="/customer-reviews">
                            <li>Customer Reviews</li>
                        </Link>
                        <Link to="/website-accessibility">
                            <li>Website Accessibility</li>
                        </Link>
                    </ul>
                </div>
            </div>
            <div className="copyright">
                <p>Copyright © 2007 - 2024 GM Insurance Services. All Rights Reserved. License No 0H00443
                </p>
            </div>
        </>
        );
}
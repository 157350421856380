import Layout from "../../layout";
import FormComponent from "../../components/Forms/AutoInsurance";
import { useState } from "react";

export default function DUI_Insurance() {
    const [width, setWidth] = useState("50");
    return (
        <Layout width={width} active full>
            <FormComponent insuranceType="DUI Insurance" getWidth={setWidth} />
        </Layout>
    );
}
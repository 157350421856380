import Layout from "../../layout";
import Hero from "../../components/Hero";
import NonOwnerInsurance  from "../../components/StaticPages/NonOwnerInsurance";
import { useContentFull } from "../../hooks/useContentFull";
import { useMemo } from "react";
import { getImageFromContentFull } from "../../utils/contentful";
import { PageLoader } from "../../components/PageLoader/PageLoader";
import get from "lodash.get";
export default function NonOwnerInsurancePage() {
    const content = {
        'bg_img': 'staic_pages_landing nonowner-isurance-bg-image',
        'text': 'Non-Owner',
        'green_text': 'Insurances' ,
        'text2': 'Quotes',
        'description2': 'If you have any questions regarding non-owner car insurance, please feel free to contact us by calling 877-333-8303',
        link: "/non-owner-insurance-form",
        'paraColor': 'white',
    }

    const {fields, loading, pictureTitle} = useContentFull()
    const currentHeroImage = useMemo(() => {
        return getImageFromContentFull(fields.background)
    },[fields])

    const subtitle = useMemo(() => {
        return get(fields,'subtitle','a')
    },[fields])


    if(loading || !currentHeroImage){
        return (
            <PageLoader />
        )
    }

    return (
        <Layout full>
            <title>{get(fields, 'pageTitle', '')}</title>
            <Hero  pictureTitle={pictureTitle} subtitle={subtitle} image={currentHeroImage} content={content}/>
            <NonOwnerInsurance fields={fields} />
        </Layout>
    );
}
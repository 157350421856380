import Layout from "../../layout";
import HomeOwnerInsurance  from "../../components/Forms/HomeOwnerInsurance";
import { useState } from "react";

export default function HomeOnwerInsurance_Page() {
    const [width, setWidth] = useState("50");
    return (
        <Layout width={width} active full>
            <HomeOwnerInsurance getWidth={setWidth} />
        </Layout>
    );
}
import React, {useEffect} from "react";
import {useHistory, useLocation} from 'react-router-dom'
import ContactForm from "./authForm";
import {useDispatch} from "react-redux";
import {setMainFirstStep} from "../../../slices/requestAChnage";

const MainStep = React.memo(({nextStep}) => {
    const location = useLocation()
    const dispatch = useDispatch()
    const currentLocation = location.pathname

    const next = () => {
        nextStep()
    }
    const handleSubmit = (values) => {
        next()
        dispatch(setMainFirstStep(values.validate[0]))
    }


    return (
                <div className="form-bg">
                    <div className="container w-100">
                        <h1 className="text-center pt-5 pb-2">Policyholders Information</h1>
                        <ContactForm currentLocation={currentLocation} onSubmit={handleSubmit}/>
                        <div className="text-left mt-3">
                            <p>By clicking "Next" I am providing my express written consent to
                                GM Insurance Services to contact me by phone, text message, and email at
                                the contact information provided in this form. Additionally, by clicking
                                "Send Message" I acknowledge that I have read, understood, and agree to
                                GM Insurance Service's <a href="/privacy-policy">Privacy
                                    Policy</a> and <a href="/privacy-policy">Terms of Use</a>.</p>
                        </div>
                    </div>
                </div>

    )
})

export default MainStep
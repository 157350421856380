import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    commercialFinalForm: [],
}


export const commercialInsuranceSlice = createSlice({
    name: "commercialInsurance",
    initialState: initialState,
    reducers: {
        setFinalForm(state,action){
            return {
                ...state,
                commercialFinalForm: action.payload
            }
        }
    }
})

export const {setFinalForm} = commercialInsuranceSlice.actions

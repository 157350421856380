import get from 'lodash.get'
function FirstComponent({fields}) {
    return (
        <div className="offers">
            <div className="offer-content">
                <h2 className="offer-heading">{get(fields, 'title', '')}</h2>
                    <p className="text-center">{get(fields, 'text', '')}</p>
            </div>
        </div>
    );
}

export default FirstComponent;
import React, { useMemo } from 'react'
import {Link} from "react-router-dom";

export default function CompareInsuranceCompenent  ({text, buttonText}) {

    const big_text = {
        fontFamily: "Work Sans",
        fontStyle: 'bold',
        fontSize: '34px',
        fontWeight: '700',
        lineHeight: '50px',
        color: "black",
//        lineHeight: '125%'
    }

    const currentText = useMemo(() => {
        if(!!text){
            return text
        }
        return 'Compare car insurance quotes for free online today!'
    },[text])

    const currentButtonText = useMemo(() => {
        if(!!buttonText){
            return buttonText
        }
        return 'Start Saving'
    },[buttonText])

    return (
        <div className="jumbotron">
        <div className="row px-md-5">
            <div className="col-lg-6">
                <h1 style={big_text}>
                    {currentText}
                </h1>
            </div>
            <div className="col-lg-3">
                    <div className="input-icon mt-3">
                    <svg width="14" id="loc-icon" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 0.0244141C3.13 0.0244141 0 3.024 0 6.73275C0 11.764 7 19.1911 7 19.1911C7 19.1911 14 11.764 14 6.73275C14 3.024 10.87 0.0244141 7 0.0244141ZM7 9.12858C5.62 9.12858 4.5 8.05525 4.5 6.73275C4.5 5.41025 5.62 4.33691 7 4.33691C8.38 4.33691 9.5 5.41025 9.5 6.73275C9.5 8.05525 8.38 9.12858 7 9.12858Z" fill="#7D828A"/>
                    </svg>
                        <input className="m-input" label="Enter Zip Code"  placeholder="Enter Zip Code" type="text" />
                    </div>
            </div>
            <div className="col-lg-3">
                <Link to="/auto-insurance-form" className="compare_insurance_btn_link">
                    <button className="m-btn btn-block mt-3 compare_insurance_btn">{currentButtonText}</button>
                </Link>
            </div>
        </div>
    </div>
    );

}
import React, {useEffect} from 'react';
import {Field, FieldArray, reduxForm} from 'redux-form';
import '../../../../src/assets/css/autoInsurance.css'
import {
     personal_property,
    renter_liability_limit,
    states,
} from "../../../static/select-options/driver-select-options";
import Button from "@material-ui/core/Button";
import {
    renderField,
    renderFieldSelect,
    renderFieldSelectRequire, renderFieldZipCode
} from "../../utils/driver-redux-form";
import passValidation from "../../utils/validate";
import {useSelector} from "react-redux";
import {getZipCode} from "../../../selectors/autoInsuranceSelector";


const renderMembers = React.memo(({fields, meta: {touched, error, submitFailed}, location, zipCodeValue}) => {
    return (
        <div>
            {fields.map((validate, index) => (
                <ul key={index}>
                    <li  className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.firstName`}
                            type="text"
                            component={renderField}
                            label="First Name"
                        />
                        <Field
                            name={`${validate}.lastName`}
                            type="text"
                            component={renderField}
                            label="Last Name"
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.property_address`}
                            type="text"
                            component={renderField}
                            label="Property Address"
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.city`}
                            type="text"
                            component={renderField}
                            label="City"
                        />
                        <Field
                            name={`${validate}.state`}
                            component={renderFieldSelectRequire}
                            label="State"
                            options={states}
                        />
                        <Field
                            name={`${validate}.zipCode`}
                            type="number"
                            component={zipCodeValue ? renderFieldZipCode : renderField}
                            label="Zip Code"
                            zipCodeValue={zipCodeValue}
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.personal_contact`}
                            component={renderField}
                            type="number"
                            label="Phone Number"
                        />
                        <Field
                            name={`${validate}.renter_email`}
                            component={renderField}
                            type="email"
                            label="Email Address"
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.personal_property`}
                            component={renderFieldSelect}
                            label="PERSONAL PROPERTY"
                            options={personal_property}
                        />
                        <Field
                            name={`${validate}.liability_limit`}
                            component={renderFieldSelect}
                            label="LIABILITY LIMIT"
                            options={renter_liability_limit}
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <div className='haveViolation'>
                            <span className='violation'>Earthquake Coverage?</span>
                            <label style={{marginRight:'10px',marginLeft:'10px'}}>
                                <Field name={`${validate}.earthquake_coverage`} component="input" type="radio" value="Yes" />{' '}
                                Yes
                            </label>
                            <label style={{marginRight:'10px'}}>
                                <Field  name={`${validate}.earthquake_coverage`} component="input" type="radio" value="No" />{' '}
                                No
                            </label>
                        </div>
                    </li>
                </ul>
            ))}
            <div className={'addDriver'}>
                <button style={{display: 'none'}} className='addButton' id='defaultValues' type="button"
                        onClick={() => fields.push({})}/>
            </div>
        </div>
    )
})

const FieldArraysForm = props => {
    const zipCodeValue = useSelector(getZipCode)
    useEffect(() => {
        const defaultClick = document.getElementById('defaultValues')
        defaultClick.click()
    }, [])

    const {handleSubmit, submitting} = props;
    return (
        <form onSubmit={handleSubmit} style={{marginTop: '25px'}}>
            <FieldArray name="validate" zipCodeValue={zipCodeValue} location={props} component={renderMembers}/>
            <div className='request'>
                <Button type='submit' disabled={submitting} style={{marginTop: '15px'}} variant="contained"
                        color="primary">
                    REQUEST A QUOTE
                </Button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'fieldArrays',
    validate: passValidation,
})(FieldArraysForm);

import React, {useEffect, useState} from 'react'
import Layout from "../../../../layout";
import '../../../../assets/css/autoInsurance.css'
import {useDispatch, useSelector} from "react-redux";
import {setAutoChange} from "../../../../slices/requestAChnage";
import {getAutoChange} from "../../../../selectors/requestAChangeSelector";
import { sendEmailChangeRequest} from "../../../../services/sendEmail";
import {useHistory} from "react-router-dom";
import ContactForm from "./textArea";
import emailjs from "emailjs-com";

export const DocumentRequest = () => {
    const [isChecked,setIsChecked] = useState([])
    const initialDatas = ['Insurance ID Card','Declaration Page','SR-22 Form']
    const [formIsReady, setFormIsReady] = useState(false)
    const [error,setError] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()
    const main = useSelector(getAutoChange)

    const setChecked = (e) => {
        setError(false)
        if (isChecked.includes(Number(e.currentTarget.dataset.current))){
            const filteredItem = isChecked.filter((el) => el !== Number(e.currentTarget.dataset.current))
            setIsChecked(filteredItem)
        }else {
            setIsChecked([...isChecked,Number(e.currentTarget.dataset.current)])
        }
    }
    const requestChangeQuote = (val) => {
        const current= isChecked.map((el,index) => initialDatas[el])
        if (isChecked.length){
            dispatch(setAutoChange({
                name:'Policy Document Request',
                value: {data:current,text:val.validate[0].text_message}
            }))
            setFormIsReady(true)
        }else {
            setError(true)
        }
    }

    useEffect(async () => {
        if (main){
            await emailjs.send("service_1d1ggng", "template_6nsys8g", {
                subject: main.RequestType,
                current: main.RequestSubType,
                firstName: main.MainInfo.firstName,
                lastName: main.MainInfo.lastName,
                phone: main.MainInfo.phone_number,
                policy: main.MainInfo.policy_number,
                email: main.MainInfo.apply_email,
                what_we_should_email:main['Policy Document Request'].data && main['Policy Document Request'].data.map((elem) => `${elem},`),
                message:main['Policy Document Request'].text && main['Policy Document Request'].text,
            }, 'user_iuz4PywP4uJvUMl4sLkyV');
        }
        formIsReady && history.push(`/request-change-thank-you`)
    },[formIsReady])

    return (
        <Layout full>
            <div className="form-bg">
                <div className="container w-100">
                    <h1 className="text-center pt-5 pb-2">Please tell us what we should e-mail</h1>
                    {initialDatas.map((el,index) => {
                        return (
                            <div key={index} className='checkboxMainDiv'>
                                <input onChange={setChecked} checked={isChecked.includes(index)} data-current={index} className='disclaimorSubmit' type="checkbox" name="cb" id={`cb${index}`}/>
                                <label className='disclaimorLabel labelWidth' htmlFor={`cb${index}`}>
                                    <p className='documentRequest'>{el}</p>
                                </label>
                            </div>
                        )
                    })}
                    </div>
                <ContactForm onSubmit={requestChangeQuote}/>
                {error &&
                <div style={{display:'flex',justifyContent:'center',marginTop:'15px'}}>
                    <p style={{color:'red'}}>Pleas Check Policy Document Request</p>
                </div>
                }
            </div>
        </Layout>

    )
}
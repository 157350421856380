import React, {useEffect, useState} from 'react';
import {Field, FieldArray, reduxForm} from 'redux-form';
import '../../../../src/assets/css/autoInsurance.css'
import {
     position_of_interest,
} from "../../../static/select-options/driver-select-options";
import Button from "@material-ui/core/Button";
import {
    renderField,
    renderFieldSelectRequire
} from "../../utils/driver-redux-form";
import passValidation from "../../utils/validate";
import FileUploader from "../Career/FileUpload";
import {Divider} from "semantic-ui-react";


const renderMembers = ({fields, meta: {touched, error, submitFailed},licensed,handleLicensed, zipCodeValue}) => {
    return (
        <div>
            {fields.map((validate, index) => (
                <ul key={index}>
                    <li  className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.firstName`}
                            type="text"
                            component={renderField}
                            label="First Name"
                        />
                        <Field
                            name={`${validate}.lastName`}
                            type="text"
                            component={renderField}
                            label="Last Name"
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.personal_contact`}
                            component={renderField}
                            type="number"
                            label="Phone Number"
                        />
                        <Field
                            name={`${validate}.apply_email`}
                            component={renderField}
                            type="email"
                            label="Email Address"
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.position_of_interest`}
                            component={renderFieldSelectRequire}
                            label="POSITION OF INTEREST"
                            options={position_of_interest}
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <div className='haveViolation'>
                            <span className='violation'>Are you licensed to sell Insurance in the State of California?</span>
                            <label style={{marginRight:'10px',marginLeft:'10px'}}>
                                <Field name={`${validate}.licensed_to_sell`} onChange={() => handleLicensed(true)} component="input" type="radio" value="Yes" />{' '}
                                Yes
                            </label>
                            <label style={{marginRight:'10px'}}>
                                <Field  name={`${validate}.licensed_to_sell`} onChange={() => handleLicensed(false)} checked={!licensed} component="input" type="radio" value="No" />{' '}
                                No
                            </label>
                        </div>
                    </li>
                    {licensed &&
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.licensed`}
                            type="text"
                            component={renderField}
                            label="Departament of Insurance License number"
                        />
                    </li>}
                </ul>
            ))}
            <div className={'addDriver'}>
                <button style={{display: 'none'}} className='addButton' id='defaultValues' type="button"
                        onClick={() => fields.push({})}/>
            </div>
            <FileUploader/>
        </div>
    )
}

const FieldArraysForm = props => {
    useEffect(() => {
        const defaultClick = document.getElementById('defaultValues')
        defaultClick.click()
    }, [])
    const [licensed,setLicensed] = useState(false);
    const handleLicensed = (data) => {
        setLicensed(data)
    }

    const {handleSubmit, submitting} = props;
    return (
        <form onSubmit={handleSubmit} style={{marginTop: '25px'}}>
            <FieldArray name="validate" licensed={licensed} handleLicensed={handleLicensed}  location={props} component={renderMembers}/>
            <Divider />

            <div className='request'>
                <Button type='submit' disabled={submitting} style={{marginTop: '15px'}} variant="contained"
                        color="primary">
                    SUBMIT
                 </Button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'fieldArrays',
    validate: passValidation,
})(FieldArraysForm);

import get from "lodash.get";

export const getCurrentContentFullId = (pathname) => {
  let currentPath = ''
  switch (pathname) {
    case "/":
      currentPath = '1KbScIHyi2qfIvN2SNXsVw'
    break
    case "/auto-insurance-quote":
      currentPath = '1pGvKQ8ydbNqw5gIs7JeOz'
    break  
    case "/home-owner-insurance":
      currentPath = '2g8DP8YA4sVBN7qHyHQfaQ'
    break  
    case "/motorcycle-insurance":
      currentPath = '50EV2e2WU0cxABAMI2B7gC'
    break  
    case "/non-owner-insurance":
      currentPath = '1TqXJSBMNjdMRM6NTqnCS1'
    break  
    case "/apartment-renter-insurance":
      currentPath = '2ITuUjdVs9uPm5ZfqJFO1A'
    break  
    case "/dui-Insurance":
      currentPath = '633kxxFB2anqRE8U0bITmV'
    break  
    case "/sr-22-Insurance":
      currentPath = '4KBzoyExes4CJklTweJDWq'
    break  
    case "/commercial-auto-insurance":
      currentPath = '16C0aF2ydWKAhRMiAeAsh7'
    break  
    case "/privacy-policy":
      currentPath = '6rrQrTr9ddjNilQCyyBsb0'
    break  
    case "/contact-us":
      currentPath = '1pEjvdGl9NyGH1dKXAuejm'
    break  
    case "/career":
      currentPath = '3TsWHOdwpt8Nol3GhXd12l'
    break  
    case "/about-us":
      currentPath = '6Iky7Yju3QFl8Te3NMKnV5'
    break  
    case "/make-payment-online":
      currentPath = 'lIUqCHKoUFVfok0vnldme'
    break  
    case "/claims":
      currentPath = 'Z15sJm3RLQXjM2uibd3rk'
    break  
    case "/roadside-assistance":
      currentPath = 'j8nrwgukEH8TJYGlBSvOR'
    break  
    case "/autoinsurancenorthhollywood":
      currentPath = '6SM88uaCygzEyknMTgFeng'
    break  
    case "/customer-reviews":
      currentPath = '6hdDSTczhfGsZ5WMfbeG2H'
    break  
    default:
      currentPath = '';
  }
  return currentPath
}



export const getImageFromContentFull = (filed) => {
  return get(filed, 'fields.file.url', '')
}
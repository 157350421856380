import React, { useMemo } from "react";

import Layout from "../../layout";
import Hero from "../../components/Hero";
import { useContentFull } from "../../hooks/useContentFull";
import { getImageFromContentFull } from "../../utils/contentful";
import { PageLoader } from "../../components/PageLoader/PageLoader";
const SR22InsurancePageBody = React.lazy(() => import('../../components/StaticPages/SR_22_InsurancePageBody'));

export default function SR_22_Insurance() {
    const content = {
        'bg_img': 'staic_pages_landing sr-22-insurance-bg-image',
        'text': 'SR-22 Auto ',
        'green_text': 'Insurances',
        'text2': 'Quotes',
        link: "/sr22-insurance-form",
    }
    const {fields, loading, pictureTitle} = useContentFull()
    const currentHeroImage = useMemo(() => {
        return getImageFromContentFull(fields.background)
    },[fields])

    if(loading || !currentHeroImage){
        return (
            <PageLoader />
        )
    }
    return (
        <Layout full>
            <Hero pictureTitle={pictureTitle} image={currentHeroImage} content={content}/>
            <SR22InsurancePageBody fields={fields} />
        </Layout>
    );
}
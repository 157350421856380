import {createSelector} from "reselect";

const homeOwnerInsurance = (state) => {return state.homeowner}

export const getHomeOwnerStep1Passing = createSelector(homeOwnerInsurance, state => state.ownerHomeConfirm)

export const getHomeFinalInfo = createSelector(homeOwnerInsurance, state => state.homeOwnerFormFinalData)











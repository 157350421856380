import HomeIcon from "../../../static/images/icons/HomeOwnerInsuranceSmallIcon.png";
import CarIcon from "../../../static/images/icons/AutoInsuranceQuoteSmallIcon.png";
import DrivingIcon from "../../../static/images/icons/DrivingUnderInfluence.png";
import CommercialIcon from "../../../static/images/icons/CommercialAutoInsuranceSmallIcon.png";
import SRIcon from "../../../static/images/icons/SR-22Insurance.png";
import NonOwnerIcon from "../../../static/images/icons/Non-OwnerInsurance.png";
import MotorCycleIcon from "../../../static/images/icons/MotorCycleInsurence.png";
import RenterIcon from "../../../static/images/icons/RentersInsurance.png";
import { Link } from "react-router-dom";

function SecondComponent() {
    return (
        <div className="offer-items">
            <div className="item-box">
                <div className="icon-box">
                    <img id="imgForIcon" src={CarIcon} alt="Auto Icon" />
                </div>
                <div className="offer-data">
                    <h3 className="offer-data-heading">
                        <div>
                            <Link to="/auto-insurance-quote">Auto</Link>
                        </div>
                    </h3>
                </div>
            </div>
            <div className="item-box">
                <div className="icon-box">
                    <img id="imgForIcon" src={HomeIcon} alt="HomeIcon" />
                </div>
                <div className="offer-data">
                    <h3 className="offer-data-heading">
                        <div>
                            <Link to="/home-owner-insurance">Home</Link>
                        </div>
                    </h3>
                </div>
            </div>
            <div className="item-box">
                <div className="icon-box">
                    <img id="imgForIcon" src={DrivingIcon} alt="Driving Icon" />
                </div>
                <div className="offer-data">
                    <h3 className="offer-data-heading">
                        <div>
                            <Link to="/dui-insurance">DUI</Link>
                        </div>
                    </h3>
                </div>
            </div>
            <div className="item-box">
                <div className="icon-box">
                    <img id="imgForIcon" src={CommercialIcon} alt="Commercial Icon" />
                </div>
                <div className="offer-data">
                    <h3 className="offer-data-heading">
                        <div>
                            <Link to="/commercial-auto-insurance">Commercial</Link>
                        </div>
                    </h3>
                </div>
            </div>
            <div className="item-box">
                <div className="icon-box">
                    <img id="imgForIcon" src={SRIcon} alt="SR Icon" />
                </div>
                <div className="offer-data">
                    <h3 className="offer-data-heading">
                        <Link to="/sr-22-insurance">SR-22</Link>
                    </h3>
                </div>
            </div>
            <div className="item-box">
                <div className="icon-box">
                    <img id="imgForIcon1" src={NonOwnerIcon} alt="Non Owner Icon" />
                </div>
                <div className="offer-data">
                    <h3 className="offer-data-heading">
                        <Link to="/non-owner-insurance">Non-Owner</Link>
                    </h3>
                </div>
            </div>
            <div className="item-box">
                <div className="icon-box">
                    <img id="imgForIcon" src={MotorCycleIcon} alt="Motor cycle Icon" />
                </div>
                <div className="offer-data">
                    <h3 className="offer-data-heading">
                        <Link to="/motorcycle-insurance">Motorcycle</Link>
                    </h3>
                </div>
            </div>
            <div className="item-box">
                <div className="icon-box">
                    <img id="imgForIcon" src={RenterIcon} alt="Renter Icon" />
                </div>
                <div className="offer-data">
                    <h3 className="offer-data-heading">
                        <Link to="/apartment-renter-insurance">Renter’s</Link>
                    </h3>
                </div>
            </div>
        </div>
    );
}

export default SecondComponent;
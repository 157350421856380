import React from 'react'
import Layout from "../../../../layout";
import StepWizard from "react-step-wizard";
import {CurrentInsuranceRequest} from "./driverChnageType";



export const DriverChange = () => {
    return (
        <Layout full>
            <div className="App">
                <StepWizard>
                    <CurrentInsuranceRequest step={1} />
                </StepWizard>
            </div>
        </Layout>
    );
}
import React, {useEffect, useState} from 'react';
import {Field, FieldArray, reduxForm} from 'redux-form';
import '../../../../src/assets/css/autoInsurance.css'
import {
    states,
} from "../../../static/select-options/driver-select-options";
import Button from "@material-ui/core/Button";
import {
    renderField, renderFieldNotRequire,
    renderFieldSelect, renderFieldZipCode,
} from "../../utils/driver-redux-form";
import passValidation from "../../utils/validate";
import {useSelector} from "react-redux";
import {getZipCode} from "../../../selectors/autoInsuranceSelector";


const renderMembers = React.memo(({fields, meta: {touched, error, submitFailed},location, zipCodeValue, violation, handleViolation}) =>{
    return (
        <div>
            {fields.map((validate, index) => (
                <ul key={index}>
                    <li  className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.firstName`}
                            type="text"
                            component={renderField}
                            label="First Name"
                        />
                        <Field
                            name={`${validate}.lastName`}
                            type="text"
                            component={renderField}
                            label="Last Name"
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.property_address`}
                            type="text"
                            component={renderField}
                            label="Garaging Address"
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.city`}
                            type="text"
                            component={renderField}
                            label="City"
                        />
                        <Field
                            name={`${validate}.state`}
                            component={renderFieldSelect}
                            label="State"
                            options={states}
                        />
                        <Field
                            name={`${validate}.zipCode`}
                            type="number"
                            component={zipCodeValue ? renderFieldZipCode : renderField}
                            label="Zip Code"
                            zipCodeValue={zipCodeValue}
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <Field
                            name={`${validate}.personal_contact`}
                            component={renderField}
                            type="number"
                            label="Phone Number"
                        />
                        <Field
                            name={`${validate}.email_address`}
                            type="email"
                            component={renderFieldNotRequire}
                            label=" E-Mail Address"
                        />
                    </li>
                    <li className='reduxFromLiMoto'>
                        <div className='haveViolation'>
                            <span
                                className='violation'>Different Mailing Address ? </span>
                            <label style={{marginRight: '10px'}}>
                                <Field onChange={() => handleViolation(true)} name={`${validate}.different_email_checked`}
                                       component="input" type="radio" value="Yes"/>{' '}
                                Yes
                            </label>
                            <label style={{marginRight: '10px'}}>
                                <Field onChange={() => handleViolation(false)} name={`${validate}.different_email_checked`}
                                       checked={!violation} component="input" type="radio" value="No"/>{' '}
                                No
                            </label>
                        </div>
                    </li>
                    {violation &&
                        <>
                            <li className='reduxFromLiMoto'>
                                <Field
                                    name={`${validate}.different_address`}
                                    type="text"
                                    component={renderFieldNotRequire}
                                    label="Mailing Address"
                                />
                            </li>
                            <li className='reduxFromLiMoto'>
                                <Field
                                    name={`${validate}.different_city`}
                                    type="text"
                                    component={renderField}
                                    label="City"
                                />
                                <Field
                                    name={`${validate}.different_state`}
                                    component={renderFieldSelect}
                                    label="State"
                                    options={states}
                                />
                                <Field
                                    name={`${validate}.different_zip`}
                                    type="number"
                                    component={zipCodeValue ? renderFieldZipCode : renderField}
                                    label="Zip Code"
                                    zipCodeValue={zipCodeValue}
                                />
                            </li>
                        </>
                    }
                </ul>
            ))}
            <div className={'addDriver'}>
                <button style={{display:'none'}} className='addButton' id='defaultValues' type="button" onClick={() => fields.push({})}/>
            </div>
        </div>
    )
})

const FieldArraysForm = props => {
    const zipCodeValue = useSelector(getZipCode)

    useEffect(() => {
        const defaultClick = document.getElementById('defaultValues')
        defaultClick.click()
    },[])
    const [violation, setViolation] = useState(false)
    const handleViolation = (data) => {
        setViolation(data)
    }
    const {handleSubmit, submitting} = props;
    return (
        <form onSubmit={handleSubmit} style={{marginTop:'25px'}}>
            <FieldArray zipCodeValue={zipCodeValue} violation={violation} handleViolation={handleViolation} name="validate" location={props} component={renderMembers}/>
            <div className='request'>
                <Button type='submit'  disabled={submitting} style={{marginTop:'15px'}} variant="contained" color="primary" >
                    REQUEST A QUOTE
                </Button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'fieldArrays',
    validate: passValidation,
})(FieldArraysForm);

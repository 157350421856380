import React, {useEffect, useState} from "react";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import {useRouteMatch} from 'react-router-dom'
import ThankYouPage from "./../../ThankYouPage"
import ContactForm from "./reduxformMoto";
import {useDispatch, useSelector} from "react-redux";
import {setFinalMotoForm} from "../../../slices/motorcycleInsuranceSlice";
import {getFinalForm} from "../../../selectors/motorcycleSelector";
import {sendEmailQuotes, sendMotorcycleInsurance} from "../../../services/sendEmail";
import {PrivacyInformation} from "../../PrivicyPolicyUnderForm";
import {MotoFormCurrent} from "../../utils/currentEmailInfo";
import emailjs from "emailjs-com";

function Main() {
    const location = useLocation()
    const dispatch = useDispatch()
    const history = useHistory()
    const zipCode = useSelector(state => state.autoInsurance.zipCode)
    const [motoEmail,setMotoEmail] = useState(false)
    const finalMotoForm = useSelector(getFinalForm)
    const { path} = useRouteMatch()

    const handleSubmit  = async (values) => {
        await dispatch(setFinalMotoForm(values))
        setMotoEmail(true)
    }
    const currentForm = finalMotoForm && MotoFormCurrent(finalMotoForm)
    useEffect(async () => {
        if (motoEmail){

            const params = {
                to_name: "ADMIN",
                subject: 'Motorcycle',
                motoInfo: currentForm.moto_vin_make_model,
                firstName:currentForm.firstName,
                lastName:currentForm.lastName,
                email:currentForm.email,
                city:currentForm.city,
                contact:currentForm.contact,
                date_of_birth:currentForm.date_of_birth,
                driver_license_number:currentForm.driver_license_number,
                gender:currentForm.gender,
                homeAddress:currentForm.homeAddress,
                marital_status:currentForm.marital_status,
                violation_state:currentForm.violation_state,
                relation_to_insured:currentForm.relation_to_insured,
                state:currentForm.state,
                zipCode: zipCode ? zipCode : currentForm.zipCode,
            }
            await sendMotorcycleInsurance(params)
            history.push(`${path}/thank-you`)}
    },[motoEmail])
    const currentLocation = location.pathname


    return (
        <Switch>
            <Route exact path={path} >
                <div className="form-bg">
                    <div className="container w-100">
                        <h1 className="text-center pt-5 pb-2">Motorcyclist Insurance</h1>
                        <ContactForm currentLocation={currentLocation} onSubmit={handleSubmit}/>
                        <PrivacyInformation/>
                    </div>
                </div>
            </Route>
            <Route  path={`${path}/thank-you`}>
                <ThankYouPage/>
            </Route>
        </Switch>
    )
}

export default Main;




import React from 'react';
import {Link, useHistory, useLocation} from "react-router-dom";
import { isMobile } from "react-device-detect";
import Logo from "../../static/images/logo.png";
import SimpleLogo from "../../static/images/simple.png";
import {useDispatch} from "react-redux";
import {setAutoRequestType} from "../../slices/requestAChnage";

const Nav = React.memo((props) => {
  const preventDefault = (event) => event.preventDefault();
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const progressBarWidth = () => {
    if (location.pathname === '/auto-insurance-form/thank-you'){
      return 100
    }else {
      return width
    }
  }
  let active = false;
  if(props.active) {
    active = props.active;
  }
  let width = "50";
  if(props.width) {
      width = props.width;
  }
  const goHome = () => {
    history.push('/')
    window.location.reload()
  }
  const requestChange = () => {
    dispatch(setAutoRequestType(''))
    history.push('request-change')
  }
  if(props.full) {
    return (
      <>
      <nav id="head" className="navbar navbar-expand-lg navbar-light bg-light">
        <div style={{backgroundImage: `url(${Logo})`}} className="newBkg" onClick={goHome}/>

        {/*<img className="logoMain" style={{cursor:'pointer'}} id="logo-head" onClick={goHome} src={Logo} alt="GM Logo" />*/}

      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#!"
              onClick={preventDefault}
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Compare Rates
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link className="dropdown-item" to="/auto-insurance-quote">
                Auto Insurance
              </Link>
              <Link className="dropdown-item" to="/commercial-auto-insurance">
                Commercial Auto Insurance
              </Link>
              <Link className="dropdown-item" to="/dui-Insurance">
                DUI Insurance
              </Link>
              <Link className="dropdown-item" to="/home-owner-insurance">
                Homeowners Insurance
              </Link>
              <Link className="dropdown-item" to="/motorcycle-insurance">
                Motorcycle Insurance
              </Link>
              <Link className="dropdown-item" to="/non-owner-insurance">
                Non Owner Insurance
              </Link>
              <Link className="dropdown-item" to="/apartment-renter-insurance">
                Renters Insurance
              </Link>
              <Link className="dropdown-item" to="/sr-22-Insurance">
                SR 22 Insurance
              </Link>
            </div>
          </li>
          <li className="nav-item dropdown ">
            <a
              className="nav-link dropdown-toggle"
              href="#!"
              onClick={preventDefault}
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Client Services
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link className="dropdown-item" to="/make-payment-online">
                Make Payment
              </Link>
              <Link className="dropdown-item" to="/claims">
                Report a Claim
              </Link>
              <Link className="dropdown-item"
                  onClick={requestChange}
                  id="navbarDropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
              >
                Request A Change
              </Link>
              
              {/*<Link className="dropdown-item" to="/change-request-vehicle-form">*/}
              {/*  Request a Change*/}
              {/*</Link>*/}
              <Link className="dropdown-item" to="/roadside-assistance">
                Roadside Assistant
              </Link>
            </div>
          </li>
          <li className="nav-item dropdown ">
            <a
              className="nav-link dropdown-toggle"
              href="#!"
              onClick={preventDefault}
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              About us
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link className="dropdown-item" to="/about-us">
                About Us
              </Link>
              <Link className="dropdown-item" to="/career">
                Careers
              </Link>
              <Link className="dropdown-item" to="/contact-us">
                Contact Us
              </Link>
              <Link className="dropdown-item"  to="/privacy-policy">
                Privacy Policy
              </Link>
              <Link className="dropdown-item" to="/customer-reviews">
                Customer Reviews
              </Link>
            </div>
          </li>
        </ul>
        <span >
          {location.pathname === '/' &&
          <div onClick={() => history.push('/make-payment-online')} className="header-btn btn-payment">
            {/* <img id="btn-phone" src={PhoneSVG} alt="" /> */}
            <div className="btn-contact">
              <span className='makePaymentSpan'>Make A Payment</span>
            </div>
          </div>}
        </span>
        <a href="tel:8773338303">
        <div className="header-btn">
              {/* <img id="btn-phone" src={PhoneSVG} alt="" /> */}
              <div className="btn-contact">
                <p>WE HELP YOU SAVE</p>
                <h4 style={{marginTop: '5px'}}>877.333.8303</h4>
              </div>
          </div>
        </a>
      </div>
    </nav>
      {isMobile && active && (
        <div className="progress">
          <div 
              className="progress-bar" 
              role="progressbar" 
              style={{ width: progressBarWidth() + "%" }}
              aria-valuenow={progressBarWidth()}
              aria-valuemin="0" 
              aria-valuemax="100"></div>
        </div>
      )}
    </>
    )
  }
  if(props.half) {
    return (
      <nav id="head-half" className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <Link to="/">
            <img id="logo-head" src={Logo} alt="GM Logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
    
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="header-btn">
              {/* <img id="btn-phone" src={PhoneSVG} alt="" /> */}
              <div className="btn-contact">
                <p>WE HELP YOU SAVE</p>
                <h4>877.333.8303</h4>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
  if(props.simple) {
    return (
      <nav id="head-half" className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <Link to="/">
            <img id="logo-head" src={SimpleLogo} alt="GM Logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
    
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="row">
              <div className="col-3">
                {/* <img id="btn-phone" src={PhoneSVG} alt="" /> */}
              </div>
              <div className="col-9">
                <h4>CALL 877.333.8303</h4>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
})

export default Nav
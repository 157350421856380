import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    MainFirstStep:null,
    currentRequest: null,
    autoRequestType: null,
    autoChange: null,
    AddRemoveChangeAutoInfo: null,
    attachment: null,
    reviews: []
}



export const requestAChangeSLice = createSlice({
    name: "requestAChangeSLice",
    initialState: initialState,
    reducers: {
        setMainFirstStep(state,action){
            return{
                ...state,
                MainFirstStep: action.payload
            }
        },
        setCurrentRequestChange(state,action){
            return{
                ...state,
                currentRequest: action.payload
            }
        },
        setAutoRequestType(state,action){
            return{
                ...state,
                autoRequestType: action.payload
            }
        },
        setAutoChange(state,action){
            return{
                ...state,
                autoChange: {RequestType:state.currentRequest,
                    RequestSubType:state.autoRequestType,
                    MainInfo:state.MainFirstStep,[action.payload.name]:action.payload.value,
                }
            }
        },
        setCurrentAutoChangeOption(state,action){
            return{
                ...state,
                AddRemoveChangeAutoInfo: action.payload
            }
        },
        setVehicleAttachment(state,action){
            return{
                ...state,
                attachment: action.payload
            }
        },
        setReviews(state,action){
            return{
                ...state,
                reviews: action.payload
            }
        }
    }
})


export const fetchMenus = () => (dispatch) => {
    axios.get('https://reviews.insurancesurvey.org/dee6f24b5c2e864610d8b0f5a0225d8b8310081e1906de4dbd6ecc8909e17af8.json')
    .then((resp) => {
        dispatch(setReviews(resp.data))
    })
}

export const {
    setMainFirstStep,
    setAutoChange,
    setAutoRequestType,
    setCurrentAutoChangeOption,
    setCurrentRequestChange,
    setVehicleAttachment,
    setReviews,
} = requestAChangeSLice.actions

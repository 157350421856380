import Layout from "../../layout";
import Hero from "../../components/Hero";
import MotorcycleInsurance  from "../../components/StaticPages/MotorcycleInsurance";
import { useContentFull } from "../../hooks/useContentFull";
import { useMemo } from "react";
import { PageLoader } from "../../components/PageLoader/PageLoader";
import { getImageFromContentFull } from "../../utils/contentful";
import get from "lodash.get";
export default function MotorcycleInsurancePage() {
    const content = {
        'bg_img': 'staic_pages_landing motorcycle-isurance-bg-image',
        'text': 'Motorcycle ',
        'green_text': 'Insurances',
        'text2': ' Quotes',
        link: "/motorcycle-insurance-form",
    }

    const {fields, loading, pictureTitle} = useContentFull()
    const currentHeroImage = useMemo(() => {
        return getImageFromContentFull(fields.background)
    },[fields])

    if(loading || !currentHeroImage){
        return (
            <PageLoader />
        )
    }

    return (
        <Layout full>
            <title>{get(fields, 'pageTitle', '')}</title>
            <Hero pictureTitle={pictureTitle} image={currentHeroImage} content={content}/>
            <MotorcycleInsurance fields={fields} />
        </Layout>
    );
}
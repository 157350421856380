import React from 'react';

import FirstComponent from "./FirstComponent";
import SecondComponent from "./SecondComponent";
import ThirdComponent from "./ThirdComponent";

import LineLeft from "../../../static/images/fonts/line-left.png";
import LineRight from "../../../static/images/fonts/line-right.png";
const HomeF3 = React.memo(({fields, buttons}) =>  {
    return (
        <>
            <FirstComponent buttons={buttons} fields={fields.autoInsurance || {}} />
            <div className="line-overlap">
                <img id="ov-img" src={LineLeft} alt="Line Left" />
            </div>
            <SecondComponent buttons={buttons} fields={fields.commercialAuto || {}} />
            <div className="line-overlap">
                <img id="ov-img" src={LineRight} alt="Line Right" />
            </div>
            <ThirdComponent buttons={buttons} fields={fields.homeOwner || {}} />
        </>
    );
})

export default HomeF3;
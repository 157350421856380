import React from 'react'
import {CurrentChangeRequestComponent} from "./detectCurrentComponent";



export const Step = ({step, nextStep, previousStep }) => {

    return (
        <div>
            <CurrentChangeRequestComponent nextStep={nextStep} previousStep={previousStep} step={step}/>
        </div>
    );
}
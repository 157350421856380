import { createSelector } from 'reselect'

const nonOwnerInsurance = (state) => {return state.nonownerInsurance}

export const getNonOwnerInfo = createSelector(nonOwnerInsurance, state => state.nonOwnerFormInfo)







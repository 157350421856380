import {createSelector} from "reselect";

export const CommercialSelector = (state) => state.commercialInsurance

export const getCommercialForm = createSelector(CommercialSelector, state => state.commercialFinalForm)








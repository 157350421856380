import emailjs from "emailjs-com";
import {getAnalytics, logEvent} from "firebase/analytics";
import logger from 'redux-logger'

const userId = "user_iuz4PywP4uJvUMl4sLkyV"
const serviceId = "service_1d1ggng"
const autoInsuranceTemplateId = "template_rwy2a2j"

export const sendEmailJobs = (finalFormObject,subj, file) => {
    const currentSubject = subj ? subj : `${finalFormObject && Object.keys(finalFormObject)[0]}`
    emailjs.send("service_1d1ggng", "template_vle0en1", {
        to_name: "ADMIN",
        from_name: "USER",
        form_type: currentSubject,
        message: 'JSON.stringify(finalFormObject)',
        file: file,
    }, 'user_iuz4PywP4uJvUMl4sLkyV');

}


export const sendEmailContactUs = (finalFormObject,subj) => {
    const currentSubject = subj ? subj : `${finalFormObject && Object.keys(finalFormObject)[0]}`
    emailjs.send("service_1d1ggng", "template_tut8xv4", {
        to_name: "ADMIN",
        from_name: "USER",
        form_type: currentSubject,
        message: JSON.stringify(finalFormObject),
    }, 'user_iuz4PywP4uJvUMl4sLkyV');

}
export const sendEmailChangeRequest = (finalFormObject,subj) => {
    const currentSubject = subj ? subj : `${finalFormObject && Object.keys(finalFormObject)[0]}`
    emailjs.send("service_1d1ggng", "template_1r3fpks", {
        to_name: "ADMIN",
        from_name: "USER",
        form_type: currentSubject,
        message: JSON.stringify(finalFormObject),
    }, 'user_iuz4PywP4uJvUMl4sLkyV');
}

export const sendEmailQuotes = (finalFormObject,subj) => {
    emailjs.send("service_1d1ggng", "template_lsyaa7c", {
        to_name: subj,
        from_name: "USER",
        message: JSON.stringify(finalFormObject),
    }, 'user_iuz4PywP4uJvUMl4sLkyV');
}

export const sendAttachedFile = (finalFormObject,name) => {
    emailjs.send('service_e05150s', 'template_nksjqc8', {
        content: finalFormObject,
        to_name: "ADMIN",
        from_name: name,
    }, 'user_iuz4PywP4uJvUMl4sLkyV');
}

export const sendCarreersAttachment = (finalFormObject,name) => {
    emailjs.send('service_e05150s', 'template_pruwghl', {
        content: finalFormObject,
        to_name: "ADMIN",
        from_name: name,
    }, 'user_iuz4PywP4uJvUMl4sLkyV');
}



export const sendEmailAutoInsurance = async (params) => {
    await sendEmailJs("service_1d1ggng", "template_rwy2a2j", "user_iuz4PywP4uJvUMl4sLkyV",params)
}

export const sendMotorcycleInsurance = async (params) => {
    await sendEmailJs("service_1d1ggng", "template_kq7mcqf", "user_iuz4PywP4uJvUMl4sLkyV",params)
}

export const sendHomeOwnerInsurance = async (params) => {
    await sendEmailJs("service_1d1ggng", "template_j8b6lmd", "user_iuz4PywP4uJvUMl4sLkyV",params)
}

export const sendCommercialAutoInsurance = async (params) => {
    await sendEmailJs("service_1d1ggng", "template_8wdxuji", "user_iuz4PywP4uJvUMl4sLkyV",params)
}

export const sendNonOwnerInsurance = async (params) => {
    await sendEmailJs("service_1d1ggng", "template_zn2g32a", "user_iuz4PywP4uJvUMl4sLkyV",params)
}

export const sendRentersInsurance = async (params) => {
    await sendEmailJs("service_1d1ggng", "template_jrd64po", "user_iuz4PywP4uJvUMl4sLkyV",params)
}

const sendEmailJs = async (serviceId= "service_1d1ggng", templateId="template_rwy2a2j", userId = "user_iuz4PywP4uJvUMl4sLkyV", obj) => {
    await emailjs.send(serviceId, templateId, obj, userId);
}



import React from 'react'
import StepWizard from "react-step-wizard";
import {Step} from "./index";
import Layout from "../../../layout";


export const RequestAChange = () => {
        return (
            <Layout full>
            <div className="App">
                <StepWizard>
                    <Step step={1} />
                    <Step step={2} />
                    <Step step={3} />
                    <Step step={4} />
                    <Step step={5} />
                </StepWizard>
            </div>
            </Layout>
        );
}





import get from 'lodash.get';
import React from 'react';

import CompareInsuranceCompenent from './CompareInsuranceCompenent';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import {BLOCKS, INLINES} from '@contentful/rich-text-types'

const FIRST_PART = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return <p className="paragraph">{children}</p>
        },
        [BLOCKS.HEADING_3]: (node, children) => {
            return  <h3 className="p-heading">{children}</h3>
        },
        [BLOCKS.TABLE]: (node, children) => {
            return  <table className="table-contentful">{children}</table>
        },
        [BLOCKS.TABLE_ROW]: (node, children) => {
            return  <tr className="table-row-contentful">{children}</tr>
        },
        [INLINES.HYPERLINK]: (node, next) => {
            return <a href={node.data.uri} target='_blank' rel="noreferrer">{node.content[0].value}</a>;
          }
    }
}

const AutoInsuranceInNorthHollywoodStatic = ({fields}) => {
    return (
        <div class="container-fluid generic_font_color">
        <div class="row justify-content-md-center">
          <div class="col col-lg-12">
          <h3 class="text-center my-5 page-heading">{get(fields,'title','')}</h3>
          </div>
      </div>
      <div class="row mb-5">
      <div class="col-lg-12">
       {fields.firstContent && documentToReactComponents(fields.firstContent, FIRST_PART)}
      </div>
      </div>    
      <div class="row mb-5">
        <div class="col col-lg-12">
         <CompareInsuranceCompenent text={fields.compareInsurance.text} buttonText={fields.compareInsurance.buttonText}/>
        </div>
      </div>
      <div class="row mb-5">
      <div class="col-lg-12">
       {fields.secondContent && documentToReactComponents(fields.secondContent, FIRST_PART)}
      </div>
      </div> 
  </div>
    )
};


export default AutoInsuranceInNorthHollywoodStatic;
import {combineReducers} from '@reduxjs/toolkit'
import {autoInsuranceSlice} from "./autoInsuranceSlice";
import {yearsSlices} from "./yearMakeModelSlice";
import {homeownerSlice} from "./homeownerSlice"
import {renterInsuranceSlice} from "./renterInsuranceSlice"
import {nonownerSlice} from "./nonownerInsuranceSlice"
import {commercialInsuranceSlice} from "./commercialInsuranceSlice"
import {applicantSlice} from "./careerApplicantSlice"
import {motorcyclistInsuranceSlice} from "./motorcycleInsuranceSlice"
import { reducer as formReducer } from 'redux-form'
import {requestAChangeSLice} from "./requestAChnage";

export const reducer = combineReducers({
    autoInsurance:autoInsuranceSlice.reducer,
    yearMakeModel:yearsSlices.reducer,
    form:formReducer,
    requestAChange: requestAChangeSLice.reducer,
    homeowner: homeownerSlice.reducer,
    renterInsurance: renterInsuranceSlice.reducer,
    nonownerInsurance: nonownerSlice.reducer,
    commercialInsurance: commercialInsuranceSlice.reducer,
    applicant: applicantSlice.reducer,
    motorcyclist: motorcyclistInsuranceSlice.reducer
})



import React, {useCallback, useState} from 'react';
import {useDispatch} from "react-redux";
import {setCV} from "../../../slices/autoInsuranceSlice";
import {setVehicleAttachment} from "../../../slices/requestAChnage";

const FileUploader = ({upload, desc, isRequest}) => {
  const [filerError, setFileError] = useState(false)
    const [file,setFile] = useState()
    const dispatch = useDispatch()



  const FileSelectHendler =  useCallback((event) => {
      if (event.target.files[0]){
          setFile(event.target.files[0])
          setFileError(false)
          const fileReader = new FileReader()
          fileReader.onload = (e) => {
              dispatch(setVehicleAttachment(e.target.result))
              // if (isRequest){
              //     dispatch(setVehicleAttachment(e.target.result))
              // }else{
              //     dispatch(setCV(e.target.result))
              // }
          }
          fileReader.readAsDataURL(event.target.files[0])
      }else{
          setFileError(true)
      }
  }, [])

  return (
//TODO: show the sselected file
      <div>
          <div className="mt-4 mb-4">
              <h5 className="mb-4">
                  <strong>{desc ? desc : 'Please upload your resume.'}</strong>
              </h5>
              <div className="ui middle aligned left aligned grid container" >
                  <input type="file" className="inputfile" id="embedpollfileinput" onChange={FileSelectHendler}
                         placeholder="upload the resume from computer"/>
                  <label htmlFor="embedpollfileinput" className="ui small blue right floated button"
                         style={{borderRadius: "30px"}}>
                      <i className="ui add icon"></i> {upload ? upload : 'UPLOAD FROM COMPUTER'}</label>
                  {file && <div className="file-name">
                      {file && file.name}
                  </div>}
              </div>
          </div>
          {filerError &&
          <span style={{color: 'red', marginTop: '20px'}}>Pleas Upload a PDF or Image format file</span>
          }
      </div>
  );
};
export default FileUploader;
import React, {useEffect, useMemo, useState} from "react";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import Steps from "../Steps";
import {useRouteMatch} from 'react-router-dom'
import ThankYouPage from "../../ThankYouPage";
import ContactFormDriver from '../MotorcycleInsurance/reduxformMoto'
import {useDispatch, useSelector} from "react-redux";

import {
    setDriverFrom,
     setFinalForm,
} from "../../../slices/autoInsuranceSlice";
import {
    getFinalForm,
} from "../../../selectors/autoInsuranceSelector";
import {PrivacyInformation} from "../../PrivicyPolicyUnderForm";
import '../../../assets/css/autoInsurance.css'
import emailjs from "emailjs-com";
import {autoSrDuiEmailInfoDriver, autoSrDuiEmailInfoVehicle} from "../../utils/currentEmailInfo";
import { getAnalytics, logEvent } from "firebase/analytics";
import {sendEmail, sendEmailAutoInsurance} from "../../../services/sendEmail";
const VehicleFormGroup = React.lazy(() => import('./VehicleFormGroup'));


function Main({insuranceType}) {
    const [emailReady, setEmailReady] = useState(null)
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const { path} = useRouteMatch()
    const finalForm = useSelector(getFinalForm)
    const [formReady, setFormReady] = useState(false)
    const zipCode = useSelector(state => state.autoInsurance.zipCode)
    const vinCodes = useSelector(state => state.autoInsurance.vinCodes)
    const submitAutoForm = (val) => {
        dispatch(setDriverFrom(val.drivers))
        dispatch(setFinalForm())
        setFormReady(true)
        if (location.pathname === '/auto-insurance-form/driver'){
            setEmailReady("GMIS - Auto")
        }else if(location.pathname === '/dui-Insurance-form/driver'){
            setEmailReady("GMIS - DUI")
        }else if(location.pathname === '/sr22-insurance-form/driver'){
            setEmailReady("GMIS - SR22")
        }

    }
    const curVinCodes = useMemo(() => {
        let str = ''

        if(Object.values(vinCodes).length){
            console.log(Object.values(vinCodes))
            Object.values(vinCodes).forEach((curr,index) => {
                if(curr.status === 'success'){
                    str += ` ${index + 1} - ${curr.value}`
                }
            })
        }
        return str;
    },[vinCodes])

    const currentEmailInfoVehicle = finalForm && autoSrDuiEmailInfoVehicle(finalForm)
    const currentEmailInfoDriver = finalForm && autoSrDuiEmailInfoDriver(finalForm)

    useEffect(async () => {
        if (formReady){
            const params = {
                to_name: "ADMIN",
                subject: emailReady,
                vin: curVinCodes,
                collisionDeductible: currentEmailInfoVehicle.collisionDeductible,
                comprehensiveDeductible: currentEmailInfoVehicle.comprehensiveDeductible,
                make: currentEmailInfoVehicle.make,
                model: currentEmailInfoVehicle.model,
                trim: currentEmailInfoVehicle.trim,
                usage: currentEmailInfoVehicle.usage,
                year: currentEmailInfoVehicle.year,
                firstName:currentEmailInfoDriver.firstName,
                middleName: currentEmailInfoDriver?.middleName || '',
                lastName:currentEmailInfoDriver.lastName,
                homeAddress:currentEmailInfoDriver.homeAddress,
                city:currentEmailInfoDriver.city,
                state:currentEmailInfoDriver.state,
                zipCode:zipCode ? zipCode : currentEmailInfoDriver.zipCode,
                contact:currentEmailInfoDriver.contact,
                email:currentEmailInfoDriver.email,
                date_of_birth:currentEmailInfoDriver.date_of_birth,
                gender:currentEmailInfoDriver.gender,
                marital_status:currentEmailInfoDriver.marital_status,
                driver_license_number:currentEmailInfoDriver.driver_license_number,
                violation_state:currentEmailInfoDriver.violation_state,
                liability_limit:currentEmailInfoDriver.liability_limit,
            }
            await sendEmailAutoInsurance(params)
            if (location.pathname === '/sr22-insurance-form/driver'){
                history.push(`/sr22-insurance-form/thank-you`)
            }else if(location.pathname === '/dui-Insurance-form/driver'){
                history.push(`/dui-insurance-form/thank-you`)
            }else{
                history.push(`/auto-insurance-form/thank-you`)
            }
        }
    },[formReady])



    const currentLocation = location.pathname

    return (
        <Switch>
            <Route exact path={path}>
                <div className="form-bg">
                    <div className="container w-100">
                        <h1 className="text-center pt-5 pb-2">{insuranceType}</h1>
                        <Steps content={<VehicleFormGroup/>}/>
                    </div>
                </div>
            </Route>
            <Route exact path={`${path}/driver`}>
                <div className="form-bg">
                    <div className="container w-100">
                        <h1 className="text-center pt-5 pb-2">{insuranceType}</h1>
                        <Steps content={<ContactFormDriver currentLocation={currentLocation} onSubmit={submitAutoForm}/>}/>
                        <PrivacyInformation/>
                    </div>
                </div>
            </Route>
            <Route path={`${path}/thank-you`}>
                <ThankYouPage/>
            </Route>
        </Switch>
    )
}

export default Main;

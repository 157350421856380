

export const address = {
    street: null,
    city: null,
    state: null,
    zipCode: null
}

export const dataOfBirth = {
    month: null,
    date: null,
    year: null,
}

export const violations = {
    violation: false,
    license: null,
    state: null
}

export const nonownerViewModel = {
    firstName: null,
    lastName: null,
    street: null,
    city: null,
    state: null,
    zipCode: null,
    phone: null,
    email: null,
    month: null,
    date: null,
    year: null,
    gender: null,
    maritalStatus: null,
}

const vehicle = {
    year:null,
    make:null,
    model:null,
    trim:null,
    comprehensiveDeductible: null,
    collisionDeductible: null,
    isChecked: false,
}

export const driverViewModel = {
    firstName: null,
    lastName: null,
    phone: null,
    email: null,
    gender: null,
    maritalStatus: null,
    month: null,
    date: null,
    year: null,
    street: null,
    city: null,
    state: null,
    zipCode: null,
    violation: false,
    license: null,
    violationState: null,
    liability: null,
    isChecked: false
}

export const additionalDriverViewModel = {
    firstName: null,
    lastName: null,
    address: address,
    violations: violations,
    isChecked: false
}

export const commercialInsuranceCostumerViewModel = {
    firstName: null,
    lastName: null,
    street: null,
    city: null,
    state: null,
    zipCode: null,
    phone: null,
    officePhone: null,
}

export const companyViewModel = {
    type: null,
    name: null,
    website: null,
    yearsInBuisness: null,
    isInsured: false,
    effectiveDate: null,
    buisnessDescription: null,
    numberOfVehicles: null,
    numberOfDrivers: null
}

export const fetchResult = {
    fetchYearsResult: {
        result:[],
        status:'idle',
        error:null
    },
    fetchMakesResult:{
        result:[],
        status:'idle',
        error:null
    },
    fetchModelsResult:{
        result:[],
        status:'idle',
        error:null
    },
    fetchTrimsResult:{
        result:[],
        status:'idle',
        error:null
    }
}

export const vehicleViewModel = {
    vehicle:vehicle,
    comprehensiveDeductible: null,
    collisionDeductible: null,
    usage: null,
    isChecked:false,
    fetchResult:fetchResult
}


export const homeownerViewModel = {
    firstName: null,
    lastName: null,
    street: null,
    city: null,
    state: null,
    zipCode: null,
    phone: null,
    email: null,
    propertyType: null,
    isPrimaryResidence: true
}

export const propertyViewModel = {
    footage: null,
    constructionType: null,
    garageType: null,
    roofType: null,
    numberOfStory: null,
    roofAge: null,
    alarmSystem: null,
    lossClaim: false
}

export const renterViewModel = {
    firstName: null,
    lastName: null,
    street: null,
    city: null,
    state: null,
    zipCode: null,
    phone: null,
    email: null,
    personalProperty: null,
    liabilityLimit: null,
    earthCoverage: false
}

export const applicantViewModel = {
    firstName: null,
    lastName: null,
    phone: null,
    eamil: null,
    position: null,
    islicensed: false,
    license: null,
    resume: null
}

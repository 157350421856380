import React, {useEffect, useState} from "react";
import { Route, Switch, useHistory, useLocation} from "react-router-dom";
import {useRouteMatch} from 'react-router-dom'
import ThankYouPage from "./../../ThankYouPage"
import {CommercialSteps} from "./commericialSteps";
import ContactForm from "./reduxCommercialForm";
import ContactFormContact from './contactInfo'
import {useDispatch, useSelector} from "react-redux";
import {setFinalForm} from "../../../slices/commercialInsuranceSlice";
import {getCommercialForm} from "../../../selectors/commercialSelector";
import {sendCommercialAutoInsurance, sendEmailQuotes} from "../../../services/sendEmail";
import {PrivacyInformation} from "../../PrivicyPolicyUnderForm";
import emailjs from "emailjs-com";

function Main() {
    const location = useLocation()
    const history = useHistory()
    const dispatch = useDispatch()
    const [commercialEmail,setCommercialEmail] = useState(false)
    const final = useSelector(getCommercialForm)

    const { path} = useRouteMatch()
    const handleSubmit = async (values) => {
        if (location.pathname === '/commercial-auto-insurance-form'){
            dispatch(setFinalForm(values.validate[0]))
            history.push(`${location.pathname}/contact-information`)
        }else if (location.pathname === '/commercial-auto-insurance-form/contact-information') {
            dispatch(setFinalForm({commercial_insurance_form:[final,values.validate[0]]}))
            setCommercialEmail(true)
        }
    }
    useEffect(async () => {
        if (commercialEmail && final){

            const params = {
                subject: 'Commercial Auto Insurance',
                companyName: final.commercial_insurance_form[0].companyName ? final.commercial_insurance_form[0].companyName : '',
                companyType: final.commercial_insurance_form[0].companyType ? final.commercial_insurance_form[0].companyType : '',
                currentlyInsured: final.commercial_insurance_form[0].currentlyInsured ? final.commercial_insurance_form[0].currentlyInsured : '',
                date_of_birth: final.commercial_insurance_form[0].date_of_birth ? final.commercial_insurance_form[0].date_of_birth : '',
                descriptionBusiness: final.commercial_insurance_form[0].descriptionBusiness ? final.commercial_insurance_form[0].descriptionBusiness : '',
                driversNUmber: final.commercial_insurance_form[0].driversNUmber ? final.commercial_insurance_form[0].driversNUmber : '',
                vehicleNumber: final.commercial_insurance_form[0].vehicleNumber ? final.commercial_insurance_form[0].vehicleNumber : '',
                website: final.commercial_insurance_form[0].website ? final.commercial_insurance_form[0].website : '',
                yearsInBussiness: final.commercial_insurance_form[0].yearsInBussiness ? final.commercial_insurance_form[0].yearsInBussiness : '',
                firstName: final.commercial_insurance_form[1].firstName ? final.commercial_insurance_form[1].firstName : '',
                lastName: final.commercial_insurance_form[1].lastName ? final.commercial_insurance_form[1].lastName : '',
                personal_contact: final.commercial_insurance_form[1].personal_contact ? final.commercial_insurance_form[1].personal_contact : '',
                email_address: final.commercial_insurance_form[1].email_address ? final.commercial_insurance_form[1].email_address : '',
                property_address: final.commercial_insurance_form[1].property_address ? final.commercial_insurance_form[1].property_address : '',
                state: final.commercial_insurance_form[1].state ? final.commercial_insurance_form[1].state : '',
                zipCode: final.commercial_insurance_form[1].zipCode ? final.commercial_insurance_form[1].zipCode : '',
                city: final.commercial_insurance_form[1].city ? final.commercial_insurance_form[1].city : '',
                different_address: final.commercial_insurance_form[1].different_address ? final.commercial_insurance_form[1].different_address : '',
                different_city: final.commercial_insurance_form[1].different_city ? final.commercial_insurance_form[1].different_city : '',
                different_zip: final.commercial_insurance_form[1].different_zip ? final.commercial_insurance_form[1].different_zip : '',
                different_state: final.commercial_insurance_form[1].different_state ? final.commercial_insurance_form[1].different_state : '',
            }
            await sendCommercialAutoInsurance(params)
            history.push('/commercial-auto-insurance-form/thank-you')
        }
    },[commercialEmail])

    const currentLocation = location.pathname

        return (
            <Switch>
                <Route exact path={path}>
                 <div className="form-bg">
                    <div className="container w-100">
                        <h1 className="text-center pt-5 pb-2">Commercial Auto Insurance</h1>
                        <CommercialSteps />
                        <ContactForm currentLocation={currentLocation} onSubmit={handleSubmit}/>
                    </div>
                </div>
                </Route>
                <Route path= {`${path}/contact-information`}>
                    <div className="form-bg">
                        <div className="container w-100">
                            <h1 className="text-center pt-5 pb-2">Commercial Auto Insurance</h1>
                            <CommercialSteps />
                            <ContactFormContact currentLocation={currentLocation} onSubmit={handleSubmit}/>
                            <PrivacyInformation/>
                        </div>
                    </div>
                </Route>
                <Route path={`${path}/thank-you`}>
                    <ThankYouPage/>
                </Route>
            </Switch>
        )


}

export default Main;




export const detectCurrentVehicleRequestType = (current) => {
    if (current === 'Add Vehicle'){
        return '/vehicle-request-add'
    }else if (current === 'Remove/Replace Vehicle'){
        return '/vehicle-request-remove-replace'
    }
}

export const dateChanger = (arg) => {
    const MM = arg.substring(5,7)
    const DD = arg.substring(8,10)
    const YY = arg.substring(0,4)
    return `${MM}-${DD}-${YY}`
}
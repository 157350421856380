import {Switch, Route, Redirect} from "react-router-dom";
import Home from "./views/Home";
import CommercialAutoInsurance from "./views/CommercialAutoInsurance";
import SR22Insurance from "./views/SR-22-InsuranceQuote";
import ApartmentRenterInsurance from "./views/ApartmentRentersInsurance";
import HomeOwnerInsurancePage from "./views/HomeOwnerInsurance";
import MotorcycleInsurancePage from "./views/MotorcycleInsurance";
import AutoInsuranceQuotePage from "./views/AutoInsuranceQuote";
import NonOwnerInsurancePage from "./views/NonOwnerInsurance";
import NonOwnerInsuranceForm from "./views/NonOwnerInsuranceForm";
import MakePaymentOnlinePage from "./views/Claims";
import RoadsideAssistancePage from "./views/RoadsideAssitance";
import OurHonestyPage from "./views/OurHonesty";
import DuiInsurancePage from "./views/DUI-Insurance";
import CareerPage from "./views/Career";
import PrivacyPolicyPage from "./views/PrivacyPolicy";
import OurMissionPage from "./views/OurMission";
import ContactUsForm from "./views/ContactUs";
import RentersInsuranceForm from "./views/RenterInsuranceForm";
import HomeInsuranceForm from "./views/HomeOwnerInsuranceForm";
import SR22InsuranceForm from "./views/SR-22-InsuranceForm";
import MotorcycleInsuranceForm from "./views/MotorCycleInsuranceForm";
import AutoInsuranceForm from "./views/AutoInsuranceForm";
import ChangeRequestVehicle from "./views/ChangeRequestVehicle";
import DuiInsuranceForm from "./views/DUI-InsuranceForm";
import CareerForm from "./views/CareerForm";
import CommercialAutoInsuranceForm from "./views/CommericalInsuranceForm";

import ScrollToTop from './utils/scrollToTop';
import {WebsiteAccessibilityPage} from "./views/Website-Accesibility";
import MakePayment from "./views/MakeAPayment";
import {RequestAChange} from "./components/RequestAChange/ComponentChanger/StepChnager";
import AddressChangeRequest from "./components/RequestAChange/AutoRequest/AddressChange";
import {DriverChange} from "./components/RequestAChange/AutoRequest/DriverChange";
import {DriverRequestCurrentForm} from "./components/RequestAChange/AutoRequest/DriverChange/DriverCurrentFormChange";
import {useSelector} from "react-redux";
import {getAutoCurrentChange, getAutoRequestType, getCurrentRequestForm} from "./selectors/requestAChangeSelector";
import {LienholderChangeRequest} from "./components/RequestAChange/AutoRequest/LineholderChange";
import {OtherChangeRequest} from "./components/RequestAChange/AutoRequest/OtherChange";
import {DocumentRequest} from "./components/RequestAChange/AutoRequest/DocumentRequest";
import {VehicleRequest} from "./components/RequestAChange/AutoRequest/VehicleRequest";
import {InsuranceChangeRequest} from "./components/RequestAChange/Commercial-Home-Other";
import {Remove_Replace_Driver} from "./components/RequestAChange/AutoRequest/DriverChange/Remove-Replace";
import {RequestChangeThankYouPage} from "./components/RequestAChange/thank-you-page-request";
import {AddVehicleRequestChange} from "./components/RequestAChange/AutoRequest/VehicleRequest/Add";
import {VehicleRemoveReplace} from "./components/RequestAChange/AutoRequest/VehicleRequest/Remove-Replace";
import AutoInsuranceInNorthHollywood from "./views/AutoInsuranceNorthHollywood";

export default function Routes() {
  const autoDetectCurrent = useSelector(getAutoCurrentChange)
  const autoDetectCurrentType = useSelector(getAutoRequestType)
  const currentForm = useSelector(getCurrentRequestForm)

  return (
    <>
    <ScrollToTop />
    <Switch>
      <Route exact path="/commercial-auto-insurance">
        <CommercialAutoInsurance />
      </Route>
      <Route path="/commercial-auto-insurance-form">
        <CommercialAutoInsuranceForm />
      </Route>
      <Route exact path="/contact-us">
          <ContactUsForm />
      </Route>
      <Route exact path="/sr-22-insurance">
        <SR22Insurance />
      </Route>
      <Route exact path="/apartment-renter-insurance">
        <ApartmentRenterInsurance />
      </Route>
      <Route exact path="/motorcycle-insurance">
        <MotorcycleInsurancePage />
      </Route>
      <Route exact path="/home-owner-insurance">
        <HomeOwnerInsurancePage />
      </Route>
      <Route path="/home-owner-insurance-form">
        <HomeInsuranceForm />
      </Route>
      <Route exact path="/auto-insurance-quote">
        <AutoInsuranceQuotePage />
      </Route>
      <Route exact path="/non-owner-insurance">
        <NonOwnerInsurancePage />
      </Route>
      <Route path="/non-owner-insurance-form">
        <NonOwnerInsuranceForm />
      </Route>
      <Route exact path="/make-payment-online">
        <MakePayment />
      </Route>
      <Route exact path="/roadside-assistance">
        <RoadsideAssistancePage />
      </Route>
      <Route exact path="/customer-reviews">
        <OurHonestyPage />
      </Route>
      <Route exact path="/dui-insurance">
        <DuiInsurancePage />
      </Route>
      <Route exact path="/career">
        <CareerPage />
      </Route>
      <Route  path="/apply">
        <CareerForm />
      </Route>
      <Route exact path="/privacy-policy">
        <PrivacyPolicyPage />
      </Route>
      <Route exact path="/about-us">
        <OurMissionPage />
      </Route>
      <Route path="/renters-insurance-form">
        <RentersInsuranceForm />
      </Route>
      <Route path="/sr22-insurance-form">
        <SR22InsuranceForm />
      </Route>
      <Route  path="/motorcycle-insurance-form">
        <MotorcycleInsuranceForm />
      </Route>
      <Route path="/auto-insurance-form">
        <AutoInsuranceForm />
      </Route>
      <Route exact path="/change-request-vehicle-form">
        <ChangeRequestVehicle />
      </Route>
      <Route path="/dui-insurance-form">
        <DuiInsuranceForm />
      </Route>
      <Route path="/claims">
        <MakePaymentOnlinePage />
      </Route>
      <Route path="/autoinsurancenorthhollywood">
        <AutoInsuranceInNorthHollywood />
      </Route>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/website-accessibility">
        <WebsiteAccessibilityPage />
      </Route>
      <Route exact path="/request-change">
        <RequestAChange/>
      </Route>
      <Route exact path="/address-request">
        {autoDetectCurrentType ? <AddressChangeRequest/> : <Redirect to="/" />}
      </Route>
      <Route exact path="/driver-change">
        <DriverChange/>
      </Route>
      <Route exact path="/request-change-thank-you">
        <RequestChangeThankYouPage/>
      </Route>
      <Route exact path="/driver-change-request">
        {autoDetectCurrent ? <DriverRequestCurrentForm/> : <Redirect to="/" />}
      </Route>
      <Route exact path="/lienholder-change">
        {autoDetectCurrentType ? <LienholderChangeRequest/> : <Redirect to="/" />}
      </Route>
      <Route exact path="/other-change">
        {autoDetectCurrentType ? <OtherChangeRequest/> : <Redirect to="/" />}
      </Route>
      <Route exact path="/policy-document-request">
        {autoDetectCurrentType ? <DocumentRequest/> : <Redirect to="/" />}
      </Route>
      <Route exact path="/vehicle-request">
        {autoDetectCurrentType ? <VehicleRequest/> : <Redirect to="/" />}
      </Route>
      <Route exact path="/vehicle-request-remove-replace">
        {autoDetectCurrentType ? <VehicleRemoveReplace/> : <Redirect to="/" />}
      </Route>
      <Route exact path="/change-request">
        {currentForm ? <InsuranceChangeRequest/> : <Redirect to="/" />}
      </Route>
      <Route exact path="/remove-replace-driver">
        {autoDetectCurrentType ? <Remove_Replace_Driver/> : <Redirect to="/" />}
      </Route>
      <Route exact path="/vehicle-request-add">
        {autoDetectCurrentType ? <AddVehicleRequestChange/> : <Redirect to="/" />}
      </Route>
    </Switch>
    </>
  )
};

import React from 'react'
import '../../assets/css/autoInsurance.css'

const OurHonesty = ({reviews}) => {

console.log(reviews)

    const renderCurrentReview = (review) => {
        // const fullName = `${review.firstName} ${review.lastName?.substring(0,1)}.`
        const customerSince = review.customerSince ?  `Customer since ${review.customerSince.substring(0,4)}` : ''

        return `— ${review.name} ${customerSince}`
        
    }



    return (
        <div className='reviewsMain'>
            <div className='reviewsContantMain' id='RRTestimonials'>
                {reviews.length && reviews.map((curr) => {
                    return (
                        <div className='newReviews'>
                            <div className='newReviews_current'>
                            {curr.content}
                            <div className='review_creds'>
                                {renderCurrentReview(curr)}
                            </div>
                            </div>
                        </div>
                    )
                })}

            </div>
        </div>
    );
}

export default OurHonesty;